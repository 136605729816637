import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toastErrorr } from "../../../Helper/ToastMessage";
import { BaseUrl } from "../../../Helper/BaseURL";

export const ProfileScreenSelectGetDataAPI = createAsyncThunk("ProfileScreenSelectGetData", async ({ data }) => {
    const {
        M_FinancialYearID,
        M_MonthID,
        M_RegistrationID,
        M_UserID,
        token,
    } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    return fetch(`${BaseUrl}/Master/Get_M_Registration_ProfileScreen_Select?M_FinancialYearID=${M_FinancialYearID}&M_MonthID=${M_MonthID}&M_RegistrationID=${M_RegistrationID}&M_UserID=${M_UserID}`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data.table) {
                return result.data.table
            } else {
                return result
            }
        })
})

const ProfileScreenSelectGetDataSlice = createSlice({
    name: " ProfileScreenSelectGetData",
    initialState: {
        isLoading: false,
        tableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(ProfileScreenSelectGetDataAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(ProfileScreenSelectGetDataAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.tableData = action.payload;
        });
        builder.addCase(ProfileScreenSelectGetDataAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.tableData = null;
            toastErrorr('Something went wrong')
        });
    },
})

export const ProfileScreenSelectGetDataReducer = ProfileScreenSelectGetDataSlice.reducer