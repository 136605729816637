

export const LogoLoader = () => {
  return (
    <main class="main">
      <p class="loader_back">
        <img src="https://ik.imagekit.io/gplanet/Gplanet/img/GCP_logo.png?updatedAt=1701510152620" alt="" class="img_size " />
        <span class="preloader"></span>
      </p>
    </main>
  )
}

