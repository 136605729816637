
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toastErrorr, toastSuccesss } from "../../Helper/ToastMessage";
import { BaseUrl } from "../../Helper/BaseURL";

// InsertUpdate Table Data

export const PostPartnerRegistrationAPI = createAsyncThunk("PostPartnerRegistration", async ({ data }) => {
    const {
        M_CountryNameID,
        G_Planet_text,
        M_PartnerTypeID,
        M_SubPartnerTypeID,
        Full_Name,
        CompanyName,
        Position_InOrganization,
        ContactPersonName,
        Email_ID,
        MobileNo,
        Address,
        PostalCode,
        Username,
        Password,
        AreaOfInterest,
        Contribution,
        OtherText,
        M_UserID,
        handlePostRegistration,
        handleSendEmail,
        setPostMsg,
        handleClear
    } = data


    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer ");

    var formdata = new FormData();

    formdata.append("M_CountryNameID", M_CountryNameID);
    formdata.append("G_Planet_text", Full_Name);
    formdata.append("Full_Name", '');
    formdata.append("CompanyName", CompanyName);
    formdata.append("Position_InOrganization", Position_InOrganization);
    formdata.append("Email_ID", Email_ID);
    formdata.append("MobileNo", MobileNo);
    formdata.append("Username", Username);
    formdata.append("Password", Password);
    formdata.append("AreaOfInterest", AreaOfInterest);
    formdata.append("Contribution", Contribution);
    formdata.append("OtherText", OtherText !== undefined ? OtherText : '');
    formdata.append("M_UserID", M_UserID);

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
    };

    fetch(`${BaseUrl}/Master/Post_M_PartnerRegistration_Insert`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                handlePostRegistration(result.message)
                handleSendEmail(result && result.data)
                handleClear()
                return result.data
            } else {
                handlePostRegistration()
                handleClear()
                setPostMsg(result.message)
            }
            return result
        })
})

const PostPartnerRegistrationSlice = createSlice({
    name: "PostPartnerRegistration",
    initialState: {
        isRegistrationLoading: false,
        tableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(PostPartnerRegistrationAPI.pending, (state, action) => {
            state.isRegistrationLoading = true;
        });
        builder.addCase(PostPartnerRegistrationAPI.fulfilled, (state, action) => {
            state.isRegistrationLoading = false;
            state.tableData = action.payload;
        });
        builder.addCase(PostPartnerRegistrationAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isRegistrationLoading = false;
            state.isError = true;
            state.tableData = null;
            // toastErrorr('Something went wrong')
        });
    },
})

export const PostPartnerRegistrationReducer = PostPartnerRegistrationSlice.reducer

//////////Registration Form

export const PostRegistrationAPI = createAsyncThunk("PostRegistration", async ({ data }) => {
    const {
        M_FinancialYearID,
        M_MonthID,
        FirstName,
        LastName,
        OrganizationName,
        DesignationName,
        PhoneNumber,
        Individual_Country,
        UserType_M_IndicatorID,
        Individual_Proof_of_Identity,
        ProofofIdentity_FileExtension,
        Individual_Proof_of_Address,
        ProofofAddress_FileExtension,
        Corporate_Country,
        Corporate_NodalOfficer,
        Corporate_WebsiteLink,
        Corporate_Certificate_of_Incorporation,
        CertificateofIncorporation_FileExtension,
        Email_ID,
        Corporate_MemorandumArticles,
        MemorandumArticles_FileExtension,
        Corporate_TaxIdentification,
        TaxIdentification_FileExtension,
        Username,
        Password,
        Government_Country,
        Government_WebsiteLink,
        Government_LegalEntityInfo,
        M_UserID,
        handlePostRegistration,
        Government_AuthorizedSignatories,
        Other_Certificate_of_Incorporation,
        OtherCertificate_FileExtension,
        Other_AuthorizationName,
        Other_Letter_of_Authorization,
        LetterofAuthorization_FileExtension,
        Other_AprroveIDCard_of_Authorization,
        AprroveIDCard_FileExtension,
        handleSendEmail,
        setPostMsg,
        handleClear
    } = data

    console.log("FormData.FullName", FirstName)

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer ");

    var formdata = new FormData();
    formdata.append("M_FinancialYearID", M_FinancialYearID);
    formdata.append("M_MonthID", M_MonthID);
    formdata.append("FirstName", FirstName);
    formdata.append("LastName", LastName);
    formdata.append("OrganizationName", OrganizationName);
    formdata.append("DesignationName", DesignationName);
    formdata.append("Email_ID", Email_ID);
    formdata.append("PhoneNumber", PhoneNumber);
    formdata.append("UserType_M_IndicatorID", UserType_M_IndicatorID);
    formdata.append("Username", Username);
    formdata.append("Password", Password);
    formdata.append("Individual_Country", Individual_Country);
    formdata.append("Individual_Proof_of_Identity", Individual_Proof_of_Identity);
    formdata.append("ProofofIdentity_FileExtension", ProofofIdentity_FileExtension);
    formdata.append("Individual_Proof_of_Address", Individual_Proof_of_Address);
    formdata.append("ProofofAddress_FileExtension", ProofofAddress_FileExtension);
    formdata.append("Corporate_Country", Corporate_Country);
    formdata.append("Corporate_NodalOfficer", Corporate_NodalOfficer);
    formdata.append("Corporate_WebsiteLink", Corporate_WebsiteLink);
    formdata.append("Corporate_Certificate_of_Incorporation", Corporate_Certificate_of_Incorporation);
    formdata.append("CertificateofIncorporation_FileExtension", CertificateofIncorporation_FileExtension);
    formdata.append("Corporate_MemorandumArticles", Corporate_MemorandumArticles);
    formdata.append("MemorandumArticles_FileExtension", MemorandumArticles_FileExtension);
    formdata.append("Corporate_TaxIdentification", Corporate_TaxIdentification);
    formdata.append("TaxIdentification_FileExtension", TaxIdentification_FileExtension);
    formdata.append("Government_Country", Government_Country);
    formdata.append("Government_WebsiteLink", Government_WebsiteLink);
    formdata.append("Government_LegalEntityInfo", Government_LegalEntityInfo);
    formdata.append("Government_AuthorizedSignatories", Government_AuthorizedSignatories);

    formdata.append("Other_Certificate_of_Incorporation", Other_Certificate_of_Incorporation);
    formdata.append("OtherCertificate_FileExtension", OtherCertificate_FileExtension);
    formdata.append("Other_AuthorizationName", Other_AuthorizationName);
    formdata.append("Other_Letter_of_Authorization", Other_Letter_of_Authorization);
    formdata.append("LetterofAuthorization_FileExtension", LetterofAuthorization_FileExtension);
    formdata.append("Other_AprroveIDCard_of_Authorization", Other_AprroveIDCard_of_Authorization);
    formdata.append("AprroveIDCard_FileExtension", AprroveIDCard_FileExtension);

    formdata.append("M_UserID", M_UserID);

    const requestOptions = {
        method: "POST",
        body: formdata,
        redirect: "follow"
    };

    fetch(`${BaseUrl}/Master/Post_M_Registration_Insert`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                handlePostRegistration(result.message)
                handleSendEmail(result && result.data)
                handleClear()
                return result.data
            } else {
                handlePostRegistration()
                handleClear()
                setPostMsg(result.message)
            }
            return result
        })
})

const PostRegistrationSlice = createSlice({
    name: "PostRegistration",
    initialState: {
        isRegistrationLoading: false,
        tableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(PostRegistrationAPI.pending, (state, action) => {
            state.isRegistrationLoading = true;
        });
        builder.addCase(PostRegistrationAPI.fulfilled, (state, action) => {
            state.isRegistrationLoading = false;
            state.tableData = action.payload;
        });
        builder.addCase(PostRegistrationAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isRegistrationLoading = false;
            state.isError = true;
            state.tableData = null;
            // toastErrorr('Something went wrong')
        });
    },
})

export const PostRegistrationReducer = PostRegistrationSlice.reducer

export const PostQueryAPI = createAsyncThunk("PostQuery", async ({ data }) => {
    const {
        M_CountryNameID,
        Full_Name,
        Email_ID,
        MobileNo,
        Address,
        Query,
        M_UserID,
        handlePostRegistration,
        setPostMsg,
        handleClear
    } = data


    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer ");

    var formdata = new FormData();

    formdata.append("M_CountryNameID", M_CountryNameID);
    formdata.append("Full_Name", Full_Name);
    formdata.append("MobileNo", MobileNo);
    formdata.append("Email_ID", Email_ID);
    formdata.append("Location", Address);
    formdata.append("Query", Query);
    formdata.append("M_UserID", M_UserID);

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
    };

    fetch(`${BaseUrl}/Master/Post_M_Query_Insert`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                handlePostRegistration(result.message)
                handleClear()
                return result.data
            } else {
                handlePostRegistration()
                handleClear()
                setPostMsg(result.message)
            }
            return result
        })
})

const PostQuerySlice = createSlice({
    name: "PostQuery",
    initialState: {
        isRegistrationLoading: false,
        tableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(PostQueryAPI.pending, (state, action) => {
            state.isRegistrationLoading = true;
        });
        builder.addCase(PostQueryAPI.fulfilled, (state, action) => {
            state.isRegistrationLoading = false;
            state.tableData = action.payload;
        });
        builder.addCase(PostQueryAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isRegistrationLoading = false;
            state.isError = true;
            state.tableData = null;
            // toastErrorr('Something went wrong')
        });
    },
})

export const PostQueryReducer = PostQuerySlice.reducer


