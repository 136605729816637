import { useEffect, useState } from "react"
import { CountryDDL } from "../../Components/DDL/CountryDDL/CountryDDL"
import { AstricSign } from "../../Helper/AstricSign/AstricSign"
import { PartnerTypeDDL } from "../../Components/DDL/PartnerTypeDDL/PartnerTypeDDL"
import { RegExEmail, RegExNumbersOnly, RegExOnlyText } from "../../Helper/Regx/Regx"
import { CountryCodeDDLAPI } from "../../Redux/DDLSlice"
import { useAuthState } from "../../Helper/Context"
import { useDispatch, useSelector } from "react-redux"
import { PostPledgeRegistrationAPI } from "../../Redux/PledgeRegistrationSlice/PledgeRegistrationSlice"
import { Loading } from "../../Helper/Loading"

export const PledgeRegistration = (props) => {
    const { handleShowPledge } = props

    const dispatch = useDispatch()

    const userDetails = useAuthState();
    const { UserID, token } = userDetails

    const [FormData, setFormData] = useState({
        ContactPersonName: '',
        Position: '',
        Email: '',
        Number: '',
        Commitment: '',
        Pledge: [],
        OtherText: ''

    })
    const [CountryCodeID, setCountryCodeID] = useState('')
    const [showOther, setshowOther] = useState(false)
    const [IsContribute, setIsContribute] = useState(false)
    const [IsAgree, setIsAgree] = useState(false)
    const [IsDisable, setIsDisable] = useState(false)
    const [PostMsg, setPostMsg] = useState(false)

    const [CountryTypeDDL, setCountryTypeDDL] = useState({
        DDL: [],
        ID: 0,
        Label: "Select...",
    })
    const [partnerTypeDDL, setpartnerTypeDDL] = useState({
        DDL: [],
        ID: 0,
        Label: "Select...",
    })

    const onChange = (e) => {
        const { name, value } = e.target
        setFormData(pre => ({
            ...pre, [name]: value
        }))
    }


    const [IsValidText, setIsValidText] = useState(false)
    const handleCheckText = (e) => {
        onChange(e)
        const IsValid = RegExOnlyText(e.target.value)
        setIsValidText(IsValid)
        return IsValid
    }

    const [IsValidEmail, setIsValidEmail] = useState(false)
    const handleCheckEmail = (e) => {
        onChange(e)
        const IsValid = RegExEmail(e.target.value)
        setIsValidEmail(IsValid)
        return IsValid
    }

    const [IsValidNumber, setIsValidNumber] = useState(false)
    const handleCheckNumber = (e) => {
        onChange(e)
        const IsValid = RegExNumbersOnly(e.target.value)
        setIsValidNumber(IsValid)
        return IsValid
    }

    const handleContribute = (e) => {
        if (e.target.checked) {
            FormData.Pledge.push(e.target.value)
        } else {
            handleContributeFilter(e.target.value)
        }
        setIsContribute(FormData.Pledge.length)
        // console.log(FormData.Pledge.length)
    }

    const handleContributeFilter = (item) => {
        FormData.Pledge = FormData.Pledge.filter((i) => {
            return i !== item
        })
    }

    const handleAgree = (e) => {
        if (e.target.checked) {
            setIsAgree(true)
        } else {
            setIsAgree(false)
        }
    }

    const onChangeOther = (e) => {
        if (e.target.checked) {
            setshowOther(true)
        } else {
            setshowOther(false)
        }
    }

    useEffect(() => {
        const data = { UserID, token, CountryTypeDDL, setCountryCodeID }
        dispatch(CountryCodeDDLAPI({ data }))
    }, [CountryTypeDDL])

    const { DDLData } = useSelector(state => state.CountryCodeDDLData)

    const handlePostRegistration = (data) => {
        setPostMsg(data)
        setTimeout(() => {
            // handleShowPledge()
        }, 5000);
    }

    const handlePost = () => {
        const data = {
            M_CountryNameID: CountryCodeID,
            M_PartnerTypeID: partnerTypeDDL.ID,
            Position_InOrganization: FormData.Position,
            ContactPersonName: FormData.ContactPersonName,
            Email_ID: FormData.Email,
            MobileNo: FormData.Number,
            Address: '',
            Commitment: FormData.Commitment,
            LIFEPledgeText: '',
            Learn_About_LIFEPledge: FormData.Pledge,
            OtherText: FormData.OtherText,
            handlePostRegistration,
            setPostMsg: setPostMsg
        }
        dispatch(PostPledgeRegistrationAPI({ data }))
    }

    const { isRegistrationLoading } = useSelector(state => state.PostPledgeRegistrationData)

    const IsValidate = () => {
        if (showOther) {
            if (
                IsValidText || IsValidEmail || IsValidNumber ||
                CountryTypeDDL.ID == '0' || partnerTypeDDL.ID == '0' ||
                FormData.ContactPersonName == '' || FormData.Position == '' || FormData.Number == '' || FormData.Commitment == ''
                || FormData.Pledge.length == '0' || IsAgree == false || FormData.OtherText == '') {
                setIsDisable(true)
            } else {
                setIsDisable(false)
            }
        }
        else if (
            IsValidText || IsValidEmail || IsValidNumber ||
            CountryTypeDDL.ID == '0' || partnerTypeDDL.ID == '0' ||
            FormData.ContactPersonName == '' || FormData.Position == '' || FormData.Number == '' || FormData.Commitment == ''
            || FormData.Pledge.length == '0' || IsAgree == false) {
            setIsDisable(true)
        } else {
            setIsDisable(false)
        }

    }

    useEffect(() => {
        IsValidate()
    }, [FormData, IsContribute, IsAgree])

    return (
        <div className="modal show fade px-lg-5" id="exampleModal"
            style={{ display: "block", backgroundColor: "#00000055", overflow: 'auto' }}
            tabindex="-1" aria-labelledby="exampleModalLabel"
            aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered" >
                <div className="modal-content" style={{ height: '90vh' }}>
                    <div class="modal-header px-lg-5 bg-white">
                        <h5 class="modal-title lightgreencolor" id="exampleModalLabel">G-PlaNET Pledge Participant Registration
                        </h5>
                        <button type="button" class=" bg-danger text-white px-2 border-0 rounded fs-4"
                            data-bs-dismiss="modal" aria-label="Close"
                            onClick={handleShowPledge}
                        > &times; </button>
                    </div>
                    <div class="modal-body px-5" style={{ height: '600px', overflowY: 'scroll' }}>
                        <form>

                            <div class="row pt-4">
                                <div class="col-12 col-md-5">
                                    <div class="col-12">
                                        <label for="exampleInputEmail1" class="form-label"> Participants Name <AstricSign /></label>
                                    </div>
                                </div>
                                <div class="col-12 col-md-7">
                                    <div class="col-12">
                                        <input
                                            type="text"
                                            class="form-control"
                                            name="ContactPersonName"
                                            aria-describedby="emailHelp"
                                            value={FormData.ContactPersonName}
                                            onChange={(e) => handleCheckText(e)}
                                        />
                                        {
                                            IsValidText && <text className="RegxValidation">Please enter Alphabet characters only</text>
                                        }
                                    </div>
                                </div>
                            </div>

                            <div class="row pt-4">
                                <div class="col-12 col-md-5">
                                    <div class="col-12">
                                        <label for="exampleInputEmail1" class="form-label">Organization/Individual Name<AstricSign /></label>
                                    </div>
                                </div>
                                <div class="col-12 col-md-7">
                                    <div class="col-12">
                                        <input
                                            type="text"
                                            class="form-control"
                                            name="Position"
                                            onChange={onChange}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div class="row pt-4">
                                <div class="col-12 col-md-5">
                                    <div class="col-12">
                                        <label for="exampleInputEmail1" class="form-label"> Position/Role in the Organization (if applicable)</label>
                                    </div>
                                </div>
                                <div class="col-12 col-md-7">
                                    <div class="col-12">
                                        <input
                                            type="text"
                                            class="form-control"
                                            name="ContactPersonName"
                                            aria-describedby="emailHelp"
                                            value={FormData.ContactPersonName}
                                            onChange={(e) => handleCheckText(e)}
                                        />
                                        {
                                            IsValidText && <text className="RegxValidation">Please enter Alphabet characters only</text>
                                        }
                                    </div>
                                </div>
                            </div>

                            {/* <div className="row pt-4">
                                <div className="col-12 col-md-5">
                                    <div className="col-12">
                                        <label for="exampleInputEmail1" className="form-label">Partner Type<AstricSign /></label>

                                    </div>
                                </div>
                                <div className="col-12 col-md-7">
                                    <div className="col-12">
                                        <PartnerTypeDDL
                                            label={true}
                                            partnerTypeDDL={partnerTypeDDL}
                                            setpartnerTypeDDL={setpartnerTypeDDL}
                                        />
                                    </div>
                                </div>
                            </div> */}





                            <div class="row pt-4">
                                <div class="col-12 col-md-5">
                                    <div class="col-12">
                                        <label for="exampleInputEmail1" class="form-label">Contact Email</label>
                                    </div>
                                </div>
                                <div class="col-12 col-md-7">
                                    <div class="col-12">
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="Email"
                                            value={FormData.Email}
                                            // onChange={onChange} 
                                            onChange={(e) => handleCheckEmail(e)}
                                        />
                                        {
                                            IsValidEmail && <text className="RegxValidation">Please enter valid Email Address</text>
                                        }
                                    </div>
                                </div>
                            </div>




                            <div className="row pt-4">

                                <div className="col-12 col-md-5">
                                    <div className="col-12">
                                        <label for="exampleInputEmail1" className="form-label">Contact
                                            Number<AstricSign /></label>
                                    </div>
                                </div>

                                <div className="col-12 col-md-7">
                                    <div className="col-12">
                                        <div className="input-group ">
                                            <span className="input-group-text">

                                                <select className="" style={{ border: 'none', backgroundColor: '#E9ECEF' }}
                                                    onChange={(e) => {
                                                        setCountryCodeID(e.target.value)
                                                    }}
                                                >
                                                    {
                                                        DDLData && DDLData.length > 0 && DDLData.map((item) => (
                                                            <option key={item.m_CountryID} value={item.m_CountryID} selected>{item.mobileCountryCode}</option>
                                                        ))
                                                    }
                                                </select>
                                            </span>

                                            <input
                                                type="text"
                                                className="form-control"
                                                name="Number"
                                                value={FormData.Number}
                                                // onChange={onChange} 
                                                onChange={(e) => handleCheckNumber(e)}
                                            />
                                            {
                                                IsValidNumber && <text className="RegxValidation">Please enter Numeric characters only</text>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row pt-4">
                                <div className="col-12 col-md-5">
                                    <div className="col-12">
                                        <label for="exampleInputEmail1"
                                            className="form-label">Country/Region<AstricSign /></label>
                                    </div>
                                </div>
                                <div className="col-12 col-md-7">
                                    <div className="col-12">
                                        <CountryDDL
                                            CountryTypeDDL={CountryTypeDDL}
                                            setCountryTypeDDL={setCountryTypeDDL}
                                        />
                                    </div>
                                </div>
                            </div>
                            {/* <div class="row pt-4">
                                <div class="col-12 col-md-5">
                                    <div class="col-12">
                                        <label for="exampleInputEmail1" class="form-label"> Commitment<AstricSign /></label>
                                    </div>
                                </div>
                                <div class="col-12 col-md-7">
                                    <div class="col-12">
                                        <input
                                            type="text"
                                            class="form-control"
                                            name='Commitment'
                                            value={FormData.Commitment}
                                            onChange={onChange}
                                        />
                                    </div>
                                </div>
                            </div> */}
                            <hr class="my-4" />

                            <h6 class="">G-PlaNET Pledge Commitment :</h6>
                            <p class="text-dark">The pledge for G-PlaNET encapsulates a collective commitment
                                to environmental stewardship and sustainable practices on a global scale.
                                It emphasizes several key principles:
                                <p className="paraFont ms-3 mt-2">1. Collaboration: Commitment to work collectively, transcending boundaries, and uniting global citizens,
                                    organizations, and governments in a shared effort towards sustainability.</p>
                                <p className="paraFont ms-3 mt-2">2. Innovation: Dedication to fostering and implementing innovative solutions that drive
                                    ecological transformation and uphold the values of environmental preservation.</p>
                                <p className="paraFont ms-3 mt-2">3. Education and Empowerment: A pledge to educate and empower communities, businesses, and governments
                                    with the necessary knowledge and tools to bring about positive environmental change.</p>
                                <p className="paraFont ms-3 mt-2">4. Responsible Action: A promise to make informed and responsible choices in daily activities, striving to
                                    reduce individual and collective ecological footprints and protect biodiversity.</p>
                                <p className="paraFont ms-3 mt-2">5. Advocacy: A commitment to advocate for policies, practices, and initiatives that prioritize environmental
                                    sustainability, encouraging widespread adoption and support.</p>
                                <p className="paraFont ms-3 mt-2">6. Knowledge Sharing: An aspiration to share experiences, best practices, and lessons learned, fostering open dialogue
                                    and continual learning in the pursuit of a better future for the planet.</p>
                                <p className="paraFont ms-3 mt-2">7. Solidarity: Recognition of the collective power of small individual actions and a commitment to stand together,
                                    acknowledging that unity is crucial for impactful and lasting change.</p>
                            </p>

                            <hr class="my-4" />

                            {/* <h6 class="">Declaration :</h6>
                            <p class="text-dark">By taking the LiFE Pledge, you commit to embracing
                                environmentally conscious lifestyles and actions. Please indicate your
                                commitment below</p>

                            <div class="row pt-4">
                                <div class="col-12 col-md-5">
                                    <div class="col-12">
                                        <label for="exampleInputEmail1" class="form-label">1. How did you learn
                                            about the LiFE Pledge?<AstricSign /></label>
                                    </div>
                                </div>
                                <div class="col-12 col-md-7">
                                    <div class="col-12">
                                        <div className="form-check">
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                value="1"
                                                name="Science"
                                                onChange={handleContribute}
                                            />
                                            <label className="form-check-label" for="flexCheckDefault">
                                                Science
                                            </label>
                                        </div>

                                        <div class="form-check">
                                            <input
                                                class="form-check-input"
                                                type="checkbox"
                                                value="2"
                                                name='Portal'
                                                onChange={handleContribute}
                                            />
                                            <label class="form-check-label" for="flexCheckDefault">
                                                G-PlaNET Portal
                                            </label>
                                        </div>
                                        <div class="form-check">
                                            <input
                                                class="form-check-input"
                                                type="checkbox"
                                                value="3"
                                                name='Event'
                                                onChange={handleContribute}
                                            />
                                            <label class="form-check-label" for="flexCheckDefault">
                                                G-PlaNET Event
                                            </label>
                                        </div>
                                        <div class="form-check">
                                            <input
                                                class="form-check-input"
                                                type="checkbox"
                                                value="4"
                                                name='Event'
                                                onChange={handleContribute}
                                            />
                                            <label class="form-check-label" for="flexCheckDefault">
                                                Social Media
                                            </label>
                                        </div>
                                        <div class="form-check">
                                            <input
                                                class="form-check-input"
                                                type="checkbox"
                                                value="5"
                                                name='Referral'
                                                onChange={handleContribute}
                                            />
                                            <label class="form-check-label" for="flexCheckDefault">
                                                Referral
                                            </label>
                                        </div>

                                        <div className="form-check">
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                value="6"
                                                name="Other"
                                                onChange={(e) => { handleContribute(e); onChangeOther(e) }}
                                            />
                                            <label className="form-check-label" for="flexCheckDefault">
                                                Other
                                            </label>
                                        </div>
                                        {
                                            showOther == true &&
                                            <textarea
                                                className="form-control"
                                                type="text"
                                                value={FormData.OtherText}
                                                placeholder="Other Reason"
                                                name="OtherText"
                                                onChange={onChange}
                                            />
                                        }


                                    </div>
                                </div>
                            </div> */}



                            {/* <h6 class="pt-1">Submit Pledge :</h6> */}
                            <p class="text-dark">By submitting this G-PlaNET Pledge Registration Form, you affirm
                                your commitment to environmental consciousness and sustainability as outlined in
                                the G-PlaNET Pledge.</p>

                            <input type="checkbox" class="form-check-input" id="exampleCheck2"
                            // onChange={handleAgree} 
                            />
                            <label class="form-check-label" for="exampleCheck2">&nbsp; I Agree<AstricSign /></label>
                            {/* <!-- <p class="float-end py-5">
                                    <button type="button" class="btn  lightgreenbg text-white px-4">Submit </button>
                                </p> --> */}

                            <h6 class="pt-5">Pledge Content :</h6>
                            <p class="text-dark">We, the global citizens committed to safeguarding our planet's future,
                                solemnly pledge our dedication to the principles and goals of G-PlaNET:</p>
                            <div className="text-dark">
                                <p className="paraFont ms-3 mt-2">1.	We pledge to collaborate: To work hand in hand, transcending borders and boundaries, pooling our collective
                                    wisdom and resources to foster a sustainable and resilient future for all.</p>
                                <p className="paraFont ms-3 mt-2">2.	We pledge to innovate: To explore, create, and implement innovative solutions that promote ecological transformation, ensuring
                                    that our actions reflect a deep commitment to environmental preservation.</p>
                                <p className="paraFont ms-3 mt-2">3.	We pledge to educate and empower: To educate ourselves and others, empowering communities, businesses, and governments
                                    with the knowledge and tools necessary to enact positive change.</p>
                                <p className="paraFont ms-3 mt-2">4.	We pledge to act responsibly: To make conscientious choices in our daily lives, striving to reduce our ecological
                                    footprint, conserve resources, and protect biodiversity.</p>
                                <p className="paraFont ms-3 mt-2">5.	We pledge to advocate: To be ambassadors for change, advocating for policies, practices, and initiatives
                                    that prioritize environmental sustainability and ecological balance.</p>
                                <p className="paraFont ms-3 mt-2">6.	We pledge to share knowledge: To share experiences, best practices, and lessons learned, fostering a culture
                                    of open dialogue and continuous learning for the betterment of our planet.</p>
                                <p className="paraFont ms-3 mt-2">7.	We pledge solidarity: To stand in solidarity with one another, recognizing that our collective actions,
                                    no matter how small, hold the power to effect meaningful and lasting change.</p>
                                <p class="text-dark">This pledge represents our unwavering commitment to G-PlaNET's vision—a world where environmental conservation, innovation, and collaboration
                                    pave the way for a sustainable and thriving future for generations to come.</p>
                            </div>


                            {/* <p class="text-dark">मैं पर्यावरण की रक्षा के लिए अपनी दैनिक जीवन शैली में हर संभव
                                बदलाव करने का वचन देता / देती हूं।
                                मैं अपने दोस्तों, परिवार और अन्य लोगों को पर्यावरण के अनुकूल आदतों के महत्व के
                                बारे में लगातार याद दिलाने के लिए प्रतिबद्ध हूं।
                            </p>
                            <p class="text-dark">मैं एक उदाहरण के रूप में सेवा करने का वचन देता / देती हूं कि
                                कैसे एक पर्यावरण के अनुकूल जीवन शैली हमारे ग्रह और लोगों को सकारात्मक रूप से
                                प्रभावित कर सकती है।</p> */}
                            <input type="checkbox" class="form-check-input" id="exampleCheck1" onChange={handleAgree} />
                            <label class="form-check-label" for="exampleCheck1">&nbsp; I Agree<AstricSign /></label>

                            <p class="float-end py-5">
                                <div>
                                    <button type="button" class="btn  lightgreenbg text-white px-4"
                                        disabled={isRegistrationLoading || IsDisable}
                                        onClick={handlePost}
                                    >{
                                            isRegistrationLoading ?
                                                <Loading />
                                                :
                                                'Submit'
                                        }
                                    </button>
                                </div>



                            </p>


                        </form>
                        <div className="mt-4 py-5 mx-auto">
                            <text style={{ color: '#006939', textAlign: 'center' }}>{PostMsg}</text>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}