
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toastErrorr, toastSuccesss } from "../../Helper/ToastMessage";
import { BaseUrl } from "../../Helper/BaseURL";

// InsertUpdate Table Data

export const PostChangePasswordAPI = createAsyncThunk("PostChangePassword", async ({ data }) => {
    const {
        Email_ID,
        NewPassword,
        OTP,
        setMessage, handleNavigate, setIsLoading
    } = data

    setIsLoading(true)
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer ");

    var formdata = new FormData();
    formdata.append("Email_ID", Email_ID);
    formdata.append("OTP", OTP);
    formdata.append("NewPassword", NewPassword);

    var requestOptions = {
        method: 'POST',
        body: formdata,
        redirect: 'follow'
    };

    fetch(`${BaseUrl}/Master/Post_M_PartnerRegistrationChangePassword_Update`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 ) {
                setMessage(result.message)
                handleNavigate()
                setIsLoading(false)
                return result.message
            } else {
                setMessage(result.message)
                setIsLoading(false)
                return result.message
            }
        })
})

const PostChangePasswordSlice = createSlice({
    name: "PostChangePassword",
    initialState: {
        isChangeLoading: false,
        Data: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(PostChangePasswordAPI.pending, (state, action) => {
            state.isChangeLoading = true;
        });
        builder.addCase(PostChangePasswordAPI.fulfilled, (state, action) => {
            state.isChangeLoading = false;
            state.Data = action.payload;
        });
        builder.addCase(PostChangePasswordAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isChangeLoading = false;
            state.isError = true;
            state.Data = null;
            // toastErrorr('Something went wrong')
        });
    },
})

export const PostChangePasswordReducer = PostChangePasswordSlice.reducer