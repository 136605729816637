import { useEffect, useState } from "react"
import { PartnerNameDDL } from "../../../../../Components/DDL/PartnerNameDDL/PartnerNameDDL"
import { useAuthState } from "../../../../../Helper/Context"
import { useDispatch, useSelector } from "react-redux"
import NewsLetter from "../../Multimedia/NewsLetter/NewsLetter"
import { NewsLetterPostAPI } from "../../../../../Redux/AdminSlice/NewsLetterAdminSlice/NewsLetterAdminSlice"
import { useSearchParams } from "react-router-dom"
import { AstricSign } from "../../../../../Helper/AstricSign/AstricSign"
import { RegExIsURLValid } from "../../../../../Helper/Regx/Regx"
import { CommonSamplePDFUploadPostAPI, CommonSampleReportAPIDataAPI } from "../../../../../Redux/AdminSlice/SampleReport/SampleReportSlice"
import { toastErrorr } from "../../../../../Helper/ToastMessage"
import moment from "moment"

export const AddNewsLetterAdmin = (props) => {
    const { handleOnAddClick, handleIsPost } = props

    const userDetails = useAuthState()
    const { UserID, token, RoleID } = userDetails
    const dispatch = useDispatch()

    const [params] = useSearchParams()
    const username = params.get('username');
    const IndicatorName = params.get('IndicatorName');

    const [To, setTo] = useState(10)
    const [From, setFrom] = useState(1)

    const [Subject, setSubject] = useState('')
    const [Document, setDocument] = useState('')
    const [NewsLetterDate, setNewsLetterDate] = useState('')
    const [NewsLetterURL, setNewsLetterURL] = useState('')
    const [loading, setloading] = useState(false)
    const [SamplePDFPostLoading, setSamplePDFPostLoading] = useState(false)
    const [SamplePDFDocument, setSamplePDFDocument] = useState(null)
    const [SampleDocumentType, setSampleDocumentType] = useState(null)

    const [NewType, setNewType] = useState('')

    const [partnerNameDDL, setpartnerNameDDL] = useState({
        DDL: [],
        ID: 0,
        Label: 'Select...'
    })

    const handleFileInputChange = (e) => {
        let file = e.target.files[0];
        return new Promise(resolve => {
            let baseURL = "";
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                baseURL = reader.result;
                // console.log(reader.result.split(',')[1])
                setDocument(reader.result.split(',')[1])
                resolve(baseURL);
            };
        });
    };

    const handleSamplePDFUPloadFileInputChange = (e) => {
        let file = e.target.files[0];
        return new Promise(resolve => {
            let baseURL = "";
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                baseURL = reader.result;
                // console.log(reader.result.split(',')[1])
                const type = baseURL.split(';')[0].split('/')[1];
                setSampleDocumentType('.' + type)
                setSamplePDFDocument(reader.result.split(',')[1])
                resolve(baseURL);
                // console.log('SampleDocumentType',type)
            };
        });
    };

    const handlePost = () => {
        const data = {
            Subject: Subject,
            MediaDate: NewsLetterDate,
            NewsLetterDoc: Document,
            NewsURL: NewsLetterURL,
            NewType: NewType,
            M_UserID: UserID,
            token: token,
            Flag: username == 'admin' ? 'Admin' : 'Partner',
            handleIsPost: handleIsPost,
            handleAddCloseClick: handleOnAddClick,
            setloading: setloading
        }
        dispatch(NewsLetterPostAPI({ data }))
    }

    const [IsURLValid, setIsURLValid] = useState(false)
    const handleCheckEmail = (e) => {
        setNewsLetterURL(e.target.value)
        const IsValid = RegExIsURLValid(e.target.value)
        setIsURLValid(IsValid)
        return IsValid
    }

    useEffect(() => {
        const data = {
            M_SampleReportID: 0,
            SampleReport_M_IndicatorID: IndicatorName,
            M_UserID: UserID,
            token: token,
            From: From,
            To: To,
        }
        dispatch(CommonSampleReportAPIDataAPI({ data }))
    }, [From, To, IndicatorName])

    const { tableData, isLoading } = useSelector(state => state.CommonSampleReportAPIData)

    const handleOpenPDF = () => {
        if (tableData && tableData[0]?.sampleReport_Path != null) {
            window.open(tableData[0]?.sampleReport_Path);
        } else {
            toastErrorr('Sample Report is not available!');
        }
    }

    const handleSamplePDFPost = () => {
        const data = {
            M_FinancialYearID: moment().format('YYYY'),
            M_MonthID: moment().format('MM'),
            SampleReport_M_IndicatorID: IndicatorName,
            SampleReport_Path: SamplePDFDocument,
            SampleReport_FileExtension: SampleDocumentType,
            handleIsPost: handleIsPost,
            M_UserID: UserID,
            token: token,
            setSamplePDFPostLoading: setSamplePDFPostLoading
        }
        dispatch(CommonSamplePDFUploadPostAPI({ data }))
    }

    return (
        <div className="modal show fade px-lg-5" id="exampleModal"
            style={{ display: "block", backgroundColor: "#00000055", overflow: 'auto' }}
            tabindex="-1" aria-labelledby="exampleModalLabel"
            aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header px-lg-5 bg-white">
                        <h5 className="modal-title lightgreencolor" id="exampleModalLabel">ADD News / NewsLetter
                        </h5>
                        <button type="button" className=" bg-danger text-white px-2 border-0 rounded fs-6"
                            data-bs-dismiss="modal" onClick={handleOnAddClick} aria-label="Close"> &times; </button>
                    </div>
                    <div className="modal-body px-3" style={{ overflowY: 'scroll' }}>
                        <form>
                            <div className='row'>
                                <div className="col-12 ps-4">
                                    <label for="exampleInputEmail1" className="form-labels me-3">News Type <AstricSign />: </label>
                                    <div className="form-check form-check-inline">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="inlineRadioOptions"
                                            id="inlineRadio1"
                                            value='News'
                                            onChange={(e) => setNewType(e.target.value)}
                                        />
                                        <label className="form-check-label fw-bold" for="inlineRadio1">News</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="inlineRadioOptions"
                                            id="inlineRadio2"
                                            value="NewsLetter"
                                            onChange={(e) => setNewType(e.target.value)}
                                        />
                                        <label className="form-check-label fw-bold" for="inlineRadio2">NewsLetter</label>
                                    </div>
                                </div>
                                <hr className="mt-3" />
                                <div className="row mt-2">
                                    <div className="col-12 col-md-6 col-lg-6 ">
                                        <label for="exampleInputEmail1" className="form-labels">Subject<AstricSign /></label>
                                        <input type="text" className="form-control"
                                            aria-describedby="emailHelp"
                                            value={Subject}
                                            onChange={(e) => setSubject(e.target.value)}
                                        />
                                    </div>

                                    <div className="col-12 col-md-6 col-lg-6 ">
                                        <label for="exampleInputEmail1" className="form-labels">Date<AstricSign /></label>
                                        <input type="date" className="form-control"
                                            aria-describedby="emailHelp"
                                            value={NewsLetterDate}
                                            onChange={(e) => setNewsLetterDate(e.target.value)}
                                        />

                                    </div>

                                    {/* <div className="col-12 col-md-6 col-lg-6 mt-3">
                                        <label for="exampleInputEmail1" className="form-labels">PDF
                                        </label>
                                        <input
                                            type="file"
                                            accept="application/pdf"
                                            className="form-control"
                                            id="exampleInputEmail1"
                                            aria-describedby="emailHelp"
                                            onChange={handleFileInputChange}
                                        />
                                    </div> */}
                                    <div class="col-12 col-md-6 mt-3">
                                        <label for="exampleInputEmail1" class="form-label pt-1 mb-0">Upload PDF <AstricSign />
                                            <a style={{ fontSize: '13px', textDecoration: 'underline', color: 'blue', cursor: 'pointer' }}
                                                onClick={handleOpenPDF}>(Click to View Sample Report)</a>
                                        </label>
                                        <input type="file" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"
                                            onChange={handleFileInputChange}
                                        />
                                        <small className="text-danger" style={{ fontSize: '12px' }}>  Upload a PDF File of up to 3 MB</small>
                                    </div>

                                    <div className="col-12 col-md-6 col-lg-6 ">
                                        <label for="exampleInputEmail1" className="form-label pt-3 mb-0"> URL
                                        </label>
                                        <input type="text" className="form-control mt-1" id="exampleInputEmail1"
                                            aria-describedby="emailHelp"
                                            value={NewsLetterURL}
                                            // onChange={(e) => setNewsLetterURL(e.target.value)}
                                            onChange={(e) => handleCheckEmail(e)}
                                        />
                                        {
                                            IsURLValid && <text className="RegxValidation">Please enter valid URL</text>
                                        }
                                    </div>

                                    <div className=" col-12 pb-2">
                                        <button type="button" className="btn  lightgreenbg float-end text-white px-4"
                                            disabled={IsURLValid || loading || NewType == '' || Subject == '' || NewsLetterDate == ''
                                                // || Document == '' || NewsLetterURL == ''
                                            }
                                            onClick={handlePost}
                                        >
                                            {loading && (
                                                <i
                                                    className="fa fa-spinner fa-spin"
                                                    style={{ marginRight: "5px" }}
                                                />
                                            )}
                                            {!loading && <span> <i class="fa-solid fa-upload pe-1 fs-6"></i> Upload</span>}
                                        </button>
                                    </div>

                                    {
                                        RoleID == 1 &&
                                        <>
                                            <div class="col-12 col-md-6 py-2 border-top ">
                                                <label for="exampleInputEmail1" class="form-label pt-1">Sample Report<AstricSign />
                                                </label>
                                                <input type="file" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" accept=".pdf"
                                                    onChange={handleSamplePDFUPloadFileInputChange}
                                                />
                                                <small className="text-danger" style={{ fontSize: '12px' }}>Upload a PDF File of up to 3 MB</small>
                                            </div>
                                            <div class="col-12 col-md-6 border-top pt-5 ">
                                                <button class="btn btn-primary text-white fw-200 px-3 fs-6 mx-3" type="submit"
                                                    disabled={SamplePDFPostLoading || SamplePDFDocument == null || SamplePDFDocument == ''}
                                                    onClick={handleSamplePDFPost}>
                                                    {SamplePDFPostLoading && (
                                                        <i
                                                            className="fa fa-spinner fa-spin"
                                                            style={{ marginRight: "5px" }}
                                                        />
                                                    )}
                                                    {!SamplePDFPostLoading && <span><i class="fa-solid fa-floppy-disk pe-1"></i> Save</span>}
                                                </button>
                                            </div>
                                        </>
                                    }

                                </div>
                            </div>

                            {/* <div className='row mt-4'>

                                <div className="col-6">
                                    <label for="exampleInputEmail1" className="form-label pt-1">Subject
                                    </label>
                                    <input type="date" className="form-control" id="exampleInputEmail1"
                                        aria-describedby="emailHelp" />
                                </div>

                                <div className="col-6">
                                    <label for="exampleInputEmail1" className="form-label pt-1">Upload PDF
                                    </label>
                                    <input type="file" className="form-control" id="exampleInputEmail1"
                                        aria-describedby="emailHelp" />
                                </div>
                            </div> */}




                        </form>

                    </div>
                    {/* <!-- <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    <button type="button" className="btn btn-primary">Save changes</button>
                </div> --> */}
                </div>
            </div>
        </div>
    )
}