import React from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { useNavigate } from 'react-router'
import Bullet from '../../../../../Helper/Bullet'

const HomeGCP = () => {
    const navigate = useNavigate()
    return (
        <div>
            <div>

                {/* <!--Start GCP Section--> */}

                <div className="row px-4 py-2 px-md-5 py-md-1 d-flex align-items-center pt-4 pt-lg-5" id="about_gplanet">
                    <div className="col-12 col-md-8">

                        <h2 className="lightgreencolor">About Green Credit Initiative</h2>
                        <div>
                            <span className="gcp_about_ul mt-3">
                                <Bullet />
                                <p className="text-dark justify">
                                The Green Credit Initiative serves to incentivize voluntary tree planting nationwide, issuing Green Credits and creating a land inventory for Afforestation programs. India's Prime Minister highlighted this initiative at the G20 summit, emphasizing the need to promote climate-positive actions and sustainable business via the Green Credit Framework.
                                </p>
                            </span>

                        </div>


                        <div>
                            <span className="gcp_about_ul mt-3">
                                <Bullet />
                                {/* <li className="px-2 fs-4 mobile_none">
                               
                                <i className="ri-arrow-right-double-line lightgreencolor"></i>
                              
                            </li> */}
                                <p className="text-dark justify">
                                The Green Credit Initiative aims to register land on a web portal for plantation activities, open to various entities like individuals, communities, and mining companies with degraded land. This land bank is accessible for voluntary, CSR-driven, and Accredited Compensatory Afforestation plantations, encompassing various land types such as degraded forests, watersheds, waste land, and more. Activities related to plantation, whether on land from the bank or other sources, can be registered by eligible entities, leading to the issuance of Green credits based on government-notified methodologies. The process leverages technology-based tools, including a web platform and registry, for digital registration, verification, and monitoring of plantation activities.
                                </p>
                            </span>

                        </div>



                    </div>
                    <div className='col-10 mx-auto col-md-4 text-center'>
                        {/* <img src="assets/img/top_logo/GCP_logo.png" alt="" className="top_logos" style={{ width: "100px" }} /> */}
                        <LazyLoadImage effect='blur' src="https://ik.imagekit.io/gplanet/Gplanet/img/GCP_logo.png?updatedAt=1701510152620" height='100%' width="95%" alt=""/>

                        {/* <img src="assets/img/top_logo/GCP_logo.png" width="80%" alt="" /> */}
                    </div>

                </div>
                {/* <div>
                    <span className="gcp_about_ul py-2">
                        <Bullet />
                        <li className="text-dark justify">
                            Registration of land on the web portal to build a land bank which can be offered for plantation activities.
                            Entities who can register land for plantations include Individuals, Gram panchayat/municipalities, Communities,
                            Institutions, Mining companies for reclaimed mining land, Forest departments for degraded forest land.
                            This land inventory will be available to individuals/entities for voluntary plantation, plantation under CSR and for plantation under Accredited Compensatory Afforestation.
                            The types of land that can be registered include Degraded Forest Land, Watershed areas, Waste Land, Community Land, Land Owned by individuals/organisations, Land owned by Other central/state govt agencies.
                            This land inventory will be available to individuals/entities for voluntary plantation, plantation under CSR and for plantation under ACA.
                        </li>
                    </span>

                    <span className="gcp_about_ul py-2">
                        <Bullet />
                        <li className="text-dark justify">
                            Registration of activities related to plantation on the web portal, either on land selected from the land bank or any other land.
                            The entities who can undertake these activities include individuals/institutions/communities etc.
                        </li>
                    </span>
                    <span className="gcp_about_ul py-2">
                        <Bullet />
                        <li className="text-dark justify">
                            The individual/entities who carry put tree plantation activities will be issued Green credits, as per methodology notified by the central government.
                        </li>
                    </span>
                    <span className="gcp_about_ul py-2">
                        <Bullet />
                        <li className="text-dark justify">
                            Registration of the activity will be done using technology-based tools.
                            Digital processes have been developed including a web platform and a registry for the programme.
                            The verification of the details submitted for registration will also be undertaken through technology/digital tools, primarily.
                        </li>
                    </span>
                </div> */}

            </div >

            {/* <div className="row px-4  px-md-5 pb-3" id="Objectives">
                <div className="col-12 mx-auto">
                    <h2 className="lightgreencolor text-start pb-3">Outcomes</h2>

                    <span className="gcp_about_ul py-2">
                        <li className="px-2 fs-5 mobile_none"> <i className="ri-check-double-line lightgreencolor"></i></li>
                        <li className="text-dark justify">
                            GCP will encourage private sector as well as other entities to meet their existing obligations, stemming from other legal frameworks, by undertaking plantations for generating green credits.
                        </li>
                    </span>

                    <span className="gcp_about_ul py-2">
                        <li className="px-2 fs-5 mobile_none"> <i className="ri-check-double-line lightgreencolor"></i></li>
                        <li className="text-dark justify">
                            Private sector, through generation of green credit, may be able to demonstrate environmental and social responsibility.
                            This may help the private sector access low cost capital from investors as well as financial institutions.
                        </li>
                    </span>
                </div>
            </div> */}
            < div >
                <p className="pt-5 cursor-pointer pb-5"><span className="float-lg-start ps-5"> <a href='https://www.moefcc-gcp.in/' target='_blank'
                    className="border border-success py-2 rounded px-5 lightgreencolor">View More <i
                        className="fas fa-angle-right"></i></a></span></p>
                {/* </ > */}
                {/* <!--end of row Objectives--> */}


                {/* <!--end of row Green Credits--> */}



            </div >
        </div >
    )
}

export default HomeGCP