import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { useAuthState } from "../../../../../Helper/Context"
import { NewsLetterDataAPI } from "../../../../../Redux/MultimediaSlice/MultimediaSlice"
import { NoData } from "../../../../../Helper/NoData/NoData"
import moment from "moment"
import { LazyLoadImage } from "react-lazy-load-image-component"

export const HomeNewLetters = () => {

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const userDetails = useAuthState()
    const { RoleID, UserID, token } = userDetails

    useEffect(() => {
        const data = {
            MediaDate: '',
            Flag: 'WEB',
            M_UserID: '0',
            token: token,
            From: '1',
            To: '4',
        }

        dispatch(NewsLetterDataAPI({ data }))
    }, [])

    const { isLoading, tableData } = useSelector(state => state.NewsLetterData)


    return (
        <div className="tab-pane fade show active" >
            <div className="row">
                {
                    tableData && tableData.length > 0 ? tableData.map(i => {
                        return (
                            <>

                                <div class="col-12 col-md-6 px-4 ">
                                    <div class="pt-4">
                                        <LazyLoadImage effect='blur' src="assets/img/pdf.png" alt="About" style={{ width: '25px' }} className="py-2" />
                                        <span class="text-dark ps-2"> {i?.subject}</span>

                                        <p class="py-3 font_size fw-lighter border-bottom pb-3">
                                            <LazyLoadImage effect='blur' src="assets/img/calendar.png" alt="About" style={{ width: '15px' }} className="me-2" />
                                            <span>{i.mediaDate ? moment(i.mediaDate).format('MMMM DD YYYY') : ''}</span>
                                            <a href={i?.newsDoc} target='_blank' class="float-end text-success fs-6">Read More... <i
                                                class="fa-solid fa-right-to-bracket ps-2"></i></a>
                                        </p>

                                    </div>
                                </div>
                            </>
                        )
                    })
                        :

                        <div class="row">
                            <div className='col-12 col-md-4 mx-auto text-center '>
                                <NoData />
                            </div>
                        </div>
                }

                <p className="pt-5 cursor-pointer"><span className="float-lg-end"> <a onClick={() => navigate('/news')}
                    className="border border-success py-2 rounded px-5 lightgreencolor">Explore <i
                        className="fas fa-angle-right"></i></a></span></p>
            </div>
        </div>
    )
}