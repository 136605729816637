

import { useEffect } from 'react'
import Select from 'react-select'
import { useDispatch, useSelector } from 'react-redux'
import { EventNameDDLAPI } from '../../../Redux/DDLSlice'
import { useAuthState } from '../../../Helper/Context'


export const EventNameDDL = (props) => {
    const { eventNameDDL, seteventNameDDL, apiFlag, rowData, IsPost } = props

    const dispatch = useDispatch()
    const userDetails = useAuthState();
    const { UserID, token } = userDetails

    useEffect(() => {
        const data = {
            UserID: UserID,
            token: token,
            M_EventTypeID: '0'
        }
        dispatch(EventNameDDLAPI({ data }))
    }, [])

    const { DDLData } = useSelector(state => state.EventNameDDLData)

    useEffect(() => {
        handleDDL()
    }, [DDLData])

    const handleDDL = () => {
        if (DDLData && DDLData.length > 0) {
            let list = DDLData.map((item, index) => ({
                value: item.m_EventID,
                label: item.eventSubject,
            }))

            seteventNameDDL({
                DDL: list,
                ID: apiFlag == 'Update' ? rowData?.m_EventID : 0,
                Label: apiFlag == 'Update' ? rowData?.eventName : "Select...",

            })
        }
        else {
            seteventNameDDL({
                DDL: [],
                ID: 0,
                Label: "Select...",
            })
        }
    }

    return (
        <div className="form-group">
            <label className="d-block" htmlFor="NameofDepartment">Event Name</label>
            <Select
                isClearable
                // isRtl={isRtl}
                isSearchable
                maxMenuHeight={150}
                value={{ value: eventNameDDL.ID, label: eventNameDDL.Label }}
                onChange={(e) => {
                    e ?
                        seteventNameDDL({ ...eventNameDDL, ID: e.value, Label: e.label })
                        :
                        seteventNameDDL({ ...eventNameDDL, ID: 0, Label: "Select..." })

                }}
                options={eventNameDDL.DDL}
            />
        </div>
    )
}