import React, { useState } from 'react'
import AdminHeader from '../../../../../Components/Header/AdminHeader'
import Sidebar from '../../../../../Components/Sidebar/Sidebar'
import { PartnerTypeDDL } from '../../../../../Components/DDL/PartnerTypeDDL/PartnerTypeDDL'
import FAB from '../../../../../Components/FAB/FAB'
import { AddNewsAdmin } from './AddNewsAdmin'
import { PublishNewsAdmin } from './PublishNewsAdmin'
import { PublishReportPopUp } from '../KnowledgeRepositoryReport/Action/PublishReportPopUp'
import { CommonDeletePopUp } from '../KnowledgeRepositoryReport/Action/CommonDeletePopUp'

export const NewsAdmin = () => {

    const [partnerTypeDDL, setpartnerTypeDDL] = useState({
        DDL: [],
        ID: 0,
        Label: "Select...",
    })


    const [showAddPopUp, setshowAddPopUp] = useState(false)
    const [editData, setEditData] = useState('')
    // const [showPublishPopUp, setshowPublishPopUp] = useState(false)
    // const [showDeletePartnerPopUp, setshowDeletePartnerPopUp] = useState(false)
    const [PublishPopUp, setPublishPopUp] = useState(false)
    const [DeletePopUp, setDeletePopUp] = useState(false)

    const [Heading, setHeading] = useState('')


    const handleOnAddClick = () => {
        console.log("first")
        setshowAddPopUp(!showAddPopUp)
    }

    // const handlePublishPopup = () => {
    //     // console.log("first")
    //     setshowPublishPopUp(!showPublishPopUp)
    // }

    const PublishButtonClick = (heading, id) => {
        setPublishPopUp(true)
        setEditData()
        // setActionId(id)
        setHeading(heading)
    }

    const handleDeletePopUp = () => {
        setDeletePopUp(true)
    }
    const handlePublishCloseClick = () => {
        setPublishPopUp(false)
        setDeletePopUp(false)
    }


    return (
        <>
            <AdminHeader />
            {/* <Sidebar active="dashboard" /> */}
            <div class="row flex-nowrap gr wrapper">
                <Sidebar active='News' />
                <div class="col py-3 text-dark" id='content'>
                    <div class="page-content">
                        <span className='d-flex justify-content-between align-items-center mx-3'>
                            <h5 class=" fw-bold my-3 "> News
                            </h5>
                            {/* <button className='add-btn' onClick={() => navigate()}>Add</button> */}

                        </span>

                        {/* Modal */}

                        <div class="row px-lg-5">
                            <div class="col-12 px-lg-5">
                                <div class="modal fade px-lg-5" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel"
                                    aria-hidden="true">
                                    <div class="modal-dialog">
                                        <div class="modal-content">
                                            <div class="modal-header px-lg-5 bg-white">
                                                <h5 class="modal-title lightgreencolor" id="exampleModalLabel">News
                                                </h5>
                                                {/* <button type="button" class=" bg-danger text-white px-2 border-0 rounded fs-4"
                                                    data-bs-dismiss="modal" aria-label="Close"> &times; </button> */}
                                            </div>
                                            <div class="modal-body px-5" style={{ height: '50px', overflowY: 'scroll' }}>
                                                <form>
                                                    <div className='row'>

                                                        <div class="col-6">
                                                            <label for="exampleInputEmail1" class="form-label pt-1">Date
                                                            </label>
                                                            <input type="date" class="form-control" id="exampleInputEmail1"
                                                                aria-describedby="emailHelp" />
                                                        </div>

                                                        <div class="col-6">
                                                            <label for="exampleInputEmail1" class="form-label pt-1">Subject
                                                            </label>
                                                            <input type="date" class="form-control" id="exampleInputEmail1"
                                                                aria-describedby="emailHelp" />
                                                        </div>
                                                    </div>

                                                    <div className='row mt-4'>

                                                        <div class="col-6">
                                                            <label for="exampleInputEmail1" class="form-label pt-1">News
                                                            </label>
                                                            <input type="file" class="form-control" id="exampleInputEmail1"
                                                                aria-describedby="emailHelp" />
                                                        </div>
                                                    </div>

                                                    <p class="float-end py-5">
                                                        <button type="button" class="btn  lightgreenbg text-white px-4">Upload </button>
                                                    </p>


                                                </form>

                                            </div>
                                            {/* <!-- <div class="modal-footer">
                                                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                                <button type="button" class="btn btn-primary">Save changes</button>
                                            </div> --> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row  card p-2">
                            <div class="col-12 ">
                                {/* <div className='row mb-3'>

                                    <div className="col-3">
                                        <label for="exampleInputEmail1" className="form-label">Upload Date
                                        </label>
                                        <input type="date" className="form-control" id="exampleInputEmail1"
                                            aria-describedby="emailHelp" />
                                    </div>

                                    <div class="col-3">
                                        <PartnerTypeDDL
                                            partnerTypeDDL={partnerTypeDDL}
                                            setpartnerTypeDDL={setpartnerTypeDDL}
                                        />
                                    </div>
                                </div> */}

                                <div class="bg-light">
                                    <div class="row grayish py-3 d-flex align-items-end">

                                        <div class="col-12 col-md-6 col-lg-3">
                                            <div className="form-group">
                                                <label className="d-block" htmlFor="NameofDepartment">Upload Date</label>
                                                <input
                                                    type="date"
                                                    className="form-control"
                                                    id="AssetName"
                                                // value={AssetName}
                                                // onChange={(e) => setAssetName(e.target.value)}
                                                />
                                            </div>
                                        </div>

                                        <div class="col-12 col-md-6 col-lg-3">
                                            <PartnerTypeDDL
                                                partnerTypeDDL={partnerTypeDDL}
                                                setpartnerTypeDDL={setpartnerTypeDDL}
                                            />
                                        </div>

                                        {/* <div className="col-12 col-md-6 col-lg-3 mt-3">
                                            <button type="button" className="clear_button">Clear</button>
                                        </div> */}

                                    </div>
                                </div>

                                <div class="col-12 pb-5" style={{ overflowX: 'scroll', width: '100%' }}>
                                    {/* <!-- <h3 class="lightgreencolor pb-3">Participants</h3> --> */}
                                    <table>
                                        <thead>
                                            <tr class="header lightgreenbg whitecolor">
                                                <th>Sr. No.</th>
                                                <th>Date</th>
                                                <th>Photo</th>
                                                <th>Video</th>
                                                <th>PDF Document</th>
                                                <th>URL</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>


                                            <tr>
                                                <td>1</td>
                                                <td>Namita Dilip Pawar</td>
                                                <td>Organization</td>
                                                <td>Alfreds Futterkiste</td>
                                                <td>Cdat@gmail.com</td>
                                                <td>7532154512</td>
                                                <td style={{ textAlign: 'center' }}>
                                                    <span className='' style={{ cursor: 'pointer' }}>
                                                        <i class="fa-solid fa-arrow-up-from-bracket fs-6" onClick={() => PublishButtonClick('Publish PopUp')} title='Publish'></i>
                                                        {/* {
                                                            showPublishPopUp &&
                                                            <PublishNewsAdmin handlePublishPopup={handlePublishPopup} />
                                                        } */}

                                                    </span>
                                                    <span className='px-2'></span>
                                                    <span style={{ cursor: 'pointer' }}>
                                                        <i class="fa-solid fa-trash-can fs-6" onClick={() => handleDeletePopUp()} title='Delete' style={{ color: 'red' }}></i>
                                                    </span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>

                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <FAB toggleState={showAddPopUp} settoggleState={handleOnAddClick} />

            {
                showAddPopUp &&
                <AddNewsAdmin handleOnAddClick={handleOnAddClick} />
            }

            {
                PublishPopUp && <PublishReportPopUp
                    open={PublishPopUp}
                    heading={Heading}
                    Lable='Publish News'
                    handleAddCloseClick={handlePublishCloseClick}
                // handlePublish={handlePublish}
                />
            }
            {
                DeletePopUp && <CommonDeletePopUp
                    open={DeletePopUp}
                    heading={Heading}
                    Lable='News '
                    handleAddCloseClick={handlePublishCloseClick}
                // handlePublish={handlePublish}
                />
            }

        </>
    )
}
