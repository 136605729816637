
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toastErrorr, toastSuccesss } from "../../Helper/ToastMessage";
import { BaseUrl } from "../../Helper/BaseURL";

// InsertUpdate Table Data

export const PostPledgeRegistrationAPI = createAsyncThunk("PostPledgeRegistration", async ({ data }) => {
    const {
        M_CountryNameID,
        M_PartnerTypeID,
        Position_InOrganization,
        ContactPersonName,
        Email_ID,
        MobileNo,
        Address,
        Commitment,
        LIFEPledgeText,
        Learn_About_LIFEPledge,
        OtherText,
        handlePostRegistration,
        setPostMsg
    } = data

    setPostMsg('Submitting ..')
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer ");

    var formdata = new FormData();
    formdata.append("M_CountryNameID", M_CountryNameID);
    formdata.append("M_PartnerTypeID", M_PartnerTypeID);
    formdata.append("ContactPersonName", ContactPersonName);
    formdata.append("Position_InOrganization", Position_InOrganization);
    formdata.append("Email_ID", Email_ID);
    formdata.append("MobileNo", MobileNo);
    formdata.append("Address", Address);
    formdata.append("Commitment", Commitment);
    formdata.append("LIFEPledgeText", LIFEPledgeText);
    formdata.append("Learn_About_LIFEPledge", Learn_About_LIFEPledge);
    formdata.append("OtherText", OtherText);
    formdata.append("M_UserID", "0");

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
    };

    fetch(`${BaseUrl}/Master/Post_M_PledgeRegistration_Insert`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                handlePostRegistration(result.message)
                return result.data
            } else {
                // handlePostRegistration()
                setPostMsg(result.message)
            }
            return result
        })
})

const PostPledgeRegistrationSlice = createSlice({
    name: "PostPledgeRegistration",
    initialState: {
        isRegistrationLoading: false,
        tableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(PostPledgeRegistrationAPI.pending, (state, action) => {
            state.isRegistrationLoading = true;
        });
        builder.addCase(PostPledgeRegistrationAPI.fulfilled, (state, action) => {
            state.isRegistrationLoading = false;
            state.tableData = action.payload;
        });
        builder.addCase(PostPledgeRegistrationAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isRegistrationLoading = false;
            state.isError = true;
            state.tableData = null;
            // toastErrorr('Something went wrong')
        });
    },
})

export const PostPledgeRegistrationReducer = PostPledgeRegistrationSlice.reducer