import { useEffect, useState } from "react"
import Footer from "../../../../Components/Footer/Footer"
import Header from "../../../../Components/Header/Header"
import { useNavigate } from "react-router-dom"
import { loginUser, logout, useAuthDispatch, useAuthState } from "../../../../Helper/Context"
import { current } from "@reduxjs/toolkit"
import { useDispatch, useSelector } from "react-redux"
import { RegExNumbersOnly } from "../../../../Helper/Regx/Regx"
import { VerifyOTPAPI } from "../../../../Redux/OTPSlice/OTPSlice"


export const OTPScreen = (props) => {

    const { handleNext, FormData, setFormData, ScreenMsg,handleResendOTP,isFLoading } = props

    const dispatch = useDispatch()


    const [OTP, setOTP] = useState("")
    const [Message, setMessage] = useState('')

    const [IsValidNumber, setIsValidNumber] = useState(false)
    const handleCheckNumber = (e) => {
        setMessage('')
        setOTP(e.target.value)
        const IsValid = RegExNumbersOnly(e.target.value)
        setIsValidNumber(IsValid)
        return IsValid
    }



    const handleSubmit = () => {
        setFormData({
            ...FormData,
            OTP: OTP
        })
        const data = {
            OTP: OTP,
            setMessage: setMessage,
            handleNext: handleNext
        }
        dispatch(VerifyOTPAPI({ data }))
    }

    const { isLoading, Data } = useSelector(state => state.VerifyOTPData)

    return (
        <div className="container-fluid g-0">

            <Header />

            {/* <!-- login Form --> */}
            <div className="row login_page p-4 p-md-5">

                <div className=" col-lg-7">

                </div>

                <div className=" col-12 col-lg-5 bg-white rounded">
                    <div className="bg-white px-md-5 pb-md-5 pt-md-4 px-4 py-4">
                        <text className="screenmsg">{ScreenMsg}</text>
                        <p><text className="screenmsg" style={{ color: 'green' }}>OTP Valid for 2 Minitues</text></p>
                        <div className="px-lg-5 pt-3 pb-5">
                            <form>
                                <div className="mb-3 py-2">
                                    <label for="exampleInputPassword1"
                                        className="form-label lightgreencolor fw-bold">OTP :</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="exampleInputPassword1"
                                        value={OTP}
                                        onChange={(e) => handleCheckNumber(e)}
                                    />
                                    {
                                        IsValidNumber && <text className="RegxValidation">Please enter valid OTP</text>
                                    }
                                </div>

                                <button className="lightgreenbg all_button w-100"
                                    type="button"
                                    disabled={isLoading || isFLoading || IsValidNumber || OTP == ""}
                                    style={{ cursor: (isLoading || isFLoading|| IsValidNumber || OTP == "") ? 'not-allowed' : 'pointer' }}
                                    onClick={() => handleSubmit()}
                                >
                                    {(isLoading || isFLoading) && (
                                        <i
                                            className="fa fa-spinner fa-spin"
                                            style={{ marginRight: "5px" }}
                                        />
                                    )}
                                    {(!isLoading || !isFLoading) && <span>Verify</span>}
                                </button>

                                <text className="screenmsg">Did not Received? <text onClick={()=>handleResendOTP()} className="cursor-pointer">Resend OTP</text></text>

                                <p className="screenmsg" style={{color:'red', marginBottom:'-5%'}}>{Message}</p>

                            </form>
                        </div>
                    </div>
                </div>

            </div>

            <Footer />
        </div >

    )
}