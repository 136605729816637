import Footer from "../../../Components/Footer/Footer"
import Header from "../../../Components/Header/Header"
import { PagesHeroSection } from "../../../Components/PagesHeroSection/PagesHeroSection"

export const Participants = () => {
    return (
        <div class="container-fluid g-0">
            <Header />

            <PagesHeroSection
                Heading='Participants'
                BreadScrum1='Home'
                BreadScrum2='Join the G-PlaNET Pledge'
            />


            <div class="row p-5">

                <div class="col-12 ">
                    <h2 class="lightgreencolor">Participants
                        <span class="float-end">
                            <div class="search-box d-flex justify-content-center align-items-center">
                                <input class="search-input" type="text" name="" placeholder="Type Here" />
                                <a href="#" class="search-btn">
                                    <i class="fas fa-search fs-5"></i>
                                </a>
                            </div>
                        </span>
                    </h2>
                </div>
                <hr class=" mt-4 mb-5" />

                <div style={{ overflowX: 'scroll', width: '100%' }}>
                    <table class="bg-white text-dark"   >
                        <tr class="header lightgreenbg text-white">
                            <th>Sr. No.</th>
                            <th>Full Name</th>
                            <th>Participants Type</th>
                            <th>Position/Role in the Organization </th>
                            {/* <th> Email</th>
                            <th>Contact Number</th> */}
                            <th>Commitment</th>
                        </tr>
                        <tr>
                            <td>1</td>
                            <td>Namita Dilip Pawar</td>
                            <td>Organization</td>
                            <td>Alfreds Futterkiste</td>
                            {/* <td>Cdat@gmail.com</td>
                            <td>7532154512</td> */}
                            <td>India</td>
                        </tr>

                        <tr>
                            <td>2</td>
                            <td>Namita Dilip Pawar</td>
                            <td>Organization</td>
                            <td>Alfreds Futterkiste</td>
                            {/* <td>Cdat@gmail.com</td>
                            <td>7532154512</td> */}
                            <td>India</td>
                        </tr>

                        <tr>
                            <td>3</td>
                            <td>Namita Dilip Pawar</td>
                            <td>Organization</td>
                            <td>Alfreds Futterkiste</td>
                            {/* <td>Cdat@gmail.com</td>
                            <td>7532154512</td> */}
                            <td>India</td>
                        </tr>

                        <tr>
                            <td>4</td>
                            <td>Namita Dilip Pawar</td>
                            <td>Organization</td>
                            <td>Alfreds Futterkiste</td>
                            {/* <td>Cdat@gmail.com</td>
                            <td>7532154512</td> */}
                            <td>India</td>
                        </tr>

                        <tr>
                            <td>5</td>
                            <td>Namita Dilip Pawar</td>
                            <td>Organization</td>
                            <td>Alfreds Futterkiste</td>
                            {/* <td>Cdat@gmail.com</td>
                            <td>7532154512</td> */}
                            <td>India</td>
                        </tr>

                    </table>
                </div>

            </div>

            <div class="row">
                <div class="col-12 ">


                    <div class="offcanvas offcanvas-bottom rounded rounded-3 me-3 mb-3" tabindex="-1" id="offcanvasBottom"
                        aria-labelledby="offcanvasBottomLabel">
                        <div class="offcanvas-header lightgreenbg">
                            <h5 class="offcanvas-title" id="offcanvasBottomLabel">
                                <img src="assets/img/G_PlaNET_logo.png" alt="..." width="40px" /><span class="ps-3 text-white">Green
                                    Planet</span>
                            </h5>
                            <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas"
                                aria-label="Close"></button>
                        </div>
                        <div class="offcanvas-body small">
                            <div class="col-12 px-4 py-3">
                                <form>
                                    <div class="mb-3">
                                        <label for="exampleInputEmail1" class="form-label">Name:</label>
                                        <input type="email" class="form-control" id="exampleInputEmail1"
                                            aria-describedby="emailHelp" />

                                    </div>
                                    <div class="mb-3">
                                        <label for="exampleInputPassword1" class="form-label">Number:</label>
                                        <input type="password" class="form-control" id="exampleInputPassword1" />
                                    </div>
                                    <div class="mb-3">
                                        <label for="exampleInputEmail1" class="form-label">Email ID:</label>
                                        <input type="email" class="form-control" id="exampleInputEmail1"
                                            aria-describedby="emailHelp" />

                                    </div>
                                    <div class="mb-3">
                                        <label for="exampleInputPassword1" class="form-label">Query:</label>
                                        <textarea class="form-control" rows="5" placeholder="Leave a comment here"
                                            id="floatingTextarea"></textarea>

                                    </div>
                                    <a href="#" class="all_button">Submit</a>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row" id="offcanvas_button">
                <div class="col-12">
                    <button class="btn lightgreenbg back-to-top2" type="button" data-bs-toggle="offcanvas"
                        data-bs-target="#offcanvasBottom" aria-controls="offcanvasBottom"
                        style={{ borderRadius: '100px', padding: '10px 9px' }}
                    > <img src="assets/img/G_PlaNET_logo.png" alt="..." width="40px" /></button>
                </div>
            </div>

            <Footer />
        </div>
    )
}