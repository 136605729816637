import React from 'react'
import Header from '../../../../../Components/Header/Header'
import Footer from '../../../../../Components/Footer/Footer'

const NewsLetter = () => {
    return (
        <>
            <Header />
            <div className="row" id="herosection">
                <div className="col-12 bg_img d-flex  align-items-center">
                    <div className=" py-5 px-4 text-white">
                        <nav style={{ bsBreadcrumbdivider: '>' }} aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item active "><a href="#" className="text-white">Home</a></li><span
                                    className="mx-2">/</span>
                                <li className="breadcrumb-item active " aria-current="page"><a href="#"
                                    className="text-white">Multimedia</a></li><span className="mx-2">/</span>
                                <li className="breadcrumb-item active " aria-current="page"><a href="#"
                                    className="text-white">NewsLetter</a></li>
                            </ol>
                        </nav>
                    </div>

                </div>
            </div>
            <div className="row p-5">

                <div className="col-12">
                    {/* <!-- <h2 className="lightgreencolor pb-4">Newsletter</h2> --> */}
                    <h2 className="lightgreencolor">Newsletter
                        <span className="float-end">
                            <div className="search-box d-flex justify-content-center align-items-center">
                                <input className="search-input" type="text" name="" placeholder="Type Here" />
                                <a href="#" className="search-btn">
                                    <i className="fas fa-search fs-5"></i>
                                </a>
                            </div>
                        </span>
                    </h2>
                </div>
                <hr className=" mt-4 mb-4" />

                <div className="col-12 col-lg-3 p-2 p-lg-4">
                    <div className="boxs2 p-lg-3 card_background">
                        <article className="cards2 ">
                            <div className="card__corners2">
                                <div className="card__corner-triangles2"></div>
                            </div>
                            <p className=" border-bottom lightgreencolor fw-bold py-2 fs-5">Newsletter Name 1
                            </p>
                            <span className="gcp_about_ul py-3">
                                <li><img src="assets/img/pdf/Icon metro-file-pdf.png" alt="" width="150%" className="" />
                                </li>
                                <li className="ms-4"> <span className="text-dark text-size2">G-PlaNET New Delhi
                                    Leaders'
                                    Declaration</span></li>
                            </span>
                            {/* <!-- <hr> --> */}
                            <p className="pt-2 pb-3"><span className="text-size ">November 10, 2023</span>
                                <a href="https://www.g20.org/content/dam/gtwenty/gtwenty_new/document/Final_G20_FMCBG_October_2023_Communique.pdf"
                                    target="_blank"><span className="float-end text-size text-primary">Read More
                                        <i className="fas fa-angle-right"></i></span></a>
                            </p>
                        </article>
                    </div>
                </div>

                <div className="col-12 col-lg-3 p-2 p-lg-4">
                    <div className="boxs2 p-lg-3 card_background">
                        <article className="cards2 ">
                            <div className="card__corners2">
                                <div className="card__corner-triangles2"></div>
                            </div>
                            <p className=" border-bottom lightgreencolor fw-bold py-2 fs-5">Newsletter Name 1
                            </p>
                            <span className="gcp_about_ul py-3">
                                <li><img src="assets/img/pdf/Icon metro-file-pdf.png" alt="" width="150%" className="" />
                                </li>
                                <li className="ms-4"> <span className="text-dark text-size2">G-PlaNET New Delhi
                                    Leaders'
                                    Declaration</span></li>
                            </span>
                            {/* <!-- <hr> --> */}
                            <p className="pt-2 pb-3"><span className="text-size ">November 10, 2023</span>
                                <a href="https://www.g20.org/content/dam/gtwenty/gtwenty_new/document/Final_G20_FMCBG_October_2023_Communique.pdf"
                                    target="_blank"><span className="float-end text-size text-primary">Read More
                                        <i className="fas fa-angle-right"></i></span></a>
                            </p>
                        </article>
                    </div>
                </div>

                <div className="col-12 col-lg-3 p-2 p-lg-4">
                    <div className="boxs2 p-lg-3 card_background">
                        <article className="cards2 ">
                            <div className="card__corners2">
                                <div className="card__corner-triangles2"></div>
                            </div>
                            <p className=" border-bottom lightgreencolor fw-bold py-2 fs-5">Newsletter Name 1
                            </p>
                            <span className="gcp_about_ul py-3">
                                <li><img src="assets/img/pdf/Icon metro-file-pdf.png" alt="" width="150%" className="" />
                                </li>
                                <li className="ms-4"> <span className="text-dark text-size2">G-PlaNET New Delhi
                                    Leaders'
                                    Declaration</span></li>
                            </span>
                            {/* <!-- <hr> --> */}
                            <p className="pt-2 pb-3"><span className="text-size ">November 10, 2023</span>
                                <a href="https://www.g20.org/content/dam/gtwenty/gtwenty_new/document/Final_G20_FMCBG_October_2023_Communique.pdf"
                                    target="_blank"><span className="float-end text-size text-primary">Read More
                                        <i className="fas fa-angle-right"></i></span></a>
                            </p>
                        </article>
                    </div>
                </div>

                <div className="col-12 col-lg-3 p-2 p-lg-4">
                    <div className="boxs2 p-lg-3 card_background">
                        <article className="cards2 ">
                            <div className="card__corners2">
                                <div className="card__corner-triangles2"></div>
                            </div>
                            <p className=" border-bottom lightgreencolor fw-bold py-2 fs-5">Newsletter Name 1
                            </p>
                            <span className="gcp_about_ul py-3">
                                <li><img src="assets/img/pdf/Icon metro-file-pdf.png" alt="" width="150%" className="" />
                                </li>
                                <li className="ms-4"> <span className="text-dark text-size2">G-PlaNET New Delhi
                                    Leaders'
                                    Declaration</span></li>
                            </span>
                            {/* <!-- <hr> --> */}
                            <p className="pt-2 pb-3"><span className="text-size ">November 10, 2023</span>
                                <a href="https://www.g20.org/content/dam/gtwenty/gtwenty_new/document/Final_G20_FMCBG_October_2023_Communique.pdf"
                                    target="_blank"><span className="float-end text-size text-primary">Read More
                                        <i className="fas fa-angle-right"></i></span></a>
                            </p>
                        </article>
                    </div>
                </div>

                <div className="col-12 col-lg-3 p-2 p-lg-4">
                    <div className="boxs2 p-lg-3 card_background">
                        <article className="cards2 ">
                            <div className="card__corners2">
                                <div className="card__corner-triangles2"></div>
                            </div>
                            <p className=" border-bottom lightgreencolor fw-bold py-2 fs-5">Newsletter Name 1
                            </p>
                            <span className="gcp_about_ul py-3">
                                <li><img src="assets/img/pdf/Icon metro-file-pdf.png" alt="" width="150%" className="" />
                                </li>
                                <li className="ms-4"> <span className="text-dark text-size2">G-PlaNET New Delhi
                                    Leaders'
                                    Declaration</span></li>
                            </span>
                            {/* <!-- <hr> --> */}
                            <p className="pt-2 pb-3"><span className="text-size ">November 10, 2023</span>
                                <a href="https://www.g20.org/content/dam/gtwenty/gtwenty_new/document/Final_G20_FMCBG_October_2023_Communique.pdf"
                                    target="_blank"><span className="float-end text-size text-primary">Read More
                                        <i className="fas fa-angle-right"></i></span></a>
                            </p>
                        </article>
                    </div>
                </div>

                <div className="col-12 col-lg-3 p-2 p-lg-4">
                    <div className="boxs2 p-lg-3 card_background">
                        <article className="cards2 ">
                            <div className="card__corners2">
                                <div className="card__corner-triangles2"></div>
                            </div>
                            <p className=" border-bottom lightgreencolor fw-bold py-2 fs-5">Newsletter Name 1
                            </p>
                            <span className="gcp_about_ul py-3">
                                <li><img src="assets/img/pdf/Icon metro-file-pdf.png" alt="" width="150%" className="" />
                                </li>
                                <li className="ms-4"> <span className="text-dark text-size2">G-PlaNET New Delhi
                                    Leaders'
                                    Declaration</span></li>
                            </span>
                            {/* <!-- <hr> --> */}
                            <p className="pt-2 pb-3"><span className="text-size ">November 10, 2023</span>
                                <a href="https://www.g20.org/content/dam/gtwenty/gtwenty_new/document/Final_G20_FMCBG_October_2023_Communique.pdf"
                                    target="_blank"><span className="float-end text-size text-primary">Read More
                                        <i className="fas fa-angle-right"></i></span></a>
                            </p>
                        </article>
                    </div>
                </div>

                <div className="col-12 col-lg-3 p-2 p-lg-4">
                    <div className="boxs2 p-lg-3 card_background">
                        <article className="cards2 ">
                            <div className="card__corners2">
                                <div className="card__corner-triangles2"></div>
                            </div>
                            <p className=" border-bottom lightgreencolor fw-bold py-2 fs-5">Newsletter Name 1
                            </p>
                            <span className="gcp_about_ul py-3">
                                <li><img src="assets/img/pdf/Icon metro-file-pdf.png" alt="" width="150%" className="" />
                                </li>
                                <li className="ms-4"> <span className="text-dark text-size2">G-PlaNET New Delhi
                                    Leaders'
                                    Declaration</span></li>
                            </span>
                            {/* <!-- <hr> --> */}
                            <p className="pt-2 pb-3"><span className="text-size ">November 10, 2023</span>
                                <a href="https://www.g20.org/content/dam/gtwenty/gtwenty_new/document/Final_G20_FMCBG_October_2023_Communique.pdf"
                                    target="_blank"><span className="float-end text-size text-primary">Read More
                                        <i className="fas fa-angle-right"></i></span></a>
                            </p>
                        </article>
                    </div>
                </div>



            </div>
            <Footer />
        </>
    )
}

export default NewsLetter