
import { useEffect } from 'react'
import Select from 'react-select'
import { useDispatch, useSelector } from 'react-redux'
import { OrganizationTypeDDLAPI } from '../../../Redux/DDLSlice'
import { useAuthState } from '../../../Helper/Context'


export const OrganiztionTypeDDL = (props) => {
    const { organizationTypeDDL, setorganizationTypeDDL, apiFlag, rowData, Label, PartnerTypeID } = props

    const dispatch = useDispatch()
    const userDetails = useAuthState();
    const { UserID, token } = userDetails

    useEffect(() => {
        const data = { UserID, token, M_PartnerTypeID: PartnerTypeID }
        dispatch(OrganizationTypeDDLAPI({ data }))
    }, [PartnerTypeID])

    const { DDLData } = useSelector(state => state.OrganizationTypeDDLData)

    useEffect(() => {
        handleDDL()
    }, [DDLData])

    const handleDDL = () => {
        if (DDLData && DDLData.length > 0) {
            let list = DDLData.map((item, index) => ({
                value: item.m_SubPartnerTypeID,
                label: item.subPartnerTypeName,
            }))

            setorganizationTypeDDL({
                DDL: list,
                ID: apiFlag == 'Update' ? rowData?.m_SubPartnerTypeID : 0,
                Label: apiFlag == 'Update' ? rowData?.subPartnerTypeName : "Select...",

            })
        }
        else {
            setorganizationTypeDDL({
                DDL: [],
                ID: 0,
                Label: "Select...",
            })
        }
    }

    return (
        <div className="form-group">
            <label className="d-block" htmlFor="NameofDepartment"> {Label}</label>
            <Select
                isClearable
                // isRtl={isRtl}
                isSearchable
                maxMenuHeight={150}
                value={{ value: organizationTypeDDL.ID, label: organizationTypeDDL.Label }}
                onChange={(e) => {
                    e ?
                        setorganizationTypeDDL({ ...organizationTypeDDL, ID: e.value, Label: e.label })
                        :
                        setorganizationTypeDDL({ ...organizationTypeDDL, ID: 0, Label: "Select..." })

                }}
                options={organizationTypeDDL.DDL}
            />
        </div>
    )
}