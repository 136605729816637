
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { toastErrorr, toastSuccesss } from "../../../Helper/ToastMessage";
import { BaseUrl } from "../../../Helper/BaseURL";



// Get Table Data
export const ReceivedQueryGetTableDataAPI = createAsyncThunk("ReceivedQueryGetTableData", async ({ data }) => {
    const {
        M_QueryID,
        M_CountryNameID,
        Query,
        M_UsersID,
        To,
        token,
        From,
        setTotalCount
    } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    return fetch(`${BaseUrl}/Master/Get_M_Query_Select?M_QueryID=${M_QueryID ? M_QueryID : '0'}&M_CountryNameID=${M_CountryNameID ? M_CountryNameID : '0'}&Query=${Query ? Query : ''}&M_UsersID=${M_UsersID}&From=${From}&To=${To}`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                setTotalCount(result.data && result.data.table && result.data.table[0].totalCount)
                return result.data
            } else {
                setTotalCount(0)
                return result
            }
        })
})

const ReceivedQueryGetTableDataSlice = createSlice({
    name: " ReceivedQueryGetTableData",
    initialState: {
        isLoading: false,
        tableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(ReceivedQueryGetTableDataAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(ReceivedQueryGetTableDataAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.tableData = action.payload;
        });
        builder.addCase(ReceivedQueryGetTableDataAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.tableData = null;
            toastErrorr('Something went wrong')
        });
    },
})

export const ReceivedQueryGetTableDataReducer = ReceivedQueryGetTableDataSlice.reducer

// InsertUpdate Table Data

export const PartenerApprovalPostAPI = createAsyncThunk("PartenerApprovalPost", async ({ data }) => {
    const {
        m_PartnerRegistrationID,
        ActionID,
        M_UserID,
        token,
        Flag,
        handlePost,
        handleAddCloseClick,
        handleClear,
        apiFlag
    } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var formdata = new FormData();
    formdata.append("m_PartnerRegistrationID", m_PartnerRegistrationID);
    formdata.append("ActionID", ActionID);
    formdata.append("M_UserID", M_UserID);

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
    };

    fetch(`${BaseUrl}/Master/Post_Admin_M_PartnerRegistration_Action`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.status) {
                handlePost()
                handleAddCloseClick()
                // handleClear()
                toastSuccesss(result.message)
            } else {
                toastErrorr(result.message)
            }
            return result

        })
})

const PartenerApprovalPostSlice = createSlice({
    name: "PartenerApprovalPost",
    initialState: {
        isLoading: false,
        CropNametableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(PartenerApprovalPostAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(PartenerApprovalPostAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.CropNametableData = action.payload;
        });
        builder.addCase(PartenerApprovalPostAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.CropNametableData = null;
            // toastErrorr('Something went wrong')
        });
    },
})

export const PartenerApprovalPostReducer = PartenerApprovalPostSlice.reducer



// InsertUpdate Table Data

export const ReplyPostAPI = createAsyncThunk("ReplyPost", async ({ data }) => {
    const {
        M_QueryID,
        QueryReply,
        M_UserID,
        token,
        ActionFlag,
        setloading,
        handlePost,
        handleAddCloseClick
    } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);
    setloading(true)
    var formdata = new FormData();
    formdata.append("M_QueryID", M_QueryID);
    formdata.append("QueryReply", QueryReply);
    formdata.append("ActionFlag",ActionFlag);
    formdata.append("M_UserID", M_UserID);

    var requestOptions = {
        method: 'PATCH',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
    };

    fetch(`${BaseUrl}/Master/M_Query_Action`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.status) {
                handlePost()
                handleAddCloseClick()
                // handleClear()
                toastSuccesss(result.message)
            } else {
                toastErrorr(result.message)
            }
            setloading(false)
            return result

        })
})

const ReplyPostSlice = createSlice({
    name: "ReplyPost",
    initialState: {
        isLoading: false,
        CropNametableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(ReplyPostAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(ReplyPostAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.CropNametableData = action.payload;
        });
        builder.addCase(ReplyPostAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.CropNametableData = null;
            // toastErrorr('Something went wrong')
        });
    },
})

export const ReplyPostReducer = ReplyPostSlice.reducer



