import { useState } from "react"
import { ForgotPassword } from "../ForgotPassword"
import { OTPScreen } from "../OTPScreen"
import { NewPassword } from "../NewPassword"
import { ForgotPasswordAPI } from "../../../../../Redux/ForgotPasswordSlice/ForgotPasswordSlice"
import { useDispatch, useSelector } from "react-redux"



export const ForgotPassScreen = () => {
    const dispatch = useDispatch()
    const [NextScreen, setNextScreen] = useState('Forgot')
    const [ScreenMsg, setScreenMsg] = useState('')

    const [FormData, setFormData] = useState({
        Email: '',
        OTP: ''
    })

    const handleNext = (value,Msg) => {
        setNextScreen(value)
        setScreenMsg(Msg)
    }

    const handleResendOTP = () => {
        setScreenMsg('')
        const data = {
            Email: FormData.Email,
            setMessage:setScreenMsg,
            handleNext:handleNext
        }
        dispatch(ForgotPasswordAPI({ data }))
    }

    const { isLoading, Data } = useSelector(state => state.ForgotPasswordData)

    return (
        <>
            {
                NextScreen == 'Forgot' ?
                    <ForgotPassword
                        handleNext={handleNext}
                        FormData={FormData}
                        setFormData={setFormData}
                    />
                    :
                    NextScreen == 'OTP' ?
                        <OTPScreen
                            handleNext={handleNext}
                            FormData={FormData}
                            setFormData={setFormData}
                            ScreenMsg={ScreenMsg}
                            handleResendOTP={handleResendOTP}
                            isFLoading={isLoading}
                        />
                        :
                        <NewPassword FormData={FormData} />
            }
        </>
    )
}