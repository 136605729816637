
import { LazyLoadImage } from "react-lazy-load-image-component"
import Footer from "../../../Components/Footer/Footer"
import Header from "../../../Components/Header/Header"
import { PagesHeroSection } from "../../../Components/PagesHeroSection/PagesHeroSection"

export const CommingSoon = () => {
    return (
        <div class="container-fluid g-0">
            <Header />

            {/* <PagesHeroSection
                Heading='FAQ'
                BreadScrum1='Home'
                BreadScrum2='Resource'
            /> */}

            {/* <div class="row" id="herosection">
                <div class="col-12 bg_img d-flex  align-items-center">
                    <div class=" py-5 px-4 text-white">
                        <nav style={{ bsBreadcrumbdivider: '>' }} aria-label="breadcrumb">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item active "><a href="#" class="text-white">Home</a></li><span
                                    class="mx-2"></span>
                                <li class="breadcrumb-item active " aria-current="page"><a href="#"
                                    class="text-white">Join the Pledge</a></li><span class="mx-2">/</span>
                                <li class="breadcrumb-item active " aria-current="page"><a href="#"
                                    class="text-white">Participants</a></li>
                            </ol>
                        </nav>
                    </div>

                </div>
            </div> */}

            <div class="row" >
            {/* id="privacy_policy" */}
                <div class="col-12 text-center ">
                    <h2 class="lightgreencolor mt-5">
                        {/* Helpdesk */}
                    </h2>
                </div>
                <div class="col-12 col-md-5 mx-auto">
                    <LazyLoadImage effect="blur" src="https://ik.imagekit.io/gplanet/Gplanet/img/coming/coming_soon.png?updatedAt=1701510163736" alt="Comming Soon" style={{ width: '90%' }} />
                </div>

            </div>

            {/* <div class="row">
                <div class="col-12 ">


                    <div class="offcanvas offcanvas-bottom rounded rounded-3 me-3 mb-3" tabindex="-1" id="offcanvasBottom"
                        aria-labelledby="offcanvasBottomLabel">
                        <div class="offcanvas-header lightgreenbg">
                            <h5 class="offcanvas-title" id="offcanvasBottomLabel">
                                <img src="assets/img/G_PlaNET_logo.png" alt="..." width="40px" /><span class="ps-3 text-white">Green
                                    Planet</span>
                            </h5>
                            <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas"
                                aria-label="Close"></button>
                        </div>
                        <div class="offcanvas-body small">
                            <div class="col-12 px-4 py-3">
                                <form>
                                    <div class="mb-3">
                                        <label for="exampleInputEmail1" class="form-label">Name:</label>
                                        <input type="email" class="form-control" id="exampleInputEmail1"
                                            aria-describedby="emailHelp" />

                                    </div>
                                    <div class="mb-3">
                                        <label for="exampleInputPassword1" class="form-label">Number:</label>
                                        <input type="password" class="form-control" id="exampleInputPassword1" />
                                    </div>
                                    <div class="mb-3">
                                        <label for="exampleInputEmail1" class="form-label">Email ID:</label>
                                        <input type="email" class="form-control" id="exampleInputEmail1"
                                            aria-describedby="emailHelp" />

                                    </div>
                                    <div class="mb-3">
                                        <label for="exampleInputPassword1" class="form-label">Query:</label>
                                        <textarea class="form-control" rows="5" placeholder="Leave a comment here"
                                            id="floatingTextarea"></textarea>

                                    </div>
                                    <a href="#" class="all_button">Submit</a>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}

            {/* <div class="row" id="offcanvas_button">
                <div class="col-12">
                    <button class="btn lightgreenbg back-to-top2" type="button" data-bs-toggle="offcanvas"
                        data-bs-target="#offcanvasBottom" aria-controls="offcanvasBottom"
                        style={{ borderRadius: '100px', padding: '10px 9px' }}
                    > <img src="assets/img/G_PlaNET_logo.png" alt="..." width="40px" /></button>
                </div>
            </div> */}

            <Footer />
        </div>
    )
}