import React from 'react'
import Header from '../../../../../Components/Header/Header'
import Footer from '../../../../../Components/Footer/Footer'

const GCP = () => {
    return (
        <div>
            <Header />
            <div className="row" id="herosection">
                <div className="col-12 bg_img1 d-flex  align-items-center">
                    <div className=" py-5 px-4 text-white">
                        <nav style={{ bsBreadcrumbdivider: '>' }} aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item active "><a href="#" className="text-dark">Home</a></li><span
                                    className="mx-2 text-dark">/</span>
                                <li className="breadcrumb-item active " aria-current="page"><a href="#"
                                    className="text-dark">About</a></li><span className="mx-2 text-dark ">/</span>
                                <li className="breadcrumb-item active " aria-current="page"><a href="#"
                                    className="lightgreencolor fw-bold">GCP</a></li>
                            </ol>
                        </nav>
                    </div>

                </div>
            </div>
            <div className="row p-4 p-md-5  gcp_bg" id="about_life">
                <div className="col-12 ">
                    <h3 className=" text-center mt-3 lightgreencolor">
                        About GCP
                    </h3>
                    <h6 className="text-center my-3">The Green Credits Programme (GCP)</h6>

                    <span className="gcp_about_ul">
                        <li className="px-2 fs-5 mobile_none"> <i className="ri-check-double-line lightgreencolor"></i></li>
                        <li className="text-dark justify">
                            The Green Credit Programme (GCP), a first of its kind, across the world, national-level endeavour
                            positioned to promote environmental activity, is an important part of LiFE. It is market-based
                            mechanism to incentivize voluntary environmental actions by stakeholders like individuals,
                            communities, private sector industries, community organisations, Forest produce organisations, local
                            bodies, Self Help Groups, companies etc. Green Credits will arise from projects undertaken in
                            different areas like tree plantation, water conservation, waste management, activities to control
                            air pollution, mangrove plantation, undertaking agriculture friendly practices etc. Green Credits
                            will be tradable outcomes and will act as incentives which will be made available for trading on a
                            domestic market platform.
                        </li>
                    </span>

                    <span className="gcp_about_ul mt-3">
                        <li className="px-2 fs-5 mobile_none"> <i className="ri-check-double-line lightgreencolor"></i></li>
                        <li className="text-dark justify">
                            GCP’s comprehensive digital infrastructure consists of three key components: a user-friendly web
                            portal/app, a robust registry, and an efficient trading platform. The web portal/app has been
                            designed as a user friendly interface for housing essential data elements pertaining to the
                            issuance, ownership, transfer, and acquisition of Green Credits (GCs). In parallel, the registry
                            maintains a comprehensive record of all entities within the GCP ecosystem that are eligible for GC
                            issuance or grants. To facilitate seamless transactions, the trading platform provides a marketplace
                            for buyers and sellers to engage in GC trading.
                        </li>
                    </span>

                </div>
            </div>
            {/* <!--end of row about life--> */}

            <div className="row p-4 p-md-5" id="Objectives">
                <div className="col-12 mx-auto">
                    <h2 className="lightgreencolor text-center">Objectives</h2>
                    <h6 className="text-center my-3">The main objectives of the Green Credit Programme are as follows</h6>

                    <span className="gcp_about_ul py-2">
                        <li className="px-2 fs-5 mobile_none"> <i className="ri-check-double-line lightgreencolor"></i></li>
                        <li className="text-dark justify">
                            Create a market-based mechanism for providing incentives in the form of Green Credits to
                            individuals, Farmer Producer Organisations, cooperatives, forestry enterprises, sustainable
                            agriculture enterprises, Urban and Rural Local Bodies, private sectors, industries and
                            organisations for environment positive actions.
                        </li>
                    </span>

                    <span className="gcp_about_ul py-2">
                        <li className="px-2 fs-5 mobile_none"> <i className="ri-check-double-line lightgreencolor"></i></li>
                        <li className="text-dark justify">
                            Create mass movement around environment positive actions and realise the vision of "Mission
                            LiFE" through pro-planet-people and entities.
                        </li>
                    </span>


                </div>
            </div>
            {/* <!--end of row Objectives--> */}

            <div className="row px-4 py-2 px-md-5 py-md-3 d-flex align-items-center justify-content-center green_credit_bg" id="Green_Credits">


                <div className="col-12  col-md-6 credit_img" >
                    <img src="assets/img/green_credit/Green Credits.png" alt="" style={{ width: '100%' }} />
                </div>
                <div className="col-12 col-md-6">
                    <h2 className="lightgreencolor">Green Credits</h2>
                    <p>
                        Green Credit means a singular unit of an incentive provided for a speciﬁed activity, delivering a
                        positive impact on the environment.
                    </p>
                </div>
            </div>
            {/* <!--end of row Green Credits--> */}

            {/* <!-- Types of GC’s start --> */}
            {/* <div className="row p-4 p-md-5 gc_typesimg">
                <h3 className="dark-blue">Types of GC’s </h3>
                <p className="text-dark">The following activities are currently being undertaken for Green Credit Programme
                    implementation:</p>

                <div className="col-12 col-lg-12 mx-auto py-4 my-0 shadow section_design mt-4">
                    <div className="row text-center text-md-start">
                        <div className="col-12 col-md-3 col-lg-2 d-block align-items-center justify-content-center ">
                            <img src="assets/img/gcp/Tree Plantation-based Green Credit1.png" alt="" />
                        </div>
                        <div className="col-12 col-md-9 col-lg-10 pt-4 ">
                            <h5>Tree Plantation-based Green Credit</h5>
                            <p className="text-dark pt-2">To promote activities for increasing the green cover across the country
                                through tree plantation and related activities.</p>
                        </div>
                    </div>
                </div>

                <div className="col-12 col-lg-12 mx-auto py-4 my-0 shadow section_design mt-4">
                    <div className="row text-center text-md-start">
                        <div className="col-12 col-md-3 col-lg-2 d-block align-items-center justify-content-center">
                            <img src="assets/img/gcp/Water-based Green Credit2.png" alt="" />
                        </div>
                        <div className="col-12 col-md-9 col-lg-10 pt-4 ">
                            <h5> Water-based Green Credit</h5>
                            <p className="text-dark pt-2">To promote water conservation, water harvesting and water use
                                eﬃciency/savings, including treatment and reuse of wastewater.
                            </p>
                        </div>
                    </div>
                </div>

                <div className="col-12 col-lg-12 mx-auto py-4 my-0 shadow section_design mt-4">
                    <div className="row text-center text-md-start">
                        <div className="col-12 col-md-3 col-lg-2 d-block align-items-center justify-content-center">
                            <img src="assets/img/gcp/SustainableAgriculturebasedGreenCredit3.png" alt="" />
                        </div>
                        <div className="col-12 col-md-9 col-lg-10 pt-4 ">
                            <h5> Sustainable Agriculture based Green Credit</h5>
                            <p className="text-dark pt-2">To promote natural and regenerative agricultural practices and land
                                restoration to improve productivity, soil health and nutritional value of food produced.</p>
                        </div>
                    </div>
                </div>

                <div className="col-12 col-lg-12 mx-auto py-4 my-0 shadow section_design mt-4">
                    <div className="row text-center text-md-start">
                        <div className="col-12 col-md-3 col-lg-2 d-block align-items-center justify-content-center">
                            <img src="assets/img/gcp/Waste Management based Green Credit4.png" alt="" />
                        </div>
                        <div className="col-12 col-md-9 col-lg-10 pt-4 ">
                            <h5>Waste Management based Green Credit</h5>
                            <p className="text-dark pt-2">To promote circularity, sustainable and improved practices for waste
                                management, including collection, segregation, and treatment.</p>
                        </div>
                    </div>
                </div>

                <div className="col-12 col-lg-12 mx-auto py-4 my-0 shadow section_design mt-4">
                    <div className="row text-center text-md-start">
                        <div className="col-12 col-md-3 col-lg-2 d-block align-items-center justify-content-center">
                            <img src="assets/img/gcp/AirPollutionReductionbasedGreenCredit5.png" alt="" />
                        </div>
                        <div className="col-12 col-md-9 col-lg-10 pt-4 ">
                            <h5>Air Pollution Reduction based Green Credit</h5>
                            <p className="text-dark pt-2">To promote measures for reducing air pollution and other pollution
                                abatement activities.</p>
                        </div>
                    </div>
                </div>

                <div className="col-12 col-lg-12 mx-auto py-4 my-0 shadow section_design mt-4">
                    <div className="row text-center text-md-start">
                        <div className="col-12 col-md-3 col-lg-2 d-block align-items-center justify-content-center">
                            <img src="assets/img/gcp/MangroveConservationRestorationBasedGreenCredit6.png" alt="" />
                        </div>
                        <div className="col-12 col-md-9 col-lg-10 pt-4 ">
                            <h5>Mangrove Conservation and Restoration based Green Credit</h5>
                            <p className="text-dark pt-2">To promote measures for conservation and restoration of mangroves.</p>
                        </div>
                    </div>
                </div>

                <div className="col-12 col-lg-12 mx-auto py-4 my-0 shadow section_design mt-4">
                    <div className="row text-center text-md-start">
                        <div className="col-12 col-md-3 col-lg-2 d-block align-items-center justify-content-center">
                            <img src="assets/img/gcp/EcomarkBasedGreenCredit7.png" alt="" />
                        </div>
                        <div className="col-12 col-md-9 col-lg-10 pt-4 ">
                            <h5>Ecomark based Green Credit</h5>
                            <p className="text-dark pt-2">To encourage manufacturers to obtain Ecomark label for their goods and
                                services.</p>
                        </div>
                    </div>
                </div>

                <div className="col-12 col-lg-12 mx-auto py-4 my-0 shadow section_design mt-4">
                    <div className="row text-center text-md-start">
                        <div className="col-12 col-md-3 col-lg-2 d-block align-items-center justify-content-center">
                            <img src="assets/img/gcp/SustainableBuildingAndInfrastructurebasedGreenCredit8.png" alt="" />
                        </div>
                        <div className="col-12 col-md-9 col-lg-10 pt-4 ">
                            <h5>Sustainable building and infrastructure based Green Credit</h5>
                            <p className="text-dark pt-2">To encourage the construction of buildings and other infrastructure using
                                sustainable technologies and materials.</p>
                        </div>
                    </div>
                </div>
            </div> */}
            {/* <!-- end Types of GC’s--> */}
            <Footer />
        </div>
    )
}

export default GCP