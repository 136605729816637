import React, { useEffect, useState } from 'react'
import AdminHeader from '../../../../../Components/Header/AdminHeader'
import Sidebar from '../../../../../Components/Sidebar/Sidebar'
import { EventNameDDL } from '../../../../../Components/DDL/EventNameDDL/EventNameDDL'
import { PartnerNameDDL } from '../../../../../Components/DDL/PartnerNameDDL/PartnerNameDDL'
import { PublishNewsAdmin } from '../NewsAdmin/PublishNewsAdmin'
import { DeletePartenerPopup } from './DeletePopup'
import FAB from '../../../../../Components/FAB/FAB'
import { AddEventGallery } from './AddEventGallery'
import { PublishReportPopUp } from '../KnowledgeRepositoryReport/Action/PublishReportPopUp'
import { CommonDeletePopUp } from '../KnowledgeRepositoryReport/Action/CommonDeletePopUp'
import { useDispatch, useSelector } from 'react-redux'
import { useAuthState } from '../../../../../Helper/Context'
import { EventGallaryDeleteAPI, EventTableDataAPI, PublishEventAPI } from '../../../../../Redux/AdminSlice/EventSlice/EventSlice'
import { Loading } from '../../../../../Helper/Loading'
import { EventGalleryTableDataAPI } from '../../../../../Redux/AdminSlice/EventGallarySlice/EventGallarySlice'
import { PhotoPopUp } from './PhotoPopUp'
import { LogoLoader } from '../../../../../Helper/LogoLoader'


export const EventGallery = () => {

    const dispatch = useDispatch()
    const userDetails = useAuthState()
    const { RoleID, UserID, token } = userDetails
    const [IsPost, setIsPost] = useState(false)

    const [showAddPopUp, setshowAddPopUp] = useState(false)
    const [showPhotoPopUp, setshowPhotoPopUp] = useState(false)
    const [editData, setEditData] = useState('')
    const [Title, setTitle] = useState('')
    // const [showPublishPopUp, setshowPublishPopUp] = useState(false)
    // const [showDeletePartnerPopUp, setshowDeletePartnerPopUp] = useState(false)
    const [PublishPopUp, setPublishPopUp] = useState(false)
    const [DeletePopUp, setDeletePopUp] = useState(false)

    const [Heading, setHeading] = useState('')
    const [EventDate, setEventDate] = useState('')
    const [TotalCount, setTotalCount] = useState(0)
    const [IsDeleteLoading, setIsDeleteLoading] = useState(false)
    const [loading, setloading] = useState(false)
    const [PhotoVideoID, setPhotoVideoID] = useState({
        ID: '',
        Flag: ''
    })

    const [partnerNameDDL, setpartnerNameDDL] = useState({
        DDL: [],
        ID: 0,
        Label: 'Select...'
    })
    const [eventNameDDL, seteventNameDDL] = useState({
        DDL: [],
        ID: 0,
        Label: 'Select...'
    })

    const [partnerTypeDDL, setpartnerTypeDDL] = useState({
        DDL: [],
        ID: 0,
        Label: "Select...",
    })



    // const handleDeletePartenerPopup = () => {
    //     console.log("first")
    //     setshowDeletePartnerPopUp(!showDeletePartnerPopUp)
    // }

    // const handlePublishPopup = () => {
    //     // console.log("first")
    //     setshowPublishPopUp(!showPublishPopUp)
    // }

    const PublishButtonClick = (heading, id) => {
        setPublishPopUp(true)
        setEditData(id)
        // setActionId(id)
        setHeading(heading)
    }

    const handleDeletePopUp = (ID, Flag) => {
        setDeletePopUp(true)
        setPhotoVideoID({
            ID: ID,
            Flag: Flag
        })
    }
    const handlePublishCloseClick = () => {
        setPublishPopUp(false)
        setDeletePopUp(false)
    }

    const handleOnAddClick = (item) => {
        setEditData(item)
        setshowAddPopUp(!showAddPopUp)
    }

    const handleOnPhotoClick = (item, title) => {
        setEditData(item)
        setTitle(title)
        setshowPhotoPopUp(!showPhotoPopUp)
    }

    const handleIsPost = () => {
        setIsPost(!IsPost)
    }

    const handleClear = () => {
        setpartnerNameDDL({
            ...partnerNameDDL,
            ID: 0,
            Label: 'Select...'
        })
        seteventNameDDL({
            ...eventNameDDL,
            ID: 0,
            Label: 'Select...'
        })
        setEventDate('')
    }

    useEffect(() => {
        const data = {
            M_EventID: eventNameDDL.ID,
            M_EventTypeID: '0',
            EventDate: EventDate,
            Flag: RoleID == 1 ? 'Admin' : 'Partner',
            M_UserID: UserID,
            token: token,
            TOP: '99999',
            setTotalCount: setTotalCount
        }
        dispatch(EventTableDataAPI({ data }))
    }, [eventNameDDL.ID, EventDate, IsPost])

    const { tableData, isLoading } = useSelector(state => state.EventTableData)

    const handleDelete = () => {
        const data = {
            M_Event_Wise_PhotoID: PhotoVideoID.ID,
            Flag: PhotoVideoID.Flag,
            M_UserID: UserID,
            token: token,
            handleIsPost: handleIsPost,
            handleAddCloseClick: handlePublishCloseClick,
            setIsDeleteLoading: setloading
        }
        dispatch(EventGallaryDeleteAPI({ data }))
    }
    const { isDeleteLoading } = useSelector(state => state.EventMasterDeleteData)

    const handlePublish = () => {
        const data = {
            M_EventID: editData,
            ActionID: 4,
            M_UserID: UserID,
            token: token,
            handleIsPost: handleIsPost,
            handleAddCloseClick: handlePublishCloseClick,
            setloading: setloading
        }
        dispatch(PublishEventAPI({ data }))
    }


    return (
        <>
            {isLoading && <LogoLoader />}
            <AdminHeader />
            {/* <Sidebar active="dashboard" /> */}
            <div class="row flex-nowrap gr wrapper">
                <Sidebar active='EventGallery' />
                <div class="col py-3 text-dark" id='content'>
                    <div class="page-content">
                        <span className='d-flex justify-content-between align-items-center mx-3'>
                            <h5 class=" fw-bold my-3 "> Event Gallery
                                {/* <!-- <span class="float-end"><a href="#n_popup_login"
                                class="add_btn  mx-1 green_color py-0">Add</a></span> --> */}
                            </h5>
                            {/* <button className='add-btn' onClick={() => navigate()}>Add</button> */}

                        </span>
                        <div class="row py-4 card p-2">
                            <div class="col-12 ">

                                <div class="col bg-light">
                                    <div class="row grayish py-3 d-flex align-items-end">

                                        <div class="col-12 col-md-6 col-lg-3">
                                            <EventNameDDL
                                                eventNameDDL={eventNameDDL}
                                                seteventNameDDL={seteventNameDDL}
                                            />
                                        </div>

                                        {/* <div class="col-12 col-md-6 col-lg-3">
                                            <PartnerNameDDL
                                                partnerNameDDL={partnerNameDDL}
                                                setpartnerNameDDL={setpartnerNameDDL}
                                            />
                                        </div> */}

                                        <div className="col-12 col-md-6 col-lg-3">
                                            <label for="exampleInputEmail1" className="form-label">Event Date</label>
                                            <input type="date" className="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp"
                                                value={EventDate}
                                                onChange={(e) => setEventDate(e.target.value)}
                                            />
                                        </div>

                                        <div className="col-12 col-md-6 col-lg-3">
                                            <button type="button" className="clear_button"
                                                onClick={handleClear}
                                            ><i class="fa-solid fa-xmark pe-1"></i> Clear</button>
                                        </div>

                                        <div className="col-12 col-md-6 col-lg-2">
                                            <span>Total Records : <b> {TotalCount}</b></span>
                                        </div>

                                    </div>
                                </div>

                                <div class="col-12 pb-5" style={{ overflowX: 'scroll', width: '100%' }}>
                                    <table>
                                        <thead>
                                            <tr class="header lightgreenbg whitecolor">
                                                <th className='text-center'>Sr. No.</th>
                                                <th className='text-center'>Event Name</th>
                                                {/* <th>Partner Name</th> */}
                                                <th className='text-center'>Photo</th>
                                                <th className='text-center'>Video</th>
                                                <th className='text-center'>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                tableData && tableData.table && tableData.table.length > 0 ? tableData.table.map((item, i) => {
                                                    return (
                                                        <tr>
                                                            <td className='text-center' width='6%'>{item.rowNum ? item.rowNum : '-'}</td>
                                                            <td>{item.eventName ? item.eventName : '-'}</td>
                                                            {/* <td>test</td> */}
                                                            <td className='text-center'>
                                                                <span className='px-3' style={{ cursor: 'pointer' }}>
                                                                    <i className="fa-solid fa-image fs-6" title='Photo'
                                                                        onClick={() => handleOnPhotoClick(item, 'Photo')}
                                                                    ></i>
                                                                </span>
                                                            </td>
                                                            <td className='text-center'>
                                                                <span className='px-3' style={{ cursor: 'pointer' }}>
                                                                    <i className="fa-solid fa-video fs-6" title='Video'
                                                                        onClick={() => handleOnPhotoClick(item, 'Video')}
                                                                    ></i>
                                                                </span>
                                                            </td>
                                                            <td className='text-center'>
                                                                <span className='' style={{ cursor: 'pointer' }}>
                                                                    <i className="fa-solid fa-circle-plus fs-6 lightgreencolor" title='Add'
                                                                        onClick={() => handleOnAddClick(item)}
                                                                    ></i>
                                                                </span>

                                                                {
                                                                    RoleID == '2' ?
                                                                        <></>
                                                                        :
                                                                        item.statusID == '5' ?
                                                                            <span className='px-3' style={{ cursor: 'pointer' }}>
                                                                                <i className="fa-solid fa-arrow-up-from-bracket fs-6"
                                                                                    onClick={() => PublishButtonClick('Publish', item.m_EventID)}
                                                                                    title='Publish'></i>
                                                                            </span>
                                                                            :
                                                                            ""
                                                                }

                                                                {/* <span style={{ cursor: 'pointer' }}>
                                                                    <i className="fa-solid fa-trash-can fs-6"
                                                                        onClick={() => handleDeletePopUp()}
                                                                        title='Delete' style={{ color: 'red' }}></i>
                                                                </span> */}
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                                    : <>No Data</>
                                            }

                                        </tbody>
                                    </table>

                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            {/* <FAB toggleState={showAddPopUp} settoggleState={handleOnAddClick} /> */}

            {
                showPhotoPopUp &&
                <PhotoPopUp IsPost={IsPost} handleOnPhotoClick={handleOnPhotoClick} handleDeletePopUp={handleDeletePopUp} EventID={editData.m_EventID} Title={Title} />
            }

            {
                showAddPopUp &&
                <AddEventGallery handleOnAddClick={handleOnAddClick} editData={editData} handleIsPost={handleIsPost} />
            }

            {
                PublishPopUp && <PublishReportPopUp
                    open={PublishPopUp}
                    heading={Heading}
                    Lable='Publish'
                    handleAddCloseClick={handlePublishCloseClick}
                    handlePublish={handlePublish}
                    loading={loading}
                />
            }
            {
                DeletePopUp && <CommonDeletePopUp
                    open={DeletePopUp}
                    heading=''
                    Lable={Title}
                    handleAddCloseClick={handlePublishCloseClick}
                    handleDelete={handleDelete}
                    IsDeleteLoading={loading}
                />
            }

        </>
    )
}
