
export const CommonDeletePopUp = (props) => {
    const { handleAddCloseClick, heading, handleDelete, Lable, IsDeleteLoading } = props

    return (
        <div className="modal show fade px-lg-5" id="exampleModal"
            style={{ display: "block", backgroundColor: "#00000055", overflow: 'auto' }}
            tabindex="-1" aria-labelledby="exampleModalLabel"
            aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" style={{ maxWidth: '40%' }}>
                <div class="modal-content" style={{ minHeight: '250px' }}>
                    <div class="modal-header px-lg-5 bg-white">
                        <h5 class="modal-title lightgreencolor" id="exampleModalLabel">Delete Pop Up
                        </h5>
                        <button type="button" class=" bg-danger text-white px-2 border-0 rounded fs-6"
                            onClick={handleAddCloseClick}> &times; </button>
                    </div>
                    <div class="modal-body px-5" style={{ height: '50px', overflowY: 'scroll' }}>
                        <form>
                            <div className='row'>
                                <div className="d-flex justify-content-center mt-4 "> Do you want to Delete {Lable}? </div>
                            </div>

                            <div className="col-12 col-lg-12">

                                <div className="btn-action d-flex justify-content-center mt-4 " >
                                    <button
                                        disabled={IsDeleteLoading}
                                        type="button"
                                        className="btn btn-success mx-2"
                                        style={{ width: '77px' }}
                                        onClick={() => handleDelete()}
                                    >
                                        {IsDeleteLoading && (
                                            <i
                                                className="fa fa-spinner fa-spin"
                                                style={{ marginRight: "5px" }}
                                            />
                                        )}
                                        {!IsDeleteLoading && <span> <i class="fa-solid fa-check pe-1"></i> Yes</span>}
                                    </button>
                                    <button type="button"
                                        className="btn btn-danger"
                                        style={{ width: '77px' }}
                                        onClick={handleAddCloseClick}
                                    ><i class="fa-solid fa-xmark pe-1"></i> No</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}