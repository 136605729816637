import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { PledgeRegistration } from '../../Pages/PopUp/PledgeRegistration'
import { LazyLoadImage } from 'react-lazy-load-image-component'

const Footer = () => {
  const navigate = useNavigate()

  const [showPledge, setshowPledge] = useState(false)

  const handleShowPledge = () => {
    setshowPledge(!showPledge)
  }

  return (
    <div className="container-fluid footer px-5 fadeIn lightgreenbg" data-wow-delay=".3s">
      <div className="  pt-5 pb-4">
        <div className="row g-5">
          <div className=" col--12 col-lg-3 col-md-6 text-center">
            <div className='cursor-pointer' onClick={() => window.open('https://moef.gov.in/moef/')}>
              <LazyLoadImage effect='blur' src="https://ik.imagekit.io/gplanet/Gplanet/img/footer_logo-01.png?updatedAt=1701510153108" alt="" style={{ width: '80px' }} />
              <h3 className="text-white my-3">MoEF&CC</h3>
            </div>
            {/* <!-- <p className="mt-4 text-light">Lorem ipsum dolor sit amet consectetur adipisicing elit. Soluta
                facere
                delectus qui placeat inventore consectetur repellendus optio debitis.</p> --> */}
            <div className="d-flex hightech-link align-items-center justify-content-center">

              <a href="https://x.com/globalgreen_?t=BY3Sf1RJDE049qRjYmFXdQ&s=08" target="_blank"
                className="btn-light nav-fill btn btn-square rounded-circle me-2"><i
                  className="fa-brands fa-x-twitter"></i></a>


              <a href="https://www.facebook.com/people/Global-Green-Credit-Initiative/61553955896667/?mibextid=ZbWKwL" target="_blank"
                className="btn-light nav-fill btn btn-square rounded-circle me-2"><i
                  className="fab fa-facebook-f"></i></a>


              {/* <a href="https://www.instagram.com/moefccgoi/" target="_blank"
                className="btn-light nav-fill btn btn-square rounded-circle me-2"><i
                  className="fab fa-instagram"></i></a> */}


              <a href="https://www.linkedin.com/company/101249694/admin/feed/posts/?feedType=following" target="_blank"
                className="btn-light nav-fill btn btn-square rounded-circle me-2"><i
                  className="fab fa-linkedin-in"></i></a>

              {/* <a href="https://www.youtube.com/channel/UCCl94zd6YfuUrv9DJz_5RcA" target="_blank"
                className="btn-light nav-fill btn btn-square rounded-circle me-2"><i
                  className="fa-brands fa-youtube"></i></a> */}




              {/* <a href="https://www.kooapp.com/profile/Moefcc" target="_blank"
                className="btn-light nav-fill btn btn-square rounded-circle me-0"><svg
                  xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="30px" height="30px"
                  viewBox="0,0,256,256" style={{ fill: '#000000' }}>
                  <g fill="none" fill-rule="nonzero" stroke="none" stroke-width="1"
                    stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10"
                    stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none"
                    font-size="none" text-anchor="none" style={{ mixBlendMode: 'normal' }}>
                    <g transform="scale(5.33333,5.33333)">
                      <path
                        d="M27.103,5.312c-0.06,-0.681 -0.408,-3.24 -2.294,-3.595c-0.452,-0.085 -1.279,0.421 0.587,1.767c-0.204,-0.152 -1.836,-1.323 -2.187,-0.126c-0.086,0.292 -0.28,0.84 1.559,1.349c0,0 -1.565,-0.505 -1.639,0.417c0.495,-0.073 0.995,-0.124 1.507,-0.124c0.846,0 1.669,0.115 2.467,0.312z"
                        fill="#000000"></path>
                      <path
                        d="M35.457,19.694c-0.239,-0.795 -0.8,-4.07 -0.8,-4.07c-0.849,-5.47 -4.971,-9.624 -9.94,-9.624c-5.582,0 -10.107,5.24 -10.107,11.704c0,0.44 0.028,0.872 0.07,1.299c0.061,0.006 0.123,0.021 0.181,0.052c2.33,1.185 4.481,5.144 3.577,7.904c-0.485,1.479 -1.908,3.047 -5.759,3.047c-0.809,0 -1.733,-0.074 -2.771,-0.228c-2.176,0.229 -1.682,1.758 -1.682,1.758c1.621,4.864 7.556,6.636 10.295,7.206l1.419,4.258h-1.306c-0.276,0 -0.5,0.224 -0.5,0.5c0,0.276 0.224,0.5 0.5,0.5h1.793l1.854,1.854c0.097,0.097 0.225,0.146 0.353,0.146c0.128,0 0.256,-0.049 0.354,-0.146c0.195,-0.195 0.195,-0.512 0,-0.707l-1.147,-1.147h1.793c0.276,0 0.5,-0.224 0.5,-0.5c0,-0.276 -0.224,-0.5 -0.5,-0.5h-2.64l-1.352,-4.056c0.171,0.025 0.288,0.04 0.288,0.04c2.309,0.385 4.295,0.342 6.015,0.03l0.995,2.986h-1.306c-0.276,0 -0.5,0.224 -0.5,0.5c0,0.276 0.224,0.5 0.5,0.5h1.793l1.854,1.854c0.097,0.097 0.225,0.146 0.353,0.146c0.128,0 0.256,-0.049 0.354,-0.146c0.195,-0.195 0.195,-0.512 0,-0.707l-1.147,-1.147h1.793c0.276,0 0.5,-0.224 0.5,-0.5c0,-0.276 -0.224,-0.5 -0.5,-0.5h-2.64l-1.066,-3.198c3.968,-1.023 6.322,-3.562 7.61,-5.574c1.306,-2.675 2.844,-7.116 0.919,-13.534z"
                        fill="#000000"></path>
                      <path
                        d="M17.487,26.647c0.754,-2.303 -1.084,-5.442 -2.799,-6.537c-1.409,1.986 -3.894,5.793 -4.538,8.684c4.079,0.583 6.687,-0.163 7.337,-2.147z"
                        fill="#000000"></path>
                      <path
                        d="M36.784,21.48c0.541,3.667 0.376,6.583 -0.141,8.876c4.022,-0.809 3.406,-4.867 0.141,-8.876z"
                        fill="#000000"></path>
                      <circle cx="24" cy="16" r="1" fill="#ffffff"></circle>
                      <circle cx="31" cy="16" r="1" fill="#ffffff"></circle>
                      <path
                        d="M25.881,18.306l1.53,-0.755c0.071,-0.035 0.154,-0.034 0.225,0.002l1.474,0.758c0.179,0.092 0.181,0.348 0.004,0.443l-1.447,0.775c-0.072,0.038 -0.157,0.04 -0.23,0.003l-1.557,-0.778c-0.185,-0.093 -0.185,-0.357 0.001,-0.448z"
                        fill="#ffeb00"></path>
                    </g>
                  </g>
                </svg></a> */}
            </div>
          </div>
          <div className=" col--12 col-lg-3 col-md-6">
            <h3 className="text-color">Short Link</h3>
            <div className="mt-4 d-flex flex-column short-link">

              <a onClick={() => navigate('/')} className="mb-2 text-white cursor-pointer" ><i
                className="fas fa-angle-right text-white me-2"></i>Home</a>

              <a onClick={() => navigate('/Commingsoon')} className="mb-2 text-white cursor-pointer"><i
                className="fas fa-angle-right text-white me-2"></i>Engage</a>

              {/* <a onClick={() => navigate('/GPlaNET')} className="mb-2 text-white cursor-pointer"><i
                className="fas fa-angle-right text-white me-2"></i>About</a> */}

              <a onClick={() => navigate('/Commingsoon')} className="mb-2 text-white cursor-pointer"><i
                className="fas fa-angle-right text-white me-2"></i>Knowledge Repository</a>



              {/* <a onClick={handleShowPledge} className="mb-2 text-white cursor-pointer"><i className="fas fa-angle-right text-white me-2"></i>
                Join the Global Green Credit Initiative Pledge
              </a> */}

              <a onClick={() => navigate('/Commingsoon')} className="mb-2 text-white cursor-pointer"><i
                className="fas fa-angle-right text-white me-2"></i>Events</a>

              <a onClick={() => navigate('/Commingsoon')} className="mb-2 text-white cursor-pointer"><i
                className="fas fa-angle-right text-white me-2"></i>
                Multimedia</a>

            </div>

          </div>

          <div className=" col--12 col-lg-3 col-md-6">
            <h3 className="text-color">Help Link</h3>
            <div className="mt-4 d-flex flex-column help-link">
              {/* <!-- <a href="" className="mb-2 text-white"><i
                className="fas fa-angle-right text-white me-2"></i>Contact</a> --> */}
              <a onClick={() => navigate('/faq')} className="mb-2 text-white cursor-pointer"><i className="fas fa-angle-right text-white me-2"></i>FAQ</a>

              <a onClick={() => navigate('/privacypolicy')} className="mb-2 text-white cursor-pointer"><i className="fas fa-angle-right text-white me-2"></i>Privacy
                Policy</a>

              <a onClick={() => navigate('/termscondition')} className="mb-2 text-white cursor-pointer"><i className="fas fa-angle-right text-white me-2"></i>Terms
                Conditions</a>
            </div>
          </div>
          <div className=" col--12 col-lg-3 col-md-6">
            <h3 className="text-color">Contact Us</h3>

            <div className="text-white mt-4 d-flex flex-column contact-link">
              <a href="https://maps.app.goo.gl/aCgpryYcTsxeWNNL7" target="_blank" className="pb-3 text-light border-bottom border-white"><i
                className="fas fa-map-marker-alt text-white me-2 text-danger"></i>Ministry of
                Environment, Forest and Climate Change
                Indira Paryavaran Bhawan
                Jorbagh Road New Delhi – 110 003
                INDIA.
              </a>

              <a href="tel:011-20819182" className="py-3 text-light border-bottom border-white"><i
                className="fas fa-phone-alt text-white me-2"></i> 011-20819415</a>

              {/* <!-- <a href="tel:011-20819172" className="py-3 text-light border-bottom border-primary"><i
                className="fas fa-phone-alt text-white me-2"></i> 011-20819172</a> --> */}

              {/* <a href="mailto:bhawna.singh@gov.in" className="py-3 text-light border-bottom border-white"><i
                className="fas fa-envelope text-white me-2"></i> bhawna.singh@gov.in</a> */}
            </div>
          </div>
        </div>
        <hr className="text-light mt-5 mb-4" />
        <div className="row">
          <div className="col-md-6 text-center text-md-start">
            <span className="text-light"><a href="#" className="text-secondary"><i
              className="fas fa-copyright text-white me-2"></i> Global Green Credit Initiative </a>, All right
              reserved.</span>
          </div>
        </div>
      </div>

      {
        showPledge &&
        <PledgeRegistration
          handleShowPledge={handleShowPledge}
        />
      }

    </div>
  )
}

export default Footer
