
import { useEffect } from 'react'
import Select from 'react-select'
import { useDispatch, useSelector } from 'react-redux'
import { UserTypeDDLAPI, UserTypeIDAPI } from '../../../Redux/DDLSlice'
import { useAuthState } from '../../../Helper/Context'


export const UserTypeDDL = (props) => {
    const { UserTypeID, setUserTypeID, apiFlag, rowData, IsPost,ApiFlag } = props

    const dispatch = useDispatch()
    const userDetails = useAuthState();
    const { UserID, token } = userDetails

    useEffect(() => {
        const data = {
            Flag: ApiFlag,
            M_UserID: UserID,
            token: token
        }
        dispatch(UserTypeDDLAPI({ data }))
    }, [])

    const { DDLData } = useSelector(state => state.UserTypeDDLData)

    useEffect(() => {
        handleDDL()
    }, [DDLData])

    const handleDDL = () => {
        if (DDLData && DDLData.length > 0) {
            let list = DDLData.map((item, index) => ({
                value: item.m_IndicatorID,
                label: item.indicatorName,
            }))

            setUserTypeID({
                DDL: list,
                ID:  0,
                Label: "Select...",

            })
        }
        else {
            setUserTypeID({
                DDL: [],
                ID: 0,
                Label: "Select...",
            })
        }
    }

    return (
        <div className="form-group">
            <label className="d-block" htmlFor="NameofDepartment">User Type</label>
            <Select
                isClearable
                // isRtl={isRtl}
                isSearchable
                maxMenuHeight={150}
                value={{ value: UserTypeID.ID, label: UserTypeID.Label }}
                onChange={(e) => {
                    e ?
                        setUserTypeID({ ...UserTypeID, ID: e.value, Label: e.label })
                        :
                        setUserTypeID({ ...UserTypeID, ID: 0, Label: "Select..." })

                }}
                options={UserTypeID.DDL}
            />
        </div>
    )
}