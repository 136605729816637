
import React from 'react'
import Header from '../../../Components/Header/Header'
import Footer from '../../../Components/Footer/Footer'
import { PagesHeroSection } from '../../../Components/PagesHeroSection/PagesHeroSection'


export const PastEvent = () => {
    return (
        <>
            <Header />
            <PagesHeroSection
                Heading='Past Event'
                BreadScrum1='Home'
                BreadScrum2='Past Event'
            />
            <div class="row p-5">

                <div class="col-12">
                    {/* <!-- <h2 class="lightgreencolor pb-4">Newsletter</h2> --> */}
                    <h2 class="lightgreencolor">Past Events
                        <span class="float-end">
                            <div class="search-box d-flex justify-content-center align-items-center">
                                <input class="search-input" type="text" name="" placeholder="Type Here" />
                                <a href="#" class="search-btn">
                                    <i class="fas fa-search fs-5"></i>
                                </a>
                            </div>
                        </span>
                    </h2>
                </div>
                <hr class=" mt-4 mb-4" />

                <div className="col-12  col-md-3">
                    <div className="shadow2 p-3">
                        <img src="assets/img/event/DSC_7710.JPG" width="100%" alt="" />
                        <p className="lightgreenbg text-white text-center p-2 py-3" style={{ fontSize: '1.1vw' }}>Green Credit Programme
                        </p>
                        <p className="lightgreencolor my-2">12 July 2023 <span className="float-end"> Delhi, India</span></p>
                        <p className="lightgreencolor my-2">GCP Launch</p>

                        <p className="mt-4 text-center"><span> <a href=""
                            className=" border2 border-success lightgreencolor py-2 px-5">Details <i
                                className="fas fa-angle-right"></i></a></span></p>
                    </div>
                </div>

                <div className="col-12  col-md-3">
                    <div className="shadow2 p-3">
                        <img src="assets/img/event/DSC_8996.JPG" width="100%" alt="" />
                        <p className="lightgreenbg text-white text-center p-2 py-3" style={{ fontSize: '1.1vw' }}>Draft Tree Plantation
                        </p>
                        <p className="lightgreencolor my-2">2 November 2023 <span className="float-end"> Delhi, India</span></p>
                        <p className="lightgreencolor my-2">GCP Launch</p>

                        <p className="mt-4 text-center"><span> <a href=""
                            className=" border2 border-success lightgreencolor py-2 px-5">Details <i
                                className="fas fa-angle-right"></i></a></span></p>


                    </div>
                </div>



            </div>
            <Footer />
        </>
    )
}
