import { EventNameDDL } from "../../../../../Components/DDL/EventNameDDL/EventNameDDL"
import { PartnerNameDDL } from "../../../../../Components/DDL/PartnerNameDDL/PartnerNameDDL"
import { PartnerTypeDDL } from "../../../../../Components/DDL/PartnerTypeDDL/PartnerTypeDDL"
import React, { useState } from 'react'
import { useAuthState } from "../../../../../Helper/Context"
import { useDispatch } from "react-redux"
import { EventGalleryPhotoPostAPI } from "../../../../../Redux/AdminSlice/EventGallarySlice/EventGallarySlice"

export const AddEventGallery = (props) => {
    const { handleOnAddClick, handleIsPost, editData } = props

    const userDetails = useAuthState()
    const { RoleID, UserID, token } = userDetails
    const dispatch = useDispatch()

    const [loading, setloading] = useState(false)
    const [loading1, setloading1] = useState(false)
    const [Document, setDocument] = useState('')
    const [Document1, setDocument1] = useState('')

    const [partnerNameDDL, setpartnerNameDDL] = useState({
        DDL: [],
        ID: 0,
        Label: 'Select...'
    })

    const [eventNameDDL, seteventNameDDL] = useState({
        DDL: [],
        ID: 0,
        Label: 'Select...'
    })

    const handlePhotoInputChange = (e) => {
        let file = e.target.files[0];
        return new Promise(resolve => {
            let baseURL = "";
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                baseURL = reader.result;
                // console.log(reader.result.split(',')[1])
                setDocument(reader.result.split(',')[1])
                resolve(baseURL);
            };
        });
    };

    const handleVideoInputChange = (e) => {
        let file = e.target.files[0];
        return new Promise(resolve => {
            let baseURL = "";
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                baseURL = reader.result;
                // console.log(reader.result.split(',')[1])
                setDocument1(reader.result.split(',')[1])
                resolve(baseURL);
            };
        });
    };

    const handleClear = (flag) => {

        if (flag === 'Photo') {
            document.getElementById('UploadPhoto').value = ''
            // document.getElementById('UploadVideo').value = ''
            setDocument('')
            // setDocument1('')
        } else {
            document.getElementById('UploadVideo').value = ''
            // setDocument('')
            setDocument1('')
        }
    }

   const handlePhotoPost = (flag) => {
        const data = {
            M_EventID: editData.m_EventID,
            M_PartnerRegistrationID: partnerNameDDL.ID,
            M_UserID: UserID,
            token: token,
            handleIsPost: handleIsPost,
            handleClear: handleClear,
            flag: flag
        }
        if (flag === 'Photo') {
            dispatch(EventGalleryPhotoPostAPI({ data, PhotoPath: Document, setloading: setloading }))
        } else {
            dispatch(EventGalleryPhotoPostAPI({ data, VideoPath: Document1, setloading: setloading1 }))
        }
    }

    return (
        <div className="modal show fade px-lg-5" id="exampleModal"
            style={{ display: "block", backgroundColor: "#00000055", overflow: 'auto' }}
            tabindex="-1" aria-labelledby="exampleModalLabel"
            aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div className="modal-header px-lg-5 bg-white">
                        <h5 className="modal-title lightgreencolor" id="exampleModalLabel">Add Event Gallery
                        </h5>
                        <button type="button" className=" bg-danger text-white px-2 border-0 rounded fs-6"
                            data-bs-dismiss="modal" onClick={handleOnAddClick} aria-label="Close"> &times; </button>
                    </div>
                    <div className="modal-body px-3" style={{ overflowY: 'scroll' }}>
                        <form>
                            <div className='row pb-4 pt-2'>
                            <div className="row">
                                <div class="col-12 col-md-8 col-lg-8" >
                                    <PartnerNameDDL
                                        partnerNameDDL={partnerNameDDL}
                                        setpartnerNameDDL={setpartnerNameDDL}
                                        Astric={true}
                                    />
                                </div>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-12 col-md-7 col-lg-8">
                                        <label for="UploadPhoto" className="form-label mb-0">Upload Photo</label>
                                        <small className="text-dark" style={{ fontSize: '12px' }}> (Maximum 5 photos can be upload)</small>
                                        <input
                                            type="file"
                                            accept="image/*"
                                            className="form-control"
                                            id="UploadPhoto"
                                            aria-describedby="emailHelp"
                                            onChange={handlePhotoInputChange}
                                        />
                                        <small className="text-danger" style={{ fontSize: '12px' }}> Upload Image less than 3 MB</small>

                                    </div>
                                    <div className="col-12 col-md-5 col-lg-4">
                                        <p className="mt-4 pt-md-4 pt-xl-0">
                                            <button type="button" className=" float-start btn lightgreenbg text-white px-4"
                                                onClick={() => handlePhotoPost('Photo')}
                                                disabled={partnerNameDDL.ID == 0 || Document == '' || loading}
                                            >{loading && (
                                                <i
                                                    className="fa fa-spinner fa-spin"
                                                    style={{ marginRight: "5px" }}
                                                />
                                            )}
                                                {!loading && <span><i class="fa-solid fa-upload pe-1 fs-6"></i> Upload</span>} </button>
                                        </p>
                                    </div>
                                </div>

                                <div className="row mt-2">
                                    <div className="col-12 col-md-7 col-lg-8">
                                        <label for="UploadVideo" className="form-label">Upload Video </label>
                                        <input
                                            type="file"
                                            accept="video/*"
                                            className="form-control"
                                            id="UploadVideo"
                                            aria-describedby="emailHelp"
                                            onChange={handleVideoInputChange}
                                        />
                                        <small className="text-danger" style={{ fontSize: '12px' }}>Upload a Video less than 75 MB</small>

                                    </div>
                                    <div className="col-12 col-md-5 col-lg-4">
                                        <p className="mt-4 pt-1 mt-lg-4 pt-lg-1">
                                            <button type="button" className=" float-start btn lightgreenbg text-white px-4"
                                                onClick={() => handlePhotoPost('Video')}
                                                disabled={partnerNameDDL.ID == 0 || Document1 == '' || loading1}
                                            >{loading1 && (
                                                <i
                                                    className="fa fa-spinner fa-spin"
                                                    style={{ marginRight: "5px" }}
                                                />
                                            )}
                                                {!loading1 && <span><i class="fa-solid fa-upload pe-1 fs-6"></i> Upload</span>} </button>
                                        </p>
                                    </div>
                                </div>


                                {/* <p className="mt-2">
                                    <button type="button" className=" float-end btn lightgreenbg text-white px-4"
                                        onClick={() => handlePhotoPost('Video')}
                                        disabled={partnerNameDDL.ID == 0 || Document == '' || loading1}
                                    >{loading1 && (
                                        <i
                                            className="fa fa-spinner fa-spin"
                                            style={{ marginRight: "5px" }}
                                        />
                                    )}
                                        {!loading1 && <span><i class="fa-solid fa-upload pe-1 fs-6"></i> Upload</span>} </button>
                                </p> */}

                                <p className="mt-2">
                                    <button type="button" className=" float-end btn btn-danger text-white px-4"
                                        onClick={handleOnAddClick}

                                    >
                                        {!loading1 && <span><i class="fa-solid fa-xmark pe-1 fs-6"></i> Close</span>} </button>
                                </p>

                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}