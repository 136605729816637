
import { useEffect } from 'react'
import Select from 'react-select'
import { useDispatch, useSelector } from 'react-redux'
import { StatusDDLAPI } from '../../../Redux/DDLSlice'
import { useAuthState } from '../../../Helper/Context'


export const StatusDDL = (props) => {
    const { statusDDL, setstatusDDL, apiFlag, rowData, IsPost } = props

    const dispatch = useDispatch()
    const userDetails = useAuthState();
    const { UserID, token } = userDetails

    useEffect(() => {
        const data = {
            Flag: 'Search_Partner',
            UserID: UserID,
            token: token
        }
        dispatch(StatusDDLAPI({ data }))
    }, [])

    const { DDLData } = useSelector(state => state.StatusDDLData)

    useEffect(() => {
        handleDDL()
    }, [DDLData])

    const handleDDL = () => {
        if (DDLData && DDLData.length > 0) {
            let list = DDLData.map((item, index) => ({
                value: item.m_IndicatorID,
                label: item.indicatorName,
            }))

            setstatusDDL({
                DDL: list,
                ID: apiFlag == 'Update' ? rowData?.m_IndicatorID : 0,
                Label: apiFlag == 'Update' ? rowData?.indicatorName : "Select...",

            })
        }
        else {
            setstatusDDL({
                DDL: [],
                ID: 0,
                Label: "Select...",
            })
        }
    }

    return (
        <div className="form-group">
            <label className="d-block" htmlFor="NameofDepartment">Status</label>
            <Select
                isClearable
                // isRtl={isRtl}
                isSearchable
                maxMenuHeight={150}
                value={{ value: statusDDL.ID, label: statusDDL.Label }}
                onChange={(e) => {
                    e ?
                        setstatusDDL({ ...statusDDL, ID: e.value, Label: e.label })
                        :
                        setstatusDDL({ ...statusDDL, ID: 0, Label: "Select..." })

                }}
                options={statusDDL.DDL}
            />
        </div>
    )
}