
export default function FAB({ toggleState, settoggleState }) {

    return (
        <div id="fab" onClick={() => settoggleState()}>
            <span>ADD</span>
            <i className="fas fa-plus" style={{ fontSize: "20px", color: "#fff" }}></i>
        </div>

    )
}