import React from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'

export default function Bullet() {
    return (
        <>
            <li class="px-2 fs-5 mobile_none">
              
                <img src='https://ik.imagekit.io/gplanet/Gplanet/img/gplanet/bullet-point.png?updatedAt=1701510161360' className='lightgreencolor' alt="science" width="22vw" />
            </li>
        </>
    )
}
