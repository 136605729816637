import { useNavigate } from "react-router-dom"

export const Discussion = () => {
    const navigate = useNavigate()

    return (
        <>
            <div className="row p-lg-5">

                <div className="col-12 col-lg-8">
                    <div>
                        <h5 className="pb-0 mb-0">Subject Name Here</h5>
                        <small>Sep 26 2023,</small>
                        <small> 11:47 am</small>
                    </div>
                    <div className="rounds_with_border mt-3">

                        <div className=" p-3">
                            <p>
                                <span className="px-2 text-dark">
                                    <i class="fa-solid fa-face-smile text-info"></i> Namita</span>
                                <small>Sep 26 2023, </small>
                                <small> 11:47 am</small>
                            </p>
                            <p className="text-dark">I would like to do an onboarding screen "welcome to bla bla bla" with an image at the top, logo etc. Is there a way of doing it?</p>
                            <p className="mb-1">
                                <span className="border rounded px-3 py-1" style={{ background: '#f2f2f2' }}>
                                    <i class="fa-solid fa-thumbs-up "></i>
                                    <span className="ps-1 pe-2">50</span>
                                    <span className="" style={{ borderLeft: '1px solid #bdc3c7' }}> <i class="fa-solid fa-thumbs-down fa-flip-horizontal"></i></span>
                                    <span className="ps-1 pe-2">0</span>
                                </span>
                            </p>
                        </div>



                        <div className=" p-3" style={{ backgroundColor: '#dafbe1' }}>
                            <p>
                                <span className="px-2 text-dark">
                                    <i class="fa-solid fa-face-smile text-warning"></i> Rohini</span>
                                <small>Sep 26 2023,</small>
                                <small> 11:47 am</small>
                            </p>
                            <p className="text-dark">"welcome to bla bla bla" </p>
                            <p className="mb-1">
                                <span className="border rounded px-3 py-1" style={{ background: '#f2f2f2' }}>
                                    <i class="fa-solid fa-thumbs-up "></i>
                                    <span className="ps-1 pe-2">50</span>
                                    <span className="" style={{ borderLeft: '1px solid #bdc3c7' }}> <i class="fa-solid fa-thumbs-down fa-flip-horizontal"></i></span>
                                    <span className="ps-1 pe-2">0</span>
                                </span>
                            </p>
                        </div>

                        <div className=" p-3">
                            <p>
                                <span className="px-2 text-dark">
                                    <i class="fa-solid fa-face-smile text-success"></i> Rasika</span>
                                <small>Sep 26 2023,</small>
                                <small> 11:47 am</small>
                            </p>
                            <p className="text-dark">Welcome to BRAND_NAME app! Within next 2 minutes we will guide you through the app functionality. </p>
                            <p className="mb-1">
                                <span className="border rounded px-3 py-1" style={{ background: '#f2f2f2' }}>
                                    <i class="fa-solid fa-thumbs-up "></i>
                                    <span className="ps-1 pe-2">50</span>
                                    <span className="" style={{ borderLeft: '1px solid #bdc3c7' }}> <i class="fa-solid fa-thumbs-down fa-flip-horizontal"></i></span>
                                    <span className="ps-1 pe-2">0</span>
                                </span>
                            </p>
                        </div>
                    </div>
                    <div className="col-12 pt-3">
                        <div class="mb-3">
                            <label for="exampleFormControlTextarea1" class="form-label fw-bold text-dark">Comments</label>
                            <textarea class="form-control" id="exampleFormControlTextarea1" rows="3"></textarea>
                        </div>
                    </div>
                </div>

                <div className="col-12 col-lg-4">

                    <div>
                        <a href="javascript:void(0)" onClick={() => navigate('/ViewAllSubject')}>View All Subject</a>
                    </div>
                    <div>
                        <small>Questions and Answers</small>
                    </div>

                    <hr />
                    <div>
                        <p className="text-dark">3 participants</p>
                        <span className="px-2"> <i class="fa-solid fa-face-smile text-info"></i> Namita</span>
                        <span className="px-2"> <i class="fa-solid fa-face-smile text-warning"></i> Rohini</span>
                        <span className="px-2"> <i class="fa-solid fa-face-smile text-success"></i> Rasika</span>
                    </div>

                </div>

            </div>
        </>
    )
}