import React, { useEffect, useState } from 'react'
import Header from '../../../../../Components/Header/Header'
import Footer from '../../../../../Components/Footer/Footer'
import { PagesHeroSection } from '../../../../../Components/PagesHeroSection/PagesHeroSection'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { NoData } from '../../../../../Helper/NoData/NoData';
import { useDispatch, useSelector } from 'react-redux';
import { PhotovideoEventTableDataAPI } from '../../../../../Redux/MultimediaSlice/MultimediaSlice';
import { useAuthState } from '../../../../../Helper/Context';
import { Pegination } from '../../../../../Components/Pagination/Pagination';
import { useSearchParams } from 'react-router-dom';

const Photos = () => {
    const dispatch = useDispatch()
    const [params] = useSearchParams()
    const userDetails = useAuthState()
    const { RoleID, UserID, token } = userDetails

    // ----------pagination-------------
    const [PerPageCount, setPerPageCount] = useState(10)
    const [TotalCount, setTotalCount] = useState(0)
    const [To, setTo] = useState(10)
    const [From, setFrom] = useState(1)
    const [rowNo, setrowNo] = useState(1)
    const [CurrentPage, setCurrentPage] = useState(0)

    const ScreenName = params.get('name');

    useEffect(() => {
        const data = {
            M_EventID: '0',
            Flag: ScreenName,
            M_UserID: '0',
            token: token,
            From: '1',
            To: '99999',
        }
        dispatch(PhotovideoEventTableDataAPI({ data }))
    }, [])

    const { isLoading, tableData } = useSelector(state => state.PhotovideoEventTableData)

    return (
        <>
            <Header active='Multimedia'/>

            <PagesHeroSection
                Heading={ScreenName == 'Photo' ? 'Photos' : 'Videos'}
                BreadScrum1='Home'
                BreadScrum2='Multimedia'
            />
            <div className="row p-4 p-md-5 ">

                {
                    tableData && tableData.length > 0 ? tableData.map(i => {
                        return (

                            <div className="col-12 col-md-4 col-lg-3 mt-4 " >



                                <div className="shadow_media rounded border  gray cursor-pointer bg-white" >

                                    <LazyLoadImage effect='blur' src={i?.photoPath} alt="About" width='100%' height='200px' style={{ objectFit: 'contain' }}
                                        onClick={() => window.open(i.photoPath)}
                                    />

                                    <div className="  px-3 mt-2 text-start text-dark fw-bold" style={{ fontSize: '13px', height: '5vh' }} >
                                        {i?.eventName}
                                    </div>
                                    <div className=" border-success  py-3 px-3" style={{ fontSize: '13px', height: '8vh' }}>
                                        <span className="" ><i class="fa-solid fa-location-dot text-danger me-1"></i> {i?.location}</span>
                                    </div>
                                </div>


                            </div>
                        )
                    })
                        :

                        <div class="row">
                            <div className='col-12 col-md-4 mx-auto text-center '>
                                <NoData />
                            </div>
                        </div>
                }

                {/* <div class="row">
                    <div className='col-12 col-md-4 mx-auto text-center '>
                        <NoData />
                    </div>
                </div> */}
                
                {/* {tableData && tableData.table && tableData.table.length > 0 &&
                    <Pegination
                        PerPageCount={PerPageCount}
                        // TotalCount={tableData.table[0].totalCount}
                        TotalCount='10'
                        setFrom={setFrom}
                        setTo={setTo}
                        setrowNo={setrowNo}
                        CurrentPage={CurrentPage}
                        setCurrentPage={setCurrentPage}
                    />
                } */}
            </div>
            <Footer />
        </>
    )
}

export default Photos