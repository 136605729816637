import { LazyLoadImage } from "react-lazy-load-image-component"

export const PagesHeroSection = (props) => {
    const { Heading, BreadScrum1, BreadScrum2 } = props
    return (
        <div className="row bg_img" id="herosection">

            <div className="col-12 col-lg-6 d-flex  align-items-center">
                <div className=" py-5 px-4 text-white">
                    <h2 class="dark-blue mb-3 animated slideInDown lightgreencolor text-white">{Heading}</h2>
                    <nav style={{ bsBreadcrumbdivider: '>' }} aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item active "><a href="#" className="text-white">{BreadScrum1}</a></li><span
                                className="mx-2 text-white">|</span>
                            {
                                Heading !== 'Contact Us' && Heading !== 'Past Event' && Heading !== 'Privacy Policy' && Heading !=='Terms and Conditions'?
                                <>
                                    <li className="breadcrumb-item active " aria-current="page"><a href="#"
                                        className="text-white">{BreadScrum2}</a></li>
                                    <span className="mx-2 text-white">|</span>
                                   
                                </>
                                 : ''
                            }
                            <li className="breadcrumb-item active " aria-current="page"><a href="#"
                                className="lightgreencolor text-white ">{Heading}</a></li>
                        </ol>
                    </nav>
                </div>

            </div>

            <div className='col-12 col-lg-6 d-flex align-items-center justify-content-end pe-lg-5 '>
                <LazyLoadImage effect="blur" className='mobile_none' src='https://ik.imagekit.io/gplanet/Gplanet/img/top_logo/GCP_logo.png?updatedAt=1701510158475' width='65%'    />
            </div>
        </div>
    )
}