import { useEffect, useState } from "react"
import Footer from "../../../../Components/Footer/Footer"
import Header from "../../../../Components/Header/Header"
import { useNavigate } from "react-router-dom"
import { loginUser, logout, useAuthDispatch, useAuthState } from "../../../../Helper/Context"
import { current } from "@reduxjs/toolkit"
import { useDispatch, useSelector } from "react-redux"
import { PostChangePasswordAPI } from "../../../../Redux/NewPassword/NewPasswordSlice"
import { RegExPassword } from "../../../../Helper/Regx/Regx"


export const NewPassword = (props) => {

    const { FormData } = props

    const navigate = useNavigate();
    const dispatch = useDispatch()

    const [Message, setMessage] = useState("")
    const [IsLoading, setIsLoading] = useState(false)

    const [PasswordFormData, setPasswordFormData] = useState({
        Password: '',
        ConfirmPassword: ''
    })

    const handleSubmit = () => {
        const data = {
            Email_ID: FormData.Email,
            NewPassword: PasswordFormData.Password,
            OTP: FormData.OTP,
            setMessage: setMessage,
            handleNavigate: handleNavigate,
            setIsLoading:setIsLoading
        }
        dispatch(PostChangePasswordAPI({ data }))
    }

    const { isChangeLoading } = useSelector(state => state.PostChangePasswordData)

    console.log('PostChangePasswordData',isChangeLoading)

    const handleNavigate = () => {
        navigate('/login')
    }
    const onChange = (e) => {
        const { name, value } = e.target
        setPasswordFormData(pre => ({
            ...pre, [name]: value
        }))
    }

    const [IsValidPassword, setIsValidPassword] = useState(false)
    const handleCheckPassword = (e) => {
        setMessage('')
        setPasswordFormData({
            ...FormData,
            ConfirmPassword: '',
        })
        onChange(e)
        const IsValid = RegExPassword(e.target.value)
        setIsValidPassword(IsValid)
        return IsValid
    }

    const [IsConfirmValidPassword, setIsConfirmValidPassword] = useState(false)
    const handleCheckConfirmPassword = (e) => {
        setMessage('')
        onChange(e)
        if (PasswordFormData.Password == e.target.value) {
            setIsConfirmValidPassword(false)
        } else {
            setIsConfirmValidPassword(true)
        }
    }

    const showPass = () => {
        var x = document.getElementById("pass");
        var y = document.getElementById("eye");
        if (x.type === "password") {
            x.type = "text";
        } else {
            x.type = "password";
        }
        if (y.className === "fa fa-eye") {
            y.className = "fa fa-eye-slash";
        } else {
            y.className = "fa fa-eye";
        }
    }


    return (
        <div className="container-fluid g-0">

            <Header />

            {/* <!-- login Form --> */}
            <div className="row login_page p-4 p-md-5">

                <div className=" col-lg-7">

                </div>

                <div className=" col-12 col-lg-5  bg-white rounded">
                    <div className="bg-white px-md-5 pb-md-5 pt-md-4 px-4 py-3">
                        <div className="px-lg-5 py-5">
                            <form>
                                <div className="mb-1">
                                    <label for="exampleInputPassword1"
                                        className="form-label lightgreencolor fw-bold">New Password:</label>
                                    <input
                                        type="password"
                                        name="Password"
                                        className="form-control"
                                        value={FormData.Password}
                                        // onChange={onChange}
                                        onChange={(e) => handleCheckPassword(e)}
                                    />
                                    {
                                        IsValidPassword &&
                                        <div className="RegxValidation">
                                            <text >Please enter Minimum six characters, at least one uppercase letter, one lowercase letter, one number and one special character.</text>
                                        </div>
                                    }
                                </div>
                                <div className="mb-3">
                                    <label for="exampleInputPassword1"
                                        className="form-label lightgreencolor fw-bold">Confirm New
                                        Password:</label>

                                    <div className="input-group ">
                                        <input
                                            type="password"
                                            className="form-control"
                                            name="ConfirmPassword"
                                            id="pass"
                                            value={PasswordFormData.ConfirmPassword}
                                            onChange={(e) => handleCheckConfirmPassword(e)}
                                        />
                                        <span className="input-group-text">

                                            <span id='eye' className="fa fa-eye-slash"
                                                onClick={() => showPass()}

                                            ></span>
                                        </span>
                                    </div>
                                    {
                                        IsConfirmValidPassword &&
                                        <div className="RegxValidation">
                                            <text >Password Mismatch</text>
                                        </div>
                                    }

                                </div>

                                <p className="pt-3 text-center">

                                    <button className="all_button px-5 py-2 float-lg-start "
                                        type="button"
                                        onClick={() => handleSubmit()}
                                        style={{ cursor: (isChangeLoading||IsLoading || IsValidPassword || IsConfirmValidPassword || PasswordFormData.ConfirmPassword == '' || PasswordFormData.Password == '') ? 'not-allowed' : 'pointer' }}
                                        disabled={isChangeLoading || IsValidPassword || IsConfirmValidPassword || PasswordFormData.ConfirmPassword == '' || PasswordFormData.Password == ''}
                                    >
                                        {isChangeLoading||IsLoading && (
                                            <i
                                                className="fa fa-spinner fa-spin"
                                                style={{ marginLeft: "20px" }}
                                            />
                                        )}
                                        {!IsLoading ? <span>Submit</span>:<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>}
                                    </button>


                                    <button className="all_button border border-danger bg-danger px-5 py-2 float-lg-end "
                                        onClick={() => handleNavigate()}
                                    > Cancel</button>
                                </p>

                                <text className="RegxValidation">{Message}</text>


                            </form>
                        </div>
                    </div>
                </div>

            </div>

            <Footer />
        </div >

    )
}