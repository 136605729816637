
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { toastErrorr, toastSuccesss } from "../../../Helper/ToastMessage";
import { BaseUrl } from "../../../Helper/BaseURL";



// Get Table Data
export const NewsLetterTableDataAPI = createAsyncThunk("NewsLetterTableData", async ({ data }) => {
    const {
        M_NewsLetterID,
        MediaDate,
        M_UserID,
        token,
        TOP,
        setTotalCount
    } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };
    return fetch(`${BaseUrl}/Master/Get_M_NewsLetter_Select?M_NewsLetterID=${M_NewsLetterID ? M_NewsLetterID : '0'}&MediaDate=${MediaDate ? MediaDate : ''}&M_UserID=${M_UserID}&TOP=${TOP}`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                setTotalCount(result.data && result.data.table && result.data.table[0].totalCount)
                return result.data
            } else {
                setTotalCount(0)
                return result
            }
        })
})

const NewsLetterTableDataSlice = createSlice({
    name: "NewsLetterTableData",
    initialState: {
        isLoading: false,
        tableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(NewsLetterTableDataAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(NewsLetterTableDataAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.tableData = action.payload;
        });
        builder.addCase(NewsLetterTableDataAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.tableData = null;
            toastErrorr('Something went wrong')
        });
    },
})

export const NewsLetterTableDataReducer = NewsLetterTableDataSlice.reducer

// InsertUpdate Table Data

export const NewsLetterPostAPI = createAsyncThunk("NewsLetterPost", async ({ data }) => {
    const {
        Subject,
        MediaDate,
        NewsLetterDoc,
        NewsURL,
        NewType,
        M_UserID,
        token,
        Flag,
        handleIsPost,
        handleAddCloseClick,
        setloading
    } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var formdata = new FormData();
    formdata.append("Subject", Subject);
    formdata.append("MediaDate", MediaDate);
    formdata.append("NewsLetterDoc", NewsLetterDoc)
    formdata.append("NewsURL", NewsURL);
    formdata.append("NewType", NewType);
    formdata.append("Flag", Flag);
    formdata.append("M_UserID", M_UserID);

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
    };
    setloading(true)
    fetch(`${BaseUrl}/Master/Post_M_NewsLetter_Insert`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.status) {
                handleIsPost()
                handleAddCloseClick()
                // handleClear()
                toastSuccesss(result.message)
            } else {
                toastErrorr(result.message)
            }
            setloading(false)
            return result

        })
})

const NewsLetterPostSlice = createSlice({
    name: "NewsLetterPost",
    initialState: {
        isLoading: false,
        CropNametableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(NewsLetterPostAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(NewsLetterPostAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.CropNametableData = action.payload;
        });
        builder.addCase(NewsLetterPostAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.CropNametableData = null;
            // toastErrorr('Something went wrong')
        });
    },
})

export const NewsLetterPostReducer = NewsLetterPostSlice.reducer

//Delete Api

export const NewsLetterDeleteAPI = createAsyncThunk("NewsLetterDelete", async ({ data }) => {
    const {
        M_NewsLetterID,
        M_UserID,
        token,
        handleIsPost,
        handleAddCloseClick,
        setloading
    } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var formdata = new FormData();
    formdata.append("M_NewsLetterID", M_NewsLetterID);
    formdata.append("M_UserID", M_UserID);

    var requestOptions = {
        method: 'DELETE',
        body: formdata,
        redirect: 'follow'
    };
    setloading(true)
    fetch(`${BaseUrl}/Master/M_NewsLetter_Delete`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.status) {
                handleIsPost()
                handleAddCloseClick()
                // handleClear()
                toastSuccesss(result.message)
            } else {
                toastErrorr(result.message)
            }
            setloading(true)
            return result

        })
})

const NewsLetterDeleteSlice = createSlice({
    name: "NewsLetterDelete",
    initialState: {
        isLoading: false,
        CropNametableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(NewsLetterDeleteAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(NewsLetterDeleteAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.CropNametableData = action.payload;
        });
        builder.addCase(NewsLetterDeleteAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.CropNametableData = null;
            // toastErrorr('Something went wrong')
        });
    },
})

export const NewsLetterDeleteReducer = NewsLetterDeleteSlice.reducer



