import { useDispatch, useSelector } from "react-redux"
import { useAuthState } from "../../../../../Helper/Context"
import { PartnerNameDDL } from "../../../../../Components/DDL/PartnerNameDDL/PartnerNameDDL"
import { useEffect, useState } from "react"
import { PartnerCaseStudyPostAPI } from "../../../../../Redux/AdminSlice/PartnerCaseStudySlice/PartnerCaseStudySlice"
import { AstricSign } from "../../../../../Helper/AstricSign/AstricSign"
import { useSearchParams } from "react-router-dom"
import { CommonSamplePDFUploadPostAPI, CommonSampleReportAPIDataAPI } from "../../../../../Redux/AdminSlice/SampleReport/SampleReportSlice"
import { toastErrorr } from "../../../../../Helper/ToastMessage"
import moment from "moment"

export const AddKnowledgeRepositoryCaseStudy = (props) => {
    const { handleOnAddClick, handleIsPost } = props

    const userDetails = useAuthState()
    const { RoleID, UserID, token } = userDetails
    const dispatch = useDispatch()

    const [params] = useSearchParams()
    const IndicatorName = params.get('IndicatorName');

    const [To, setTo] = useState(10)
    const [From, setFrom] = useState(1)

    const [PDF_Doc, setPDF_Doc] = useState('')
    const [Subject, setSubject] = useState('')
    const [CaseDate, setCaseDate] = useState('')
    const [Document, setDocument] = useState('')
    const [isPostLoading, setisPostLoading] = useState(false)
    const [YesConformCheckbox, setYesConformCheckbox] = useState(false)
    const [SamplePDFPostLoading, setSamplePDFPostLoading] = useState(false)
    const [SamplePDFDocument, setSamplePDFDocument] = useState(null)
    const [SampleDocumentType, setSampleDocumentType] = useState(null)

    const handleYesNo = () => {
        setYesConformCheckbox(!YesConformCheckbox)
    }

    const [partnerNameDDL, setpartnerNameDDL] = useState({
        DDL: [],
        ID: 0,
        Label: 'Select...'
    })

    const handleFileInputChange = (e) => {
        let file = e.target.files[0];
        return new Promise(resolve => {
            let baseURL = "";
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                baseURL = reader.result;
                // console.log(reader.result.split(',')[1])
                setDocument(reader.result.split(',')[1])
                resolve(baseURL);
            };
        });
    };

    const handleSamplePDFUPloadFileInputChange = (e) => {
        let file = e.target.files[0];
        return new Promise(resolve => {
            let baseURL = "";
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                baseURL = reader.result;
                // console.log(reader.result.split(',')[1])
                const type = baseURL.split(';')[0].split('/')[1];
                setSampleDocumentType('.' + type)
                setSamplePDFDocument(reader.result.split(',')[1])
                resolve(baseURL);
                // console.log('SampleDocumentType',type)
            };
        });
    };

    const handlePost = () => {
        const data = {
            M_PartnerRegistrationID: partnerNameDDL.ID,
            CaseDate: CaseDate,
            CaseStudiesSubject: Subject,
            PDF_Doc: Document,
            M_UserID: UserID,
            token: token,
            Flag: RoleID == 1 ? 'Admin' : 'Partner',
            handleIsPost: handleIsPost,
            handleAddCloseClick: handleOnAddClick,
            setisPostLoading: setisPostLoading
        }
        dispatch(PartnerCaseStudyPostAPI({ data }))
    }


    useEffect(() => {
        const data = {
            M_SampleReportID: 0,
            SampleReport_M_IndicatorID: IndicatorName,
            M_UserID: UserID,
            token: token,
            From: From,
            To: To,
        }
        dispatch(CommonSampleReportAPIDataAPI({ data }))
    }, [From, To, IndicatorName])

    const { tableData, isLoading } = useSelector(state => state.CommonSampleReportAPIData)

    const handleOpenPDF = () => {
        if (tableData && tableData[0]?.sampleReport_Path != null) {
            window.open(tableData[0]?.sampleReport_Path,);
        } else {
            toastErrorr('Sample Report is not available!');
        }
    }

    const handleSamplePDFPost = () => {
        const data = {
            M_FinancialYearID: moment().format('YYYY'),
            M_MonthID: moment().format('MM'),
            SampleReport_M_IndicatorID: IndicatorName,
            SampleReport_Path: SamplePDFDocument,
            SampleReport_FileExtension: SampleDocumentType,
            handleIsPost: handleIsPost,
            M_UserID: UserID,
            token: token,
            setSamplePDFPostLoading: setSamplePDFPostLoading
        }
        dispatch(CommonSamplePDFUploadPostAPI({ data }))
    }
    return (
        <div className="modal show fade px-lg-5" id="exampleModal"
            style={{ display: "block", backgroundColor: "#00000055", overflow: 'auto' }}
            tabindex="-1" aria-labelledby="exampleModalLabel"
            aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">

                    <div class="modal-header px-lg-5 bg-white">
                        <h5 class="modal-title lightgreencolor" id="exampleModalLabel">Partner Report
                        </h5>
                        <button type="button" class=" bg-danger text-white px-2 border-0 rounded fs-6"
                            onClick={handleOnAddClick}> &times; </button>
                    </div>

                    <div className="modal-body px-3 pt-4" style={{ overflowY: 'scroll' }}>
                        <form>
                            <div className='row'>

                                <div class="col-12 col-md-6">
                                    <PartnerNameDDL
                                        partnerNameDDL={partnerNameDDL}
                                        setpartnerNameDDL={setpartnerNameDDL}
                                        Astric={true}
                                    />
                                </div>

                                <div class="col-12 col-md-6">
                                    <label for="exampleInputEmail1" class=''>Upload Date<AstricSign />
                                    </label>
                                    <input type="date" class="form-control" id="exampleInputEmail1"
                                        aria-describedby="emailHelp"
                                        value={CaseDate}
                                        onChange={(e) => setCaseDate(e.target.value)}
                                    />
                                </div>
                            </div>

                            <div className='row mt-4'>

                                <div className="col-12 col-md-6">
                                    <label for="exampleInputEmail1" className="form-label pt-1">Subject<AstricSign />
                                    </label>
                                    <input type="text" className="form-control" id="exampleInputEmail1"
                                        aria-describedby="emailHelp"
                                        value={Subject}
                                        onChange={(e) => setSubject(e.target.value)} />
                                </div>

                                {/* <div className="col-12 col-md-6">
                                    <label for="exampleInputEmail1" className="form-label pt-1">Upload PDF<AstricSign/>
                                    </label>
                                    <input type="file" className="form-control" id="exampleInputEmail1"
                                        aria-describedby="emailHelp"
                                        onChange={handleFileInputChange} />
                                </div> */}

                                <div class="col-12 col-md-6">
                                    <label for="exampleInputEmail1" class="form-label pt-1">Upload PDF <AstricSign />
                                        <a style={{ fontSize: '13px', textDecoration: 'underline', color: 'blue', cursor: 'pointer' }}
                                            onClick={handleOpenPDF} >(Click to View Sample Report)</a>
                                    </label>
                                    <input type="file" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"
                                        onChange={handleFileInputChange}
                                    />
                                    <small className="text-danger" style={{ fontSize: '12px' }}> Upload a PDF file less than 3 MB</small>
                                </div>

                                {
                                    RoleID != 1 &&
                                    <>
                                        <div className="col-12 pt-4">
                                            <p className="">I grant GGCI permission to make the resource I am uploading public on the platform.</p>
                                            <div class="mb-3 form-check">
                                                <input type="checkbox"
                                                    class="form-check-input"
                                                    id="exampleCheck1"
                                                    name="YesConformCheckbox"
                                                    value={YesConformCheckbox}
                                                    onChange={(e) => handleYesNo(e)}
                                                />
                                                <label class="form-check-label " for="exampleCheck1">Yes, I confirm</label>
                                            </div>
                                        </div>

                                    </>
                                }

                                <div className=" col-12 pt-2 pb-2">
                                    <button type="button" className="btn float-end  lightgreenbg text-white px-4"
                                        disabled={isPostLoading || partnerNameDDL.ID == '0' || CaseDate == '' || Subject == '' || Document == '' || RoleID != 1 && YesConformCheckbox == false}
                                        onClick={handlePost}>
                                        {isPostLoading && (
                                            <i
                                                className="fa fa-spinner fa-spin"
                                                style={{ marginRight: "5px" }}
                                            />
                                        )}
                                        {!isPostLoading && <span><i class="fa-solid fa-upload pe-1 fs-6"></i> Upload</span>}
                                    </button>
                                </div>


                                {
                                    RoleID == 1 &&
                                    <>
                                        <div class="col-12 col-md-6 py-2 border-top ">
                                            <label for="exampleInputEmail1" class="form-label pt-1">Sample Report<AstricSign />
                                            </label>
                                            <input type="file" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" accept=".pdf"
                                                onChange={handleSamplePDFUPloadFileInputChange}
                                            />
                                            <small className="text-danger" style={{ fontSize: '12px' }}>Upload a PDF file less than 3 MB</small>
                                        </div>
                                        <div class="col-12 col-md-6 border-top pt-5 ">
                                            <button class="btn btn-primary text-white fw-200 px-3 fs-6 mx-3" type="submit"
                                                disabled={SamplePDFPostLoading || SamplePDFDocument == null || SamplePDFDocument == ''}
                                                onClick={handleSamplePDFPost}>
                                                {SamplePDFPostLoading && (
                                                    <i
                                                        className="fa fa-spinner fa-spin"
                                                        style={{ marginRight: "5px" }}
                                                    />
                                                )}
                                                {!SamplePDFPostLoading && <span><i class="fa-solid fa-floppy-disk pe-1"></i> Save</span>}
                                            </button>
                                        </div>
                                    </>
                                }

                            </div>


                        </form>
                    </div>
                    {/* <!-- <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    <button type="button" className="btn btn-primary">Save changes</button>
                </div> --> */}
                </div>
            </div>
        </div>
    )
}