import React from 'react'
import Header from '../../../../../Components/Header/Header'
import Footer from '../../../../../Components/Footer/Footer'
import { useNavigate } from 'react-router'

const ReportDocument = ({ HomeCaseStudy }) => {
    const navigate = useNavigate()
    return (
        <>
            {/* <Header /> */}
            <div class="row py-1">
                <div class="col-12  col-lg-4 mx-auto px-4 ">
                    <h6 class="border-bottom pb-3 dark-blue">Case Study</h6>

                    <div class="  mb-5">

                        <div class="">
                            <div className='row'>

                                <div className='col-1'>
                                    <img src="assets/img/pdf/Icon metro-file-pdf.png" width="15px" alt="" />
                                </div>
                                <div className='col-11'>
                                    <span class="text-dark fw-bold">2023 Update to Leaders on Progress towards the G20 Remittance Target</span>
                                </div>
                            </div>


                            <p class="py-3 ps-3 font_size fw-lighter border-bottom pb-3"><span>October 23, 2023</span>
                                <a href="https://www.g20.org/content/dam/gtwenty/gtwenty_new/document/Final_G20_FMCBG_October_2023_Communique.pdf"><span class="float-end text-success">Read More <i
                                    class="fa-solid fa-right-to-bracket ps-2"></i></span></a>
                            </p>
                            {/* <!-- <hr> --> */}
                        </div>
                        <div class="">
                            <div className='row'>

                                <div className='col-1'>
                                    <img src="assets/img/pdf/Icon metro-file-pdf.png" width="15px" alt="" />
                                </div>
                                <div className='col-11'>
                                    <span class="text-dark fw-bold">G20 Financial Inclusion Action Plan Progress Report 2021-23</span>
                                </div>
                            </div>


                            <p class="py-3 ps-3 font_size fw-lighter border-bottom pb-3"><span>October 23, 2023</span>
                                <a href="https://www.g20.org/content/dam/gtwenty/gtwenty_new/document/Final_G20_FMCBG_October_2023_Communique.pdf"><span class="float-end text-success">Read More <i
                                    class="fa-solid fa-right-to-bracket ps-2"></i></span></a>
                            </p>
                            {/* <!-- <hr> --> */}
                        </div>


                    </div>
                </div>

                <div class="col-12  col-lg-4 mx-auto px-4 " style={{ marginTop: '36px' }}>


                    <div class="">
                        <div className='row'>

                            <div className='col-1'>
                                <img src="assets/img/pdf/Icon metro-file-pdf.png" width="15px" alt="" />
                            </div>
                            <div className='col-11'>
                                <span class="text-dark fw-bold">G20 Report on Macroeconomic Risks Stemming from Climate Change and Transition Pathways</span>
                            </div>
                        </div>


                        <p class="py-3 ps-3 font_size fw-lighter border-bottom pb-3"><span>October 23, 2023</span>
                            <a href="https://www.g20.org/content/dam/gtwenty/gtwenty_new/document/Final_G20_FMCBG_October_2023_Communique.pdf"><span class="float-end text-success">Read More <i
                                class="fa-solid fa-right-to-bracket ps-2"></i></span></a>
                        </p>
                        {/* <!-- <hr> --> */}
                    </div>
                    <div class="">
                        <div className='row'>

                            <div className='col-1'>
                                <img src="assets/img/pdf/Icon metro-file-pdf.png" width="15px" alt="" />
                            </div>
                            <div className='col-11'>
                                <span class="text-dark fw-bold">G20 Culture- shaping the global narrative for inclusive growth</span>
                            </div>
                        </div>

                        <p class="py-3 ps-3 font_size fw-lighter border-bottom pb-3"><span>October 23, 2023</span>
                            <a href="https://www.g20.org/content/dam/gtwenty/gtwenty_new/document/Final_G20_FMCBG_October_2023_Communique.pdf"><span class="float-end text-success">Read More <i
                                class="fa-solid fa-right-to-bracket ps-2"></i></span></a>
                        </p>
                        {/* <!-- <hr> --> */}
                    </div>
                    {
                        HomeCaseStudy == 'HomeCaseStudy' ?
                            <>
                                {/* <div className='text-end'>
                                    <button type="button" class="btn btn-success" onClick={() => navigate('/casestudy')} >Load More</button>
                                </div> */}
                                <p className="pt-5 cursor-pointer pb-5"><span className="float-lg-end"> <a onClick={() => navigate('/casestudy')}
                                className="border border-success py-2 rounded px-5 lightgreencolor">Load More <i
                                    className="fas fa-angle-right"></i></a></span></p>
                            </>
                            :
                            ''
                    }

                </div>
            </div>
            {/* <Footer /> */}
        </>
    )
}

export default ReportDocument