
export const QueryViewPopUp = (props) => {
    const { handleShowQueryViewPopUp, heading, GridData, isARLoading } = props

    return (
        <div className="modal show fade px-lg-5" id="exampleModal"
            style={{ display: "block", backgroundColor: "#00000055", overflow: 'auto' }}
            tabindex="-1" aria-labelledby="exampleModalLabel"
            aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" style={{ maxWidth: '35%' }}>
                <div class="modal-content" style={{ minHeight: '250px' }}>
                    <div class="modal-header px-lg-5 bg-white">
                        <h5 class="modal-title lightgreencolor" id="exampleModalLabel">{heading}
                        </h5>
                        <button type="button" class=" bg-danger text-white px-2 border-0 rounded fs-6"
                            onClick={handleShowQueryViewPopUp}> &times; </button>
                    </div>
                    <div class="modal-body px-5" style={{ height: '50px', overflowY: 'scroll' }}>
                        <form>
                            <div className="col-12 col-lg-12">
                                <label className="">Remark</label>
                                <div className="btn-action d-flex justify-content-center mt-4" >
                                    <div className="col-xl-12 col-lg-12 text-center">
                                        <textarea className="rounded" rows="3" style={{ width: '350px', padding: '5px' }}
                                          disabled  value={GridData && GridData.queryReply}>
                                        </textarea>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    {/* <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                        <button type="button" class="btn btn-primary">Save changes</button>
                    </div> */}
                </div>
            </div>
        </div>
    )
}