
export const PublishReportPopUp = (props) => {
    const { handleAddCloseClick, heading, handlePublish, Lable, loading } = props

    return (
        <div className="modal show fade px-lg-5" id="exampleModal"
            style={{ display: "block", backgroundColor: "#00000055", overflow: 'auto' }}
            tabindex="-1" aria-labelledby="exampleModalLabel"
            aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" style={{ maxWidth: '40%' }}>
                <div class="modal-content" style={{ minHeight: '250px' }}>
                    <div class="modal-header px-lg-5 bg-white">
                        <h5 class="modal-title lightgreencolor" id="exampleModalLabel">{heading}
                        </h5>
                        <button type="button" class=" bg-danger text-white px-2 border-0 rounded fs-6"
                            onClick={handleAddCloseClick}> &times; </button>
                    </div>
                    <div class="modal-body px-5" style={{ height: '50px', overflowY: 'scroll' }}>
                        <form>
                            <div className='row'>
                                <div className="d-flex justify-content-center mt-4 "> Do you want to {Lable}? </div>
                            </div>

                            <div className="col-12 col-lg-12">

                                <div className="btn-action d-flex justify-content-center mt-4 " >
                                    {
                                        loading ?
                                            <button
                                            disabled={loading}
                                                type="button"
                                                className="btn btn-success mx-2"
                                                style={{ width: '60px' }}
                                                onClick={() => handlePublish()}
                                            >{loading && (
                                                <i
                                                    className="fa fa-spinner fa-spin"
                                                    style={{ marginRight: "5px" }}
                                                />
                                            )}
                                                {!loading && <span>Yes</span>}</button>
                                            :
                                            <button
                                                type="button"
                                                className="btn btn-success mx-2"
                                                style={{ width: '60px' }}
                                                onClick={() => handlePublish()}
                                            >Yes</button>
                                    }
                                    <button type="button"
                                        className="btn btn-danger"
                                        style={{ width: '60px' }}
                                        onClick={handleAddCloseClick}
                                    >No</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}