

import { useEffect } from 'react'
import Select from 'react-select'
import { useDispatch, useSelector } from 'react-redux'


export const CountryNameDDL = (props) => {
    const { AccountingIndicators, setAccountingIndicators, apiFlag, rowData, IsPost } = props

    // const dispatch = useDispatch()

    // const UserID = sessionStorage.getItem('User')
    // const access_token = sessionStorage.getItem('NMCToken')

    // useEffect(() => {
    //     const data = {
    //         userID: UserID,
    //         access_token: access_token
    //     }
    //     dispatch(LekhaSanketakDDLAPI({ data }))
    // }, [IsPost])

    // const { DDLData } = useSelector(state => state.LekhaSanketakDDLData)

    // useEffect(() => {
    //     handleDDL()
    // }, [DDLData])

    // const handleDDL = () => {
    //     if (DDLData && DDLData.length > 0) {
    //         let list = DDLData.map((item, index) => ({
    //             value: item.ptmS_M_BudgetCodeID,
    //             label: item.budgetCode,
    //         }))

    //         setAccountingIndicators({
    //             DDL: list,
    //             ID: apiFlag == 'Update' ? rowData?.ptmS_M_BudgetCodeID : 0,
    //             Label: apiFlag == 'Update' ? rowData?.budgetCode : "Select...",

    //         })
    //     }
    //     else {
    //         setAccountingIndicators({
    //             DDL: [],
    //             ID: 0,
    //             Label: "Select...",
    //         })
    //     }
    // }

    return (
        <div className="form-group">
            <label className="d-block" htmlFor="NameofDepartment">Country Name</label>
            <Select
                isClearable
                // isRtl={isRtl}
                isSearchable
                maxMenuHeight={150}
            // value={{ value: AccountingIndicators.ID, label: AccountingIndicators.Label }}
            // onChange={(e) => {
            //     e ?
            //         setAccountingIndicators({ ...AccountingIndicators, ID: e.value, Label: e.label })
            //         :
            //         setAccountingIndicators({ ...AccountingIndicators, ID: 0, Label: "Select..." })

            // }}
            // options={AccountingIndicators.DDL}
            />
        </div>
    )
}