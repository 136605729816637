import Footer from "../../../Components/Footer/Footer"
import Header from "../../../Components/Header/Header"
import { PagesHeroSection } from "../../../Components/PagesHeroSection/PagesHeroSection"

export const ViewAllSubject = () => {
    return (
        <>

            <Header active='Multimedia' />
            <PagesHeroSection
                Heading='Discussion Forum'
                BreadScrum1='Home'
                BreadScrum2='Engage'
            />


            <div className="row p-lg-5">
                <div className="col-12">
                    <h5 className="pb-3">Subject</h5>
                    
                    <div class="d-flex">
                        <div class="nav flex-column nav-pills me-3 col-lg-3" >
                            <button class="nav-link active text-start">Q & A</button>
                        </div>
                       
                        <div class="col-9" >
                            <div className="rounds_with_border ">

                                <div className="p-3">
                                    <p>
                                        <span className="px-2 text-dark">
                                            <i class="fa-solid fa-face-smile text-info"></i> Namita</span>
                                        <small>Sep 26 2023, </small>
                                        <small> 11:47 am</small>
                                    </p>
                                    <p className="text-dark cursor-pointer">Subject Name Here</p>
                                    <p className="mb-1">
                                        <span className="border rounded px-2 py-0">
                                            <i class="fa-solid fa-thumbs-up text-dark"></i>
                                            <span className="ps-1 pe-2" style={{ fontSize: '12px' }}>50</span>
                                            <span className="" style={{ borderLeft: '1px solid #bdc3c7' }}> <i class="fa-solid fa-thumbs-down fa-flip-horizontal"></i></span>
                                            <span className="ps-1 pe-2">0</span>
                                        </span>
                                    </p>
                                </div>

                                <div className="p-3" style={{ backgroundColor: '#dafbe1' }}>
                                    <p>
                                        <span className="px-2 text-dark">
                                            <i class="fa-solid fa-face-smile text-warning"></i> Rohini</span>
                                        <small>Sep 26 2023,</small>
                                        <small> 11:47 am</small>
                                    </p>
                                    <p className="text-dark cursor-pointer">Subject Name Here </p>
                                    <p className="mb-1">
                                        <span className="border rounded px-2 py-0">
                                            <i class="fa-solid fa-thumbs-up text-dark"></i>
                                            <span className="ps-1 pe-2" style={{ fontSize: '12px' }}>50</span>
                                            <span className="" style={{ borderLeft: '1px solid #bdc3c7' }}> <i class="fa-solid fa-thumbs-down fa-flip-horizontal"></i></span>
                                            <span className="ps-1 pe-2">0</span>
                                        </span>
                                    </p>
                                </div>

                                <div className="p-3">
                                    <p>
                                        <span className="px-2 text-dark">
                                            <i class="fa-solid fa-face-smile text-success"></i> Rasika</span>
                                        <small>Sep 26 2023,</small>
                                        <small> 11:47 am</small>
                                    </p>
                                    <p className="text-dark cursor-pointer"> Subject Name Here</p>
                                    <p className="mb-1">
                                        <span className="border rounded px-2 py-0">
                                            <i class="fa-solid fa-thumbs-up text-dark"></i>
                                            <span className="ps-1 pe-2" style={{ fontSize: '12px' }}>50</span>
                                            <span className="" style={{ borderLeft: '1px solid #bdc3c7' }}> <i class="fa-solid fa-thumbs-down fa-flip-horizontal"></i></span>
                                            <span className="ps-1 pe-2">0</span>
                                        </span>
                                    </p>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />

        </>
    )
}