import { useEffect, useState } from "react"
import Footer from "../../../../Components/Footer/Footer"
import Header from "../../../../Components/Header/Header"
import { useNavigate } from "react-router-dom"
import { loginUser, logout, useAuthDispatch, useAuthState } from "../../../../Helper/Context"

import { ForgotPasswordAPI } from "../../../../Redux/ForgotPasswordSlice/ForgotPasswordSlice"
import { useDispatch, useSelector } from "react-redux"
import { RegExEmail } from "../../../../Helper/Regx/Regx"



export const ForgotPassword = (props) => {

    const {handleNext, setFormData , FormData,setScreenMsg} = props

    const dispatch = useDispatch()

    const [Email, setEmail] = useState('')
    const [Message, setMessage] = useState('')

    const [IsValidEmail, setIsValidEmail] = useState(false)
    
    const handleCheckEmail = (e) => {
        setMessage('')
        setEmail(e.target.value)
        const IsValid = RegExEmail(e.target.value)
        setIsValidEmail(IsValid)
        return IsValid
    }

    const handleSubmit = () => {
        setFormData({
            ...FormData,
            Email:Email
        })
        const data = {
            Email: Email,
            setMessage:setMessage,
            handleNext:handleNext
        }
        dispatch(ForgotPasswordAPI({ data }))
    }

    const { isLoading, Data } = useSelector(state => state.ForgotPasswordData)


    return (
        <div className="container-fluid g-0">

            <Header />

            {/* <!-- login Form --> */}
            <div className="row login_page p-4 p-md-5">

                <div className=" col-lg-7">

                </div>

                <div className=" col-12 col-lg-5  bg-white rounded">
                    <div className="bg-white px-md-5 pb-md-5 pt-md-4 px-4 py-3">
                        <div className="px-lg-5 py-5">
                            <h3 className="pb-4 greendarkcolor fw-bold">Forgot Password</h3>
                            <form>
                                <div className="mb-3">
                                    <label for="exampleInputPassword1"
                                        className="form-label lightgreencolor fw-bold"
                                    >Register Email ID:</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="exampleInputPassword1"
                                        value={Email}
                                        // onChange={(e) => (setEmail(e.target.value))}
                                        onChange={(e) => handleCheckEmail(e)}
                                    />
                                    {
                                        IsValidEmail && <text className="RegxValidation">Please enter valid Email Address</text>
                                    }
                                </div>

                                <button className="lightgreenbg all_button w-100"
                                    style={{ cursor: (isLoading ||IsValidEmail || Email == '') ? 'not-allowed' : 'pointer' }}
                                    disabled={isLoading ||IsValidEmail || Email == ''}
                                    type="button"
                                    // style={{ cursor: 'pointer' }}
                                    // onClick={() => navigate('/OTPScreen')}
                                    onClick={() => handleSubmit()}
                                >
                                    {isLoading && (
                                        <i
                                            className="fa fa-spinner fa-spin"
                                            style={{ marginRight: "5px" }}
                                        />
                                    )}
                                    {!isLoading && <span>Send OTP</span>}</button>

                               
                                    <text className="RegxValidation">{Message}</text>
                                

                            </form>
                        </div>
                    </div>
                </div>

            </div>

            <Footer />
        </div >

    )
}