


import React from 'react'
import Header from '../../../Components/Header/Header'
import Footer from '../../../Components/Footer/Footer'
import { PagesHeroSection } from '../../../Components/PagesHeroSection/PagesHeroSection'


export const PrivacyPolicy = () => {
    return (
        <>
            <Header />
            <div className="row" id="herosection">

            <PagesHeroSection
                Heading='Privacy Policy'
                BreadScrum1='Home'
                BreadScrum2='Privacy Policy'
            />

                {/* <div className="col-12 bg_img d-flex  align-items-center">
                    <div className=" py-5 px-4 text-white">
                        <nav style={{ bsBreadcrumbdivider: '>' }} aria-label="breadcrumb">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item active "><a href="#" class="text-white">Home</a></li><span class="mx-2">/</span>
                                <li class="breadcrumb-item active " aria-current="page"><a href="#" class="text-white">Terms and Conditions</a></li>

                            </ol>
                        </nav>
                    </div>
                </div> */}

            </div>
            <div class="row" id="privacy data">
                <div class="col-11 text-center pt-2   mx-auto">
                    <h2 class="mt-3 lightgreencolor fw-bold text-start">Privacy Policy for Global Green Credit Initiative Website</h2>
                    <p class="mt-1 text-start">Effective Date: 01-12-2023</p>
                    <hr class="mx-start lightgreencolor" style={{ width: '200px' }} />

                </div>


                <div class="col-11 pb-4 mx-auto text-start">
                    <h5 class=" my-2 fw-bold">Introduction</h5>
                    <p>The Global Green Credit Initiative respects the privacy of its users. This Privacy Policy outlines how we collect, use, disclose, and protect the information gathered through our website.</p>

                    <h5 class=" my-4 fw-bold">Information Collection</h5>
                    <p><b class="text-dark">Personal Information :</b> We may collect personal information, such as names, email addresses, contact details, etc., provided voluntarily by users through forms or interactions with the website.</p>
                    <p><b class="text-dark">Automatically Collected Information :</b> The Initiative may gather non-personal information, including but not limited to IP addresses, browser details, device information, and website usage data via cookies or similar technologies.</p>

                    <h5 class=" my-4 fw-bold">Use of Information</h5>
                    <p><b class="text-dark">Personal Information :</b> We utilize personal information for communication purposes, to provide requested services, respond to inquiries, and improve user experience.</p>
                    <p><b class="text-dark">Automatically Collected Information :</b> Non-personal information helps us analyse website usage, optimize content, and enhance user interaction.</p>

                    <h5 class=" my-4 fw-bold">Information Sharing</h5>
                    <p>The Initiative does not sell, trade, or otherwise transfer users' personal information to external parties. However, data may be shared with trusted third parties assisting in website operations or service provision, subject to confidentiality agreements.</p>

            

                    <h5 class=" my-4 fw-bold">Data Security</h5>
                    <p>We employ industry-standard security measures to safeguard user data against unauthorized access, alteration, disclosure, or destruction.</p>

                    <h5 class=" my-4 fw-bold">Cookies and Tracking Technologies</h5>
                    <p>The Initiative may use cookies or similar tracking technologies to enhance user experience and gather information about website usage. Users can manage preferences regarding cookies through browser settings.</p>

                    <h5 class=" my-4 fw-bold">Third-Party Links</h5>
                    <p>Our website may contain links to third-party sites. The Initiative is not responsible for the privacy practices or content of such external websites. Users are encouraged to review the privacy policies of those sites.</p>


                    <h5 class=" my-4 fw-bold">Children's Privacy</h5>
                    <p>The Initiative does not knowingly collect personal information from individuals under the age of 13. If a parent or guardian believes their child has provided personal information, they should contact us immediately for its removal.</p>

                    <h5 class=" my-4 fw-bold">Changes to Privacy Policy</h5>
                    <p>The Initiative reserves the right to update this Privacy Policy. Users will be notified of any significant changes via email or prominent website notifications.</p>

                    <h5 class=" my-4 fw-bold">Contact Information</h5>
                    <p>For any queries, concerns, or requests regarding this Privacy Policy or data handling practices, users can contact us at [contact information].</p>
                    <p>By accessing and using the Global Green Credit Initiative website, users agree to the terms outlined in this Privacy Policy.</p>


                </div>
            </div>
            <Footer />
        </>
    )
}
