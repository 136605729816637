
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { toastErrorr, toastSuccesss } from "../../../Helper/ToastMessage";
import { BaseUrl } from "../../../Helper/BaseURL";



// Get Table Data
export const PartenerReportTableDataAPI = createAsyncThunk("PartenerReportTableData", async ({ data }) => {
    const {
        M_KnowledgeRepository_ReportID,
        M_PartnerRegistrationID,
        ReportDate,
        Flag,
        From,
        To,
        M_UserID,
        token,
        setTotalCount
    } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    return fetch(`${BaseUrl}/Master/Get_M_KnowledgeRepository_Report_Select?M_KnowledgeRepository_ReportID=${M_KnowledgeRepository_ReportID ? M_KnowledgeRepository_ReportID : '0'}&M_PartnerRegistrationID=${M_PartnerRegistrationID ? M_PartnerRegistrationID : '0'}&ReportDate=${ReportDate ? ReportDate : ''}&M_UserID=${M_UserID}&Flag=${Flag}&From=${From}&To=${To}`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                setTotalCount(result.data && result.data.table && result.data.table[0].totalCount)
                return result.data
            } else {
                setTotalCount(0)
                return result
            }
        })
})

const PartenerReportTableDataSlice = createSlice({
    name: " PartenerReportTableData",
    initialState: {
        isLoading: false,
        tableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(PartenerReportTableDataAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(PartenerReportTableDataAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.tableData = action.payload;
        });
        builder.addCase(PartenerReportTableDataAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.tableData = null;
            toastErrorr('Something went wrong')
        });
    },
})

export const PartenerReportTableDataReducer = PartenerReportTableDataSlice.reducer

// InsertUpdate Table Data

export const PartenerReportPostAPI = createAsyncThunk("PartenerReportPost", async ({ data }) => {
    const {
        M_PartnerRegistrationID,
        ReportDate,
        ReportSubject,
        PDF_Doc,
        M_UserID,
        token,
        Flag,
        handleIsPost,
        handleAddCloseClick,
        setisPostLoading
    } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);
    setisPostLoading(true)
    var formdata = new FormData();
    formdata.append("M_PartnerRegistrationID", M_PartnerRegistrationID);
    formdata.append("ReportDate", ReportDate);
    formdata.append("ReportSubject", ReportSubject);
    formdata.append("PDF_Doc", PDF_Doc);
    formdata.append("Flag", Flag);
    formdata.append("M_UserID", M_UserID);

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
    };

    fetch(`${BaseUrl}/Master/Post_M_KnowledgeRepository_Report_Insert`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.status) {
                handleIsPost()
                handleAddCloseClick()
                // handleClear()
                toastSuccesss(result.message)
            } else {
                toastErrorr(result.message)
            }
            setisPostLoading(false)
            return result

        })
})

const PartenerReportPostSlice = createSlice({
    name: "PartenerReportPost",
    initialState: {
        isPostLoading: false,
        CropNametableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(PartenerReportPostAPI.pending, (state, action) => {
            state.isPostLoading = true;
        });
        builder.addCase(PartenerReportPostAPI.fulfilled, (state, action) => {
            state.CropNametableData = action.payload;
            state.isPostLoading = false;
        });
        builder.addCase(PartenerReportPostAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isPostLoading = false;
            state.isError = true;
            state.CropNametableData = null;
            // toastErrorr('Something went wrong')
        });
    },
})

export const PartenerReportPostReducer = PartenerReportPostSlice.reducer

export const PartenerReportPatchAPI = createAsyncThunk("PartenerReportPatch", async ({ data }) => {
    const {
        M_KnowledgeRepository_ReportID,
        ActionID,
        M_UserID,
        token,
        handleIsPost,
        handleAddCloseClick,
    } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var formdata = new FormData();
    formdata.append("M_KnowledgeRepository_ReportID", M_KnowledgeRepository_ReportID);
    formdata.append("ActionID", ActionID);
    formdata.append("M_UserID", M_UserID);

    var requestOptions = {
        method: 'PATCH',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
    };

    fetch(`${BaseUrl}/Master/Admin_M_KnowledgeRepository_Report_Action`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.status) {
                handleIsPost()
                handleAddCloseClick()
                // handleClear()
                toastSuccesss(result.message)
            } else {
                toastErrorr(result.message)
            }
            return result

        })
})

const PartenerReportPatchSlice = createSlice({
    name: "PartenerReportPatch",
    initialState: {
        isLoading: false,
        CropNametableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(PartenerReportPatchAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(PartenerReportPatchAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.CropNametableData = action.payload;
        });
        builder.addCase(PartenerReportPatchAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.CropNametableData = null;
            // toastErrorr('Something went wrong')
        });
    },
})

export const PartenerReportPatchReducer = PartenerReportPatchSlice.reducer

// Delete api

export const PartenerReportDeleteAPI = createAsyncThunk("PartenerReportDelete", async ({ data }) => {
    const {
        M_KnowledgeRepository_ReportID,
        M_UserID,
        token,
        handleIsPost,
        handleAddCloseClick,
        setIsDeleteLoading
    } = data
    setIsDeleteLoading(true)
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var formdata = new FormData();
    formdata.append("M_KnowledgeRepository_ReportID", M_KnowledgeRepository_ReportID);
    formdata.append("M_UserID", M_UserID);

    var requestOptions = {
        method: 'DELETE',
        body: formdata,
        redirect: 'follow'
    };

    fetch(`${BaseUrl}/Master/M_KnowledgeRepository_Report_Delete`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.status) {
                handleIsPost()
                handleAddCloseClick()
                // handleClear()
                toastSuccesss(result.message)
            } else {
                toastErrorr(result.message)
            }
            setIsDeleteLoading(false)
            return result

        })
})

const PartenerReportDeleteSlice = createSlice({
    name: "PartenerReportDelete",
    initialState: {
        isLoading: false,
        CropNametableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(PartenerReportDeleteAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(PartenerReportDeleteAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.CropNametableData = action.payload;
        });
        builder.addCase(PartenerReportDeleteAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.CropNametableData = null;
            // toastErrorr('Something went wrong')
        });
    },
})

export const PartenerReportDeleteReducer = PartenerReportDeleteSlice.reducer



