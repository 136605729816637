
import React, { useEffect, useState } from 'react'
import AdminHeader from '../../../../../Components/Header/AdminHeader'
import Sidebar from '../../../../../Components/Sidebar/Sidebar'
import FAB from '../../../../../Components/FAB/FAB'
import { CommonDeletePopUp } from '../KnowledgeRepositoryReport/Action/CommonDeletePopUp'
import { PartnerNameDDL } from '../../../../../Components/DDL/PartnerNameDDL/PartnerNameDDL'
import { PublishReportPopUp } from '../KnowledgeRepositoryReport/Action/PublishReportPopUp'
import { NewsLetterDeleteAPI, NewsLetterTableDataAPI } from '../../../../../Redux/AdminSlice/NewsLetterAdminSlice/NewsLetterAdminSlice'
import { useAuthState } from '../../../../../Helper/Context'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import { Loading } from '../../../../../Helper/Loading'
import { LogoLoader } from '../../../../../Helper/LogoLoader'
import { EditorState } from 'draft-js'
import { Editor } from 'react-draft-wysiwyg'
import 'draft-js/dist/Draft.css'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
export const DiscussionForumForm = () => {

    const userDetails = useAuthState()
    const { RoleID, UserID, token } = userDetails
    const dispatch = useDispatch()

    const [Heading, setHeading] = useState('')
    const [showAddPopUp, setshowAddPopUp] = useState(false)
    const [UploadDate, setUploadDate] = useState('')
    const [PublishPopUp, setPublishPopUp] = useState(false)
    const [DeletePopUp, setDeletePopUp] = useState(false)
    const [IsPost, setIsPost] = useState(false)
    const [editData, setEditData] = useState('')
    const [loading, setloading] = useState(false)


    // editor

    const [JobDescription, setJobDescription] = React.useState('')
    const [ConvertedContent, setConvertedContent] = React.useState('')

    const handleEditorChange = (state) => {
        setJobDescription(state);
        // convertContentToHTML(state);
    }
    // const convertContentToHTML = (state) => {
    //     let currentContentAsHTML = convertContentToHTML(state.getCurrentContent());
    //     setConvertedContent(currentContentAsHTML);
    // }

    // ----------pagination-------------
    const [PerPageCount, setPerPageCount] = useState(10)
    const [TotalCount, setTotalCount] = useState(0)
    const [To, setTo] = useState(10)
    const [From, setFrom] = useState(1)
    const [rowNo, setrowNo] = useState(1)
    const [CurrentPage, setCurrentPage] = useState(0)

    const [partnerNameDDL, setpartnerNameDDL] = useState({
        DDL: [],
        ID: 0,
        Label: 'Select...'
    })

    const PublishButtonClick = (heading, id) => {
        setPublishPopUp(true)
        setEditData()
        setHeading(heading)
    }

    const handleDeletePopUp = (item) => {
        setDeletePopUp(true)
        setEditData(item)
    }
    const handlePublishCloseClick = () => {
        setPublishPopUp(false)
        setDeletePopUp(false)
    }
    const handleIsPost = () => {
        setIsPost(!IsPost)
    }
    const handleOnAddClick = () => {
        setshowAddPopUp(!showAddPopUp)
    }

    const handleClear = () => {
        setpartnerNameDDL({
            ...partnerNameDDL,
            ID: 0,
            Label: 'Select...'
        })
        setUploadDate('')
    }

    const handleShowPdf = (PDFUrl) => {
        if (PDFUrl) {
            window.open(PDFUrl)
        }
    }

    const handleDelete = () => {
        const data = {
            M_NewsLetterID: editData.m_NewsLetterID,
            M_UserID: UserID,
            token: token,
            handleIsPost: handleIsPost,
            handleAddCloseClick: handlePublishCloseClick,
            setloading: setloading
        }
        dispatch(NewsLetterDeleteAPI({ data }))
    }

    useEffect(() => {
        const data = {
            M_NewsLetterID: partnerNameDDL.ID,
            MediaDate: UploadDate,
            M_UserID: UserID,
            From: From,
            TOP: '9999',
            token: token,
            setTotalCount: setTotalCount
        }
        dispatch(NewsLetterTableDataAPI({ data }))
    }, [IsPost, UploadDate, partnerNameDDL.ID, To])

    const { tableData, isLoading } = useSelector(state => state.NewsLetterTableData)


    return (
        <>
            {isLoading && <LogoLoader />}
            <AdminHeader />
            {/* <Sidebar active="dashboard" /> */}
            <div class="row flex-nowrap gr wrapper">
                <Sidebar active='DiscussionForumForm' />
                <div class="col py-3 text-dark" id='content'>
                    <div class="page-content">
                        <span className='d-flex justify-content-between align-items-center mx-3'>
                            <h5 class=" fw-bold my-3 "> Discussion Forum </h5>
                        </span>

                        <div class="row py-4 bg-light px-3">

                            <div className="col-12 col-md-9 col-lg-10 mx-auto rounded px-3 py-2 bg-white" style={{ boxShadow: 'rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset' }}>
                                <div className="form-group">
                                    <label className='fw-bold pb-2'>Create New Subject <span style={{ color: "red" }}> *</span></label>
                                    <Editor
                                        editorState={JobDescription}
                                        onEditorStateChange={editorState => {
                                            setJobDescription(editorState);
                                            handleEditorChange(editorState);
                                        }}
                                        wrapperClassName="wrapper-class"
                                        editorClassName="editor-class"
                                        toolbarClassName="toolbar-class"
                                        editorStyle={{ border: "1px solid", height: '200px' }}
                                    />

                                    <div className=' mt-4 '>
                                
                                        <input type="file" id="file" style={{ display: "none" }}
                                            onChange={(e) => this.onChangeFile(e)} />
                                        <label htmlFor="file" >
                                            <span className='fa fa-image fs-2 img-hover text-success' title="Add Image"> </span>
                                        </label>

                                        
                                        <button type="button" className="clear_button mb-2 px-4 py-2 float-end"
                                            onClick={handleClear}
                                        > <i class="fa-solid fa-save pe-1"></i> Submit</button>

                                    </div>
                                </div>
                            </div>

                            {/* {tableData && tableData.table && tableData.table.length > 0 &&
                                <Pegination
                                    PerPageCount={PerPageCount}
                                    TotalCount={tableData.table[0].totalCount}
                                    setFrom={setFrom}
                                    setTo={setTo}
                                    setrowNo={setrowNo}
                                    CurrentPage={CurrentPage}
                                    setCurrentPage={setCurrentPage}
                                />
                            } */}
                        </div>
                    </div>
                </div>
            </div>
            {/* <FAB toggleState={showAddPopUp} settoggleState={handleOnAddClick} /> */}

            {
                PublishPopUp && <PublishReportPopUp
                    open={PublishPopUp}
                    heading={Heading}
                    Lable='publish News'
                    handleAddCloseClick={handlePublishCloseClick}
                // handlePublish={handlePublish}
                />
            }
            {
                DeletePopUp && <CommonDeletePopUp
                    open={DeletePopUp}
                    heading={Heading}
                    Lable='News'
                    handleAddCloseClick={handlePublishCloseClick}
                    handleDelete={handleDelete}
                    IsDeleteLoading={loading}
                />
            }
        </>
    )
}
