import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useAuthState } from "../../../../../Helper/Context"
import { KYCFormPopUpTableDataAPI } from "../../../../../Redux/AdminSlice/PartenerApprovalSlice/PartenerApprovalSlice"
import { Loading } from "../../../../../Helper/Loading"
import { toastErrorr } from "../../../../../Helper/ToastMessage"

export const KYCFromViewModal = (props) => {
    const { handleKYCShowModal, GridData } = props

    const userDetails = useAuthState()
    const { RoleID, UserID, token } = userDetails
    const dispatch = useDispatch()

    const [KYCData, setKYCData] = useState([])

    const handleEyeClick = (data) => {
        if (data) {
            window.open(data)
        } else {
            toastErrorr('Photo not available')
        }

        // const newWindow = window.open("", "_blank");
    };

    useEffect(() => {
        const data = {
            M_RegistrationID: GridData?.m_RegistrationID,
            M_UserID: UserID,
            From: '1',
            To: '99999',
            token: token,
            setKYCData: setKYCData
        }
        dispatch(KYCFormPopUpTableDataAPI({ data }))
    }, [])

    const { tableData, isLoading } = useSelector(state => state.KYCFormPopUpTableData)

    return (
        <div className="modal show fade px-lg-5" id="exampleModal"
            style={{ display: "block", backgroundColor: "#00000055", overflow: 'auto' }}
             aria-labelledby="exampleModalLabel"
            aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered" >
                <div className="modal-content" style={{ height: '90vh' }}>
                    <div className="modal-header px-lg-5 bg-white">
                        <h5 className="modal-title lightgreencolor " id="exampleModalLabel">
                            Registration
                        </h5>
                        <button type="button" className=" bg-danger text-white px-2 border-0 rounded fs-4"
                            data-dismiss="modal" data-bs-dismiss="modal" aria-label="Close"
                            onClick={handleKYCShowModal}
                        > &times; </button>
                    </div>
                    {isLoading && <Loading />}
                    <div className="modal-body px-5 whats_new"
                    // style={{ height: '600px', overflowY: 'scroll' }}
                    >

                        <form>
                            <div className="row pt-4">
                                <div className="col-12">
                                    {GridData?.userType_M_IndicatorID == 6 &&
                                        <div className="pt-3 col-12">
                                            <h6>Individual Form and KYC</h6>
                                            <div className="border p-4 ">

                                                <div className="row py-2 ">
                                                    <div className="col-5 mb-1 ">
                                                        <label for="exampleFormControlInput1" className="form-label pe-2 text-black font-weight-bold">
                                                            Country/Region
                                                        </label>
                                                    </div>
                                                    <div className="col-1 mb-1">:</div>
                                                    <div className="col-6 mb-1">
                                                        {KYCData ? KYCData && KYCData[0]?.individual_Country : "-"}

                                                    </div>
                                                </div>

                                                <div className="row py-2 ">
                                                    <div className="col-5 mb-1 ">
                                                        <label for="exampleFormControlInput1" className="form-label pe-2 text-black font-weight-bold">
                                                            PROOF OF IDENTITY
                                                        </label>
                                                    </div>
                                                    <div className="col-1 mb-1">:</div>
                                                    <div className="col-6 mb-1">
                                                        <span
                                                            onClick={() => handleEyeClick(KYCData ? KYCData[0]?.individual_Proof_of_Identity : "-")}

                                                            style={{ cursor: "pointer" }}
                                                        >
                                                            <i className="fas fa-eye"></i>
                                                        </span>
                                                    </div>
                                                </div>

                                                <div className="row py-2 ">
                                                    <div className="col-5 mb-1 ">
                                                        <label for="exampleFormControlInput1" className="form-label pe-2 text-black font-weight-bold">
                                                            PROOF OF ADDRESS
                                                        </label>
                                                    </div>
                                                    <div className="col-1 mb-1">:</div>
                                                    <div className="col-6 mb-1">
                                                        <span
                                                            onClick={() => handleEyeClick(KYCData ? KYCData[0]?.individual_Proof_of_Address : "-")}
                                                            className=" p-0"
                                                            style={{ cursor: "pointer" }}
                                                        >
                                                            <i className="fas fa-eye"></i>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }

                                    {GridData?.userType_M_IndicatorID == 7 &&
                                        <div className="col-12">
                                            <h6>Corporate Form and KYC (Private Organization)</h6>
                                            <div className="border p-4">

                                                <div className="row py-2 ">
                                                    <div className="col-5 mb-1 ">
                                                        <label for="exampleFormControlInput1" className="form-label pe-2 text-black font-weight-bold">
                                                            Country/Region
                                                        </label>
                                                    </div>
                                                    <div className="col-1 mb-1">:</div>
                                                    <div className="col-6 mb-1">
                                                        {KYCData ? KYCData && KYCData[0]?.corporate_Country : "-"}

                                                    </div>
                                                </div>

                                                <div className="row py-2 ">
                                                    <div className="col-5 mb-1 ">
                                                        <label for="exampleFormControlInput1" className="form-label pe-2 text-black font-weight-bold">
                                                            Nodal Officer
                                                        </label>
                                                    </div>
                                                    <div className="col-1 mb-1">:</div>
                                                    <div className="col-6 mb-1">
                                                        {KYCData ? KYCData && KYCData[0]?.corporate_NodalOfficer : "-"}

                                                    </div>
                                                </div>

                                                <div className="row py-2 ">
                                                    <div className="col-5 mb-1 ">
                                                        <label for="exampleFormControlInput1" className="form-label pe-2 text-black font-weight-bold">
                                                            Website Link
                                                        </label>
                                                    </div>
                                                    <div className="col-1 mb-1">:</div>
                                                    <div className="col-6 mb-1">
                                                        {KYCData ? KYCData && KYCData[0]?.corporate_WebsiteLink : "-"}

                                                    </div>
                                                </div>
                                                <div className="row py-2 ">
                                                    <div className="col-5 mb-1 ">
                                                        <label for="exampleFormControlInput1" className="form-label pe-2 text-black font-weight-bold">
                                                            Copy of Certificate of incorporation
                                                        </label>
                                                    </div>
                                                    <div className="col-1 mb-1">:</div>
                                                    <div className="col-6 mb-1">
                                                        <span
                                                            type="button"
                                                            onClick={() => handleEyeClick(KYCData ? KYCData[0]?.corporate_Certificate_of_Incorporation : "-")}
                                                            className="btn p-0"
                                                            style={{ cursor: "pointer" }}
                                                        >
                                                            <i className="fas fa-eye"></i>
                                                        </span>
                                                    </div>
                                                </div>

                                                <div className="row py-2 ">
                                                    <div className="col-5 mb-1 ">
                                                        <label for="exampleFormControlInput1" className="form-label pe-2 text-black font-weight-bold">
                                                            Copy of Memorandum & Articles of Association
                                                        </label>
                                                    </div>
                                                    <div className="col-1 mb-1">:</div>
                                                    <div className="col-6 mb-1">
                                                        <span
                                                            type="button"
                                                            onClick={() => handleEyeClick(KYCData ? KYCData[0]?.corporate_MemorandumArticles : "-")}
                                                            className="p-0"
                                                            style={{ cursor: "pointer" }}
                                                        >
                                                            <i className="fas fa-eye"></i>
                                                        </span>
                                                    </div>
                                                </div>

                                                <div className="row py-2 ">
                                                    <div className="col-5 mb-1 ">
                                                        <label for="exampleFormControlInput1" className="form-label pe-2 text-black font-weight-bold">
                                                            Copy of Tax Identification Number of the Company
                                                        </label>
                                                    </div>
                                                    <div className="col-1 mb-1">:</div>
                                                    <div className="col-6 mb-1">
                                                        <span
                                                            type="button"
                                                            onClick={() => handleEyeClick(KYCData ? KYCData[0]?.corporate_TaxIdentification : "-")}
                                                            className="p-0"
                                                            style={{ cursor: "pointer" }}
                                                        >
                                                            <i className="fas fa-eye"></i>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }

                                    {GridData?.userType_M_IndicatorID == 8 &&
                                        <div className="col-12">
                                            <h6>Government Entity (KYC)</h6>
                                            <div className="border p-4">

                                                <div className="row py-2 ">
                                                    <div className="col-5 mb-1 ">
                                                        <label for="exampleFormControlInput1" className="form-label pe-2 text-black font-weight-bold">
                                                            Country/Region
                                                        </label>
                                                    </div>
                                                    <div className="col-1 mb-1">:</div>
                                                    <div className="col-6 mb-1">
                                                        {KYCData ? KYCData && KYCData[0]?.government_Country : "-"}

                                                    </div>
                                                </div>
                                                <div className="row py-2 ">
                                                    <div className="col-5 mb-1 ">
                                                        <label for="exampleFormControlInput1" className="form-label pe-2 text-black font-weight-bold">
                                                            Website Link
                                                        </label>
                                                    </div>
                                                    <div className="col-1 mb-1">:</div>
                                                    <div className="col-6 mb-1">
                                                        {KYCData ? KYCData && KYCData[0]?.government_WebsiteLink : "-"}

                                                    </div>
                                                </div>

                                                <div className="row py-2 ">
                                                    <div className="col-5 mb-1 ">
                                                        <label for="exampleFormControlInput1" className="form-label pe-2 text-black font-weight-bold">
                                                            Legal Entity Information
                                                        </label>
                                                    </div>
                                                    <div className="col-1 mb-1">:</div>
                                                    <div className="col-6 mb-1">
                                                        {KYCData ? KYCData && KYCData[0]?.government_LegalEntityInfo : "-"}

                                                    </div>
                                                </div>

                                                <div className="row py-2 ">
                                                    <div className="col-5 mb-1 ">
                                                        <label for="exampleFormControlInput1" className="form-label pe-2 text-black font-weight-bold">
                                                            Authorized Signatories
                                                        </label>
                                                    </div>
                                                    <div className="col-1 mb-1">:</div>
                                                    <div className="col-6 mb-1">
                                                        {KYCData ? KYCData && KYCData[0]?.government_AuthorizedSignatories : "-"}

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}