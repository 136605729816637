
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

export const HomeKnowledge = () => {
    return (
        <div className="row px-4 px-md-5 knowdge_repository d-flex align-items-center justify-content-center">
            <div className="col-12 col-lg-6 ">
                <h2 className="lightgreencolor  py-3 text-center text-lg-start mt-4 mt-lg-0">Knowledge Repository</h2>
                <p className="text-dark text-center text-lg-start">Welcome to our Knowledge Repository, your gateway to a wealth of information and
                    resources dedicated
                    to environmental transformation. Explore our extensive collection of reports, case studies, and
                    share your own contributions to foster sustainable change. Together, we empower environmental
                    stewardship and inspire transformative action.</p>
            </div>
            <div className="col-12 col-lg-6 d-flex align-items-center justify-content-center">
            <LazyLoadImage effect='blur' src="https://ik.imagekit.io/gplanet/Gplanet/img/Repository/Knowledge%20Repository-001.png?updatedAt=1701510159450" height='100%' width="93%" alt=""/>
                {/* <img src="assets/img/Repository/Knowledge Repository-001.png" width="90%" alt="" /> */}
            </div>
        </div>
    )
}