
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { WorldMap } from '../../../../WorldMap/WorldMap';

export const HomePartnerMap = () => {
    return (
        <div className="row gray_bg">
            <div className="col-12 text-center py-5">
                <h2 className="lightgreencolor">Active Participant Country In Global Green Credit Initiative</h2>
            </div>
            <div className="col-12 ">
            {/* <div className="mb-3 bg-white py-4 mx-auto"> */}

                {/* <WorldMap /> */}
                <LazyLoadImage effect='blur' src="https://ik.imagekit.io/gplanet/Gplanet/img/india-01.jpg?updatedAt=1701510155627" alt="About" width="100%" height='100%'/>

                {/* <img src="assets/img/india-01.jpg" alt="Map" style={{ width: '100%' }} /> */}
            {/* </div> */}
            </div>
        </div>
    )
}