import { useNavigate } from "react-router-dom"


export const NotFound = () => {
    const navigate = useNavigate()
    return (
        <div className="container-fluid gray_bg " style={{ height: '100vh' }}>
            <a onClick={() => navigate('/')} className="cursor-pointer">
                <p className="p-lg-3 px-3 pt-3 text-center text-lg-start">
                    <img src="assets/img/GCP_logo.png" alt="" width="130px"
                        className="bg-white rounded p-1" />
                </p>
            </a>

            <div className="row  d-flex justify-content-center align-items-center">

                <img src="https://ik.imagekit.io/gplanet/Gplanet/img/404-01.png?updatedAt=1701510153687" alt="" className="img_404" />

                <p className=" text-center pt-5 ">
                    <a onClick={() => navigate('/')} className="bg-danger px-5 cursor-pointer py-3 text-white rounded border">
                        Go Home
                    </a>
                </p>

            </div>

        </div>
    )
}