

import { useEffect } from 'react'
import Select from 'react-select'
import { useDispatch, useSelector } from 'react-redux'
import { EventSubTypeDDLAPI, EventTypeDDLAPI } from '../../../Redux/DDLSlice'
import { AstricSign } from '../../../Helper/AstricSign/AstricSign'


export const EventSubTypeDDL = (props) => {
    const { eventSubTypeDDL, seteventSubTypeDDL, apiFlag, rowData, IsPost ,Astric} = props

    const dispatch = useDispatch()

    const UserID = sessionStorage.getItem('User')
    const access_token = sessionStorage.getItem('NMCToken')

    useEffect(() => {
        const data = {
            userID: UserID,
            access_token: access_token
        }
        dispatch(EventSubTypeDDLAPI({ data }))
    }, [])

    const { DDLData } = useSelector(state => state.EventSubTypeDDLData)

    useEffect(() => {
        handleDDL()
    }, [DDLData])

    const handleDDL = () => {
        if (DDLData && DDLData.length > 0) {
            let list = DDLData.map((item, index) => ({
                value: item.m_EventSubTypeID,
                label: item.eventSubType,
            }))

            seteventSubTypeDDL({
                DDL: list,
                ID: apiFlag == 'Update' ? rowData?.m_EventSubTypeID : 0,
                Label: apiFlag == 'Update' ? rowData?.eventSubType : "Select...",

            })
        }
        else {
            seteventSubTypeDDL({
                DDL: [],
                ID: 0,
                Label: "Select...",
            })
        }
    }

    return (
        <div className="form-group">
            <label className="d-block" htmlFor="NameofDepartment">Event Sub Type{Astric && <AstricSign/>}</label>
            <Select
                isClearable
                // isRtl={isRtl}
                isSearchable
                maxMenuHeight={150}
                value={{ value: eventSubTypeDDL.ID, label: eventSubTypeDDL.Label }}
                onChange={(e) => {
                    e ?
                        seteventSubTypeDDL({ ...eventSubTypeDDL, ID: e.value, Label: e.label })
                        :
                        seteventSubTypeDDL({ ...eventSubTypeDDL, ID: 0, Label: "Select..." })

                }}
                options={eventSubTypeDDL.DDL}
            />
        </div>
    )
}