import {
    BrowserRouter as Router,
    Routes,
    Route,
    Outlet,
    useLocation,
    Navigate,
} from "react-router-dom";
import { useAuthState } from "./Helper/Context";
import Home from "./Pages/Private/DashboardPages/Home/Home";
import GPlaNET from "./Pages/Private/DashboardPages/About/GPlaNET/GPlaNET";
import GCP from "./Pages/Private/DashboardPages/About/GCPgplanet.js/GCP";
import Life from "./Pages/Private/DashboardPages/About/Life/Life";
import Reports from "./Pages/Private/DashboardPages/KnowledgeRepository/Reports/Reports";
import CaseStudy from "./Pages/Private/DashboardPages/KnowledgeRepository/CaseStudy/CaseStudy";
import Photos from "./Pages/Private/DashboardPages/Multimedia/Photos/Photos";
import Videos from "./Pages/Private/DashboardPages/Multimedia/VideosM/Videos";
import News from "./Pages/Private/DashboardPages/Multimedia/News/News";
import NewsLetter from "./Pages/Private/DashboardPages/Multimedia/NewsLetter/NewsLetter";
import AdminHeader from "./Components/Header/AdminHeader";
import Admin from "./Pages/Private/DashboardPages/Admin/Admin";
import PledgeParticipant from "./Pages/Private/DashboardPages/Admin/PledgeParticipant/PledgeParticipant";
import QueryScreen from "./Pages/Private/DashboardPages/Admin/QueryScreen/QueryScreen";
import { KnowledgeRepositoryReport } from "./Pages/Private/DashboardPages/Admin/KnowledgeRepositoryReport/KnowledgeRepositoryReport";
import { KnowledgeRepositoryCaseStudy } from "./Pages/Private/DashboardPages/Admin/KnowledgeRepositoryCaseStudy/KnowledgeRepositoryCaseStudy";
import { Event } from "./Pages/Private/DashboardPages/Admin/Event/Event";
import { NewsLetterAdmin } from "./Pages/Private/DashboardPages/Admin/NewsLetter/NewsLetterAdmin";
import { NewsAdmin } from "./Pages/Private/DashboardPages/Admin/NewsAdmin/NewsAdmin";
import { LoginPage } from "./Pages/Private/DashboardPages/LoginPage/LoginPage";
import { Participants } from "./Pages/Private/JoinThePledge/Participants";
import { WorldMap } from "./Pages/Private/JoinThePledge/WorldMap";
import { FAQ } from "./Pages/Private/Resources/FAQ";
import { ContactUS } from "./Pages/Private/ContactUs/ContactUs";
import { CommingSoon } from "./Pages/Private/CommingSoon/CommingSoon";
import { PastEvent } from "./Pages/Private/PastEvent/PastEvent";
import { TermsConditions } from "./Pages/Private/TermsConditions/TermsConditions";
import { EventGallery } from "./Pages/Private/DashboardPages/Admin/EventGallery/EventGallery";
import ReportDocument from "./Pages/Private/DashboardPages/KnowledgeRepository/ReportDocument/ReportDocument";
import { ForgotPassword } from "./Pages/Private/DashboardPages/LoginPage/ForgotPassword";
import { OTPScreen } from "./Pages/Private/DashboardPages/LoginPage/OTPScreen";
import { NewPassword } from "./Pages/Private/DashboardPages/LoginPage/NewPassword";
import { PrivacyPolicy } from "./Pages/Private/PrivacyPolicy/PrivacyPolicy";
import { useEffect, useState } from "react";
import { NotFound } from "./Pages/NotFound/NotFound";
import { ErrorBoundary } from 'react-error-boundary'
import { ForgotPassScreen } from "./Pages/Private/DashboardPages/LoginPage/ForgotPassScreen/ForgotPassScreen";
import { DiscussionForum } from "./Pages/Private/Event/DiscussionForum";
import { Discussion } from "./Pages/Private/Event/Discussion";
import { ViewAllSubject } from "./Pages/Private/Event/ViewAllSubject";
import { DiscussionForumForm } from "./Pages/Private/DashboardPages/Admin/DiscussionForum/DiscussionForumForm";

export default function AllRoutes() {

    const [RoleID, setRoleID] = useState(sessionStorage.getItem("A454A"))

    const RequiredAuth = () => {
        const userDetails = useAuthState()
        const { RoleID } = userDetails
        let Auth = sessionStorage.getItem("A454A")
        setRoleID(sessionStorage.getItem("A454A"))
        const location = useLocation()
        if (!Auth) {
            return <Navigate to="/" state={{ from: location }} />
        }
        return <Outlet />
    }

    useEffect(() => {
        setRoleID(sessionStorage.getItem("A454A"))
    }, [RoleID])

    return (
        <>
            <ErrorBoundary FallbackComponent={NotFound}>
                <Router >
                    <Routes>

                        <Route exact path="/" element={<Home />} />
                        <Route exact path="/login" element={<LoginPage />} />
                        <Route path="/Participants" element={<Participants />} />
                        <Route path="/WorldMap" element={<WorldMap />} />
                        <Route path="/faq" element={<FAQ />} />
                        <Route path="/contactus" element={<ContactUS />} />
                        <Route path="/Commingsoon" element={<CommingSoon />} />
                        <Route path="/reportDocument" element={<ReportDocument />} />
                        <Route path="/forgotPassword" element={<ForgotPassScreen />} />
                        <Route path="/OTPScreen" element={<OTPScreen />} />
                        <Route path="/NewPassword" element={<NewPassword />} />
                        <Route path="/GPlaNET" element={<GPlaNET />} />
                        <Route path="/gcp" element={<GCP />} />
                        <Route path="/life" element={<Life />} />
                        <Route path="/reports" element={<Reports />} />
                        <Route path="/casestudy" element={<CaseStudy />} />
                        <Route path="/photos" element={<Photos />} />
                        <Route path="/PastEvent" element={<PastEvent />} />
                        <Route path="/videos" element={<Videos />} />
                        <Route path="/news" element={<News />} />
                        <Route path="/newsletter" element={<NewsLetter />} />
                        <Route path="/termscondition" element={<TermsConditions />} />
                        <Route path="/privacypolicy" element={<PrivacyPolicy />} />
                        <Route path="/notfound" element={<NotFound />} />
                        <Route path="*" element={<NotFound />} />
                        <Route path="/DiscussionForum" element={<DiscussionForum />} />
                        <Route path="/Discussion" element={<Discussion />} />
                        <Route path="/ViewAllSubject" element={<ViewAllSubject />} />
                        
                        <Route exact element={<RequiredAuth />}>

                            <Route path="/admin" element={RoleID == '1' ? <Admin /> : <NotFound />} />
                            <Route path="/queryScreen" element={RoleID == '1' ? <QueryScreen /> : <NotFound />} />
                            <Route path="/knowledgeRepositoryReport" element={(RoleID == '1' || RoleID == '2') ? <KnowledgeRepositoryReport /> : <NotFound />} />
                            <Route path="/knowledgeRepositoryCaseStudy" element={(RoleID == '1' || RoleID == '2') ? <KnowledgeRepositoryCaseStudy /> : <NotFound />} />
                            <Route path="/event" element={(RoleID == '1' || RoleID == '2') ? <Event /> : <NotFound />} />
                            <Route path="/eventgallery" element={(RoleID == '1' || RoleID == '2') ? <EventGallery /> : <NotFound />} />

                            <Route path="/pledgeParticipant" element={<PledgeParticipant />} />
                            <Route path="/newsLetterAdmin" element={<NewsLetterAdmin />} />
                            <Route path="/newsAdmin" element={<NewsAdmin />} />
                            <Route path="/DiscussionForumForm" element={<DiscussionForumForm />} />
                        </Route>

                    </Routes>
                </Router>

            </ErrorBoundary>
        </>
    )

}

