import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BaseUrl } from "../../Helper/BaseURL";
import { toastErrorr } from "../../Helper/ToastMessage";

export const VerifyOTPAPI = createAsyncThunk("VerifyOTP", async ({ data }) => {
    const {OTP,setMessage,handleNext } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " );

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    
    return fetch(`${BaseUrl}/Master/Get_M_PartnerRegistration_OTP_Check_Select?OTP_NUM=${OTP}`, requestOptions)

        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 ) {
                setMessage(result.message)
                handleNext('Password',result.message)
                return result.message
            } else {
                setMessage(result.message)
                return result.message
            }
        })
})

const VerifyOTPAPISlice = createSlice({
    name: "VerifyOTP",
    initialState: {
        isLoading: false,
        Data: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(VerifyOTPAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(VerifyOTPAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.Data = action.payload;
        });
        builder.addCase(VerifyOTPAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.Data = null;
            toastErrorr('Something went wrong')
        });
    },
})
export const VerifyOTPDataReducer = VerifyOTPAPISlice.reducer


