

import React from 'react'
import Header from '../../../Components/Header/Header'
import Footer from '../../../Components/Footer/Footer'
import { PagesHeroSection } from '../../../Components/PagesHeroSection/PagesHeroSection'


export const TermsConditions = () => {
    return (
        <>
            <Header />
            <div className="row" id="herosection">

                <PagesHeroSection
                    Heading='Terms and Conditions'
                    BreadScrum1='Home'
                    BreadScrum2='Terms and Conditions'
                />

                {/* <div className="col-12 bg_img d-flex  align-items-center">
                    <div className=" py-5 px-4 text-white">
                        <nav style={{ bsBreadcrumbdivider: '>' }} aria-label="breadcrumb">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item active "><a href="#" class="text-white">Home</a></li><span class="mx-2">/</span>
                                <li class="breadcrumb-item active " aria-current="page"><a href="#" class="text-white">Terms and Conditions</a></li>

                            </ol>
                        </nav>
                    </div>

                </div> */}
            </div>
            <div class="row" id="privacy data">
                <div class="col-11 text-center pt-2 mx-auto">
                    <h2 class="mt-3 lightgreencolor fw-bold text-start">Global Green Credit Initiative Website Terms and Conditions</h2>
                    <hr class="mx-start lightgreencolor" style={{ width: '200px' }} />

                </div>


                <div class="col-11 pb-4 mx-auto text-start">
                    <h5 class=" my-2 fw-bold">Acceptance of Terms</h5>
                    <p>By accessing or using the GGCI website, you agree to comply with and be bound by these terms and conditions.</p>

                    <h5 class=" my-4 fw-bold">Changes to Terms</h5>
                    <p>GGCI reserves the right to modify or revise these terms at any time. Your continued use of the website after any changes constitutes acceptance of those changes.</p>

                    <h5 class=" my-4 fw-bold">Privacy Policy</h5>
                    <p>Please refer to our Privacy Policy for information on how we collect, use, and disclose personal information.</p>

                    <h5 class=" my-4 fw-bold">User Conduct</h5>
                    <p>Users shall not engage in any activity that disrupts or interferes with the functionality of the website.</p>

                    <p>Respect the rights of other users and refrain from any harmful or unlawful activities.</p>

                    <h5 class=" my-4 fw-bold">Intellectual Property</h5>
                    <p>All content on the GGCI website, including text, graphics, logos, and images, is the property of GGCI and is protected by intellectual property laws.</p>

                    <h5 class=" my-4 fw-bold">External Links</h5>
                    <p>The website may contain links to third-party websites. GGCI is not responsible for the content or practices of these external sites.</p>

                    <h5 class=" my-4 fw-bold">Participation and Contributions</h5>
                    <p>Users participating in dialogues, events, or submitting content to the GGCI Portal agree to abide by the established guidelines and contribute constructively.</p>

                    <h5 class=" my-4 fw-bold">Liabilities</h5>
                    <p>GGCI is not liable for any damages or losses resulting from the use of the website or the information provided on it.</p>

                    <h5 class=" my-4 fw-bold">Governing Law</h5>
                    <p>These terms and conditions are governed by and construed in accordance with the laws of MoEFCC.</p>

                    <h5 class=" my-4 fw-bold"> Termination</h5>
                    <p>GGCI reserves the right to terminate or suspend access to the website for any user who violates these terms.</p>

                    <h5 class=" my-4 fw-bold">Contact Information</h5>
                    <p>For any inquiries or concerns regarding these terms, please contact Ms. Bhawna Singh, Bhawna.singh@gov.in.</p>

                    <h5 class=" my-4 fw-bold">Agreement</h5>
                    <p>By using the GGCI website, you acknowledge that you have read, understood, and agreed to these terms and conditions.</p>

                    <h5 class=" my-4 fw-bold">Effective Date</h5>
                    <p>These terms and conditions are effective as of 01–12-2023.</p>



                </div>
            </div>
            <Footer />
        </>
    )
}
