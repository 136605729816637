

import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Select from 'react-select'
import { useAuthState } from '../../../Helper/Context'
import { UpdatedCountryDDLAPI } from '../../../Redux/DDLSlice'



export const UpdatedCountryDDL = (props) => {
    const { CountryDDL, setCountryDDL, Flag } = props

    const dispatch = useDispatch()
    const userDetails = useAuthState();
    const { UserID, token } = userDetails

    useEffect(() => {
        const data = { M_UserID: UserID, Flag: Flag, token }
        dispatch(UpdatedCountryDDLAPI({ data }))
    }, [])

    const { CountryDDLData, isLoading } = useSelector(state => state.UpdatedCountryDDLData)

    useEffect(() => {
        handleDDL()
    }, [CountryDDLData])

    const handleDDL = () => {
        if (CountryDDLData && CountryDDLData.length > 0) {
            let list = CountryDDLData.map((item, index) => ({
                value: item.m_CountryNameID,
                label: item.countryName,
            }))

            setCountryDDL({
                DDL: list,
                ID: 0,
                Label: "Select...",

            })
        }
        else {
            setCountryDDL({
                DDL: [],
                ID: 0,
                Label: "Select...",
            })
        }
    }

    return (
        <div className="form-group">
            <label className="d-block" htmlFor="NameofDepartment">Country Name</label>
            <Select
                isClearable
                // isRtl={isRtl}
                isLoading={isLoading}
                isSearchable
                maxMenuHeight={150}
                value={{ value: CountryDDL.ID, label: CountryDDL.Label }}
                onChange={(e) => {
                    e ?
                        setCountryDDL({ ...CountryDDL, ID: e.value, Label: e.label })
                        :
                        setCountryDDL({ ...CountryDDL, ID: 0, Label: "Select..." })

                }}
                options={CountryDDL.DDL}
            />
        </div>
    )
}