
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { toastErrorr, toastSuccesss } from "../../../Helper/ToastMessage";
import { BaseUrl } from "../../../Helper/BaseURL";

// Get Table Data
export const PledgeParticipantTableDataAPI = createAsyncThunk("PledgeParticipantTableData", async ({ data }) => {
    const {
        M_CountryID,
        M_PartnerTypeID,
        M_StatusID,
        M_UserID,
        token,
        From,
        TOP,
    } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    return fetch(`${BaseUrl}/Master/Get_Admin_M_PledgeRegistration_Select?M_CountryID=${M_CountryID ? M_CountryID : '0'}&M_PartnerTypeID=${M_PartnerTypeID ? M_PartnerTypeID : '0'}&M_StatusID=${M_StatusID ? M_StatusID : '0'}&M_UserID=${M_UserID}&TOP=${TOP}`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                return result.data
            } else {
                return result
            }
        })
})

const PledgeParticipantTableDataSlice = createSlice({
    name: " PledgeParticipantTableData",
    initialState: {
        isLoading: false,
        tableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(PledgeParticipantTableDataAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(PledgeParticipantTableDataAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.tableData = action.payload;
        });
        builder.addCase(PledgeParticipantTableDataAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.tableData = null;
            toastErrorr('Something went wrong')
        });
    },
})

export const PledgeParticipantTableDataReducer = PledgeParticipantTableDataSlice.reducer

// InsertUpdate Table Data

export const PledgeParticipantPostAPI = createAsyncThunk("PledgeParticipantPost", async ({ data }) => {
    const {
        M_PledgeRegistrationID,
        ActionID,
        M_UserID,
        token,
        Flag,
        handlePost,
        handleAddCloseClick,
        handleClear,
        apiFlag
    } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var formdata = new FormData();
    formdata.append("M_PledgeRegistrationID", M_PledgeRegistrationID);
    formdata.append("ActionID",ActionID);
    formdata.append("M_UserID", M_UserID);

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
    };

    fetch(`${BaseUrl}/Master/Post_Admin_M_PledgeRegistration_Action`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.status) {
                handlePost()
                handleAddCloseClick()
                // handleClear()
                toastSuccesss(result.message)
            } else {
                toastErrorr(result.message)
            }
            return result

        })
})

const PledgeParticipantPostSlice = createSlice({
    name: "PledgeParticipantPost",
    initialState: {
        isLoading: false,
        CropNametableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(PledgeParticipantPostAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(PledgeParticipantPostAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.CropNametableData = action.payload;
        });
        builder.addCase(PledgeParticipantPostAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.CropNametableData = null;
            // toastErrorr('Something went wrong')
        });
    },
})

export const PledgeParticipantPostReducer = PledgeParticipantPostSlice.reducer



