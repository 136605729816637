import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BaseUrl } from "../../Helper/BaseURL";
import { toastErrorr } from "../../Helper/ToastMessage";

export const ForgotPasswordAPI = createAsyncThunk("ForgotPassword", async ({ data }) => {
    const {Email,setMessage,handleNext } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " );

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    
    return fetch(`${BaseUrl}/Master/Get_M_PartnerRegistrationEmail_IDCheck_Select?Email_ID=${Email}`, requestOptions)

        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300) {
                setMessage(result.message)
                handleNext('OTP',result.message)
                return result.message
            } else {
                setMessage(result.message)
                return result.message
            }
        })
})

const ForgotPasswordAPISlice = createSlice({
    name: "ForgotPassword",
    initialState: {
        isLoading: false,
        Data: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(ForgotPasswordAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(ForgotPasswordAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.Data = action.payload;
        });
        builder.addCase(ForgotPasswordAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.Data = null;
            toastErrorr('Something went wrong')
        });
    },
})
export const ForgotPasswordReducer = ForgotPasswordAPISlice.reducer


