

import { useEffect } from 'react'
import Select from 'react-select'
import { useDispatch, useSelector } from 'react-redux'
import { PartnerTypeDDLAPI } from '../../../Redux/DDLSlice'
import { useAuthState } from '../../../Helper/Context'


export const PartnerTypeDDL = (props) => {
    const { partnerTypeDDL, setpartnerTypeDDL, apiFlag, rowData, IsPost,label,Label } = props

    const dispatch = useDispatch()
    const userDetails = useAuthState();
    const { UserID, token } = userDetails

    useEffect(() => {
        const data = { UserID, token }
        dispatch(PartnerTypeDDLAPI({ data }))
    }, [])

    const { DDLData } = useSelector(state => state.PartnerTypeDDLData)

    useEffect(() => {
        handleDDL()
    }, [DDLData])

    const handleDDL = () => {
        if (DDLData && DDLData.length > 0) {
            let list = DDLData.map((item, index) => ({
                value: item.m_PartnerTypeID,
                label: item.partnerTypeName,
            }))
            setpartnerTypeDDL({
                DDL: list,
                ID: apiFlag == 'Update' ? rowData?.m_PartnerTypeID : 0,
                Label: apiFlag == 'Update' ? rowData?.partnerTypeName : "Select...",

            })
        }
        else {
            setpartnerTypeDDL({
                DDL: [],
                ID: 0,
                Label: "Select...",
            })
        }
    }

    return (
        <div className="form-group">
            {!label &&<label className="d-block" htmlFor="NameofDepartment">{Label}</label> }
            <Select
                isClearable
                // isRtl={isRtl}
                isSearchable
                maxMenuHeight={150}
                value={{ value: partnerTypeDDL.ID, label: partnerTypeDDL.Label }}
                onChange={(e) => {
                    e ?
                        setpartnerTypeDDL({ ...partnerTypeDDL, ID: e.value, Label: e.label })
                        :
                        setpartnerTypeDDL({ ...partnerTypeDDL, ID: 0, Label: "Select..." })

                }}
                options={partnerTypeDDL.DDL}
            />
        </div>
    )
}