
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BaseUrl } from "../../Helper/BaseURL";
import { toastErrorr } from "../../Helper/ToastMessage";

// Get Table Data

export const TechnicalreportDataAPI = createAsyncThunk("TechnicalreportData", async ({ data }) => {
    const {
        ReportDate,
        Flag,
        M_UserID,
        token,
        From,
        To,
    } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };
    return fetch(`${BaseUrl}/Master/Get_WEB_M_KnowledgeRepository_Report_Select?ReportDate=${ReportDate}&Flag=${Flag}&M_UserID=${M_UserID}&From=${From}&To=${To}`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                return result.data && result.data.table
            } else {
                return result
            }
        })
})

const TechnicalreportDataSlice = createSlice({
    name: "TechnicalreportData",
    initialState: {
        isLoading: false,
        tableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(TechnicalreportDataAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(TechnicalreportDataAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.tableData = action.payload;
        });
        builder.addCase(TechnicalreportDataAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.tableData = null;
            // toastErrorr('Something went wrong')
        });
    },
})

export const TechnicalreportDataReducer = TechnicalreportDataSlice.reducer