import React from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import Bullet from '../../../../../Helper/Bullet'

const HomeGPlanet = () => {
    return (
        <>
            <div>
                <div class="row px-4 pb-2 px-md-5 py-md-1 d-flex align-items-center" id="about_gplanet">
                    <div className='col-12 col-lg-5 py-4 order-2 order-lg-1 text-center text-lg-start mx-auto  '>
                        <LazyLoadImage effect='blur' src="https://ik.imagekit.io/gplanet/Gplanet/img/Gp.jpg?updatedAt=1701510153029" alt="About" style={{ width: "100%" }} className='rounded' />
                        {/* <img src="assets/img/Gp.png" width="80%" alt="" /> */}
                    </div>
                    <div class="col-12 col-lg-7 order-1 order-lg-2 pt-2 pt-lg-0">

                        <h2 class="text-dark text-starts">Global Green Credit Initiative is envisaged to have the following purposes</h2>

                        <div className='d-flex'>
                            <div>
                                <span class="gcp_about_ul">
                                    <Bullet />
                                </span>
                            </div>
                            <div>
                                <span class=" justify fw-bold lightgreencolor textsize">
                                    Science
                                </span>
                                <p class="text-dark" >
                                    <b class="text-dark"> Sharing of global technical know-how and experiences:</b> the science
                                    behind activities contributing to environment conservation can be explored and shared (science
                                    component)
                                </p>
                            </div>
                        </div>


                        <div className='d-flex'>
                            <div>
                                <span class="gcp_about_ul">
                                    <Bullet />

                                </span>
                            </div>
                            <div>
                                <span class=" justify fw-bold lightgreencolor textsize">

                                    Policy

                                </span>
                                <p class="text-dark">
                                    <b class="text-dark"> Shaping green credit policy instruments:</b> Seeks to explore the efficacy
                                    of various policy instruments in incentivizing environment actions (policy component)
                                </p>
                            </div>
                        </div>
                        <div className='d-flex'>
                            <div>
                                <span class="gcp_about_ul">
                                    <Bullet />

                                </span>
                            </div>
                            <div>
                                <span class=" justify fw-bold lightgreencolor textsize">
                                    Practice
                                </span>
                                <p class="text-dark">
                                    <b class="text-dark fw-bolder"> Supporting implementation:</b> Creating one common value chain
                                    connecting communities, self-help groups, farmers/community organizations with
                                    companies/corporates and other stakeholders who can participate in the program through 1
                                    marketplace (practice component)
                                </p>
                            </div>
                        </div>

                    </div>
                </div>
                {/* <!--end of row about gplanet--> */}


                <div className="row px-4 px-md-5 d-flex align-items-center justify-content-center green_credit_bg"
                    id="Dialogue Series">




                    <h2 className="text-start lightgreencolor mt-4 mb-0">Global Green Credit Initiative comprises of several key components</h2>

                    <div className="col-12 col-md-7 pb-4 ">

                        <h2 className="text-dark ">Dialogue Series </h2>
                        <p className="justify">
                            The Dialogue Series invites interested parties, including country governments, international
                            organizations, and think-tanks, to participate in structured dialogues. These dialogues provide a
                            forum for sharing insights, experiences, and ideas related to environmental transformation and
                            sustainability.
                        </p>
                        <p className="justify">
                            The dialogues aim to foster collaboration, identify innovative solutions, and promote best practices
                            for environmental protection and sustainable living.
                        </p>
                    </div>
                    <div className="col-12  col-md-5 credit_img   text-center">
                        <LazyLoadImage className='' effect='blur' src="https://ik.imagekit.io/gplanet/Gplanet/img/g20_flag-01.png?updatedAt=1701510153255" alt="About" height='100%' style={{ width: '60%', borderRadius: '8px' }} />
                        {/* <img src="assets/img/Flag.jpg" className='shadow'  alt="" style={{ width: '50%', borderRadius:'8px' }} /> */}
                    </div>
                </div>
                {/* <!--end of row Dialogue Series--> */}


                {/* <div className="row px-4 px-md-5 d-flex align-items-center justify-content-center green_credit_bg"
                    id="Dialogue Series">
                    <div className="col-12  col-md-7 credit_img pc_none text-center">
                        <img src="assets/img/gplanet/LiFE_Pledge2.jpeg" alt="" style={{ width: '60%' }} />
                    </div>
                    <div className="col-12 col-md-6">
                        <h2 className="lightgreencolor">LiFE Pledge</h2>
                        <p className="justify">
                            Parties are invited to take the LiFE Pledge, a commitment to embrace environmentally conscious
                            lifestyles and actions. The LiFE Pledge not only encourages individuals and organizations to adopt
                            sustainable practices but also enhances the global visibility of Mission LiFE and the Green Credits
                            Programme.
                        </p>

                    </div>

                    <div className="col-12  col-md-5 credit_img text-center mobile_none">
                        <img src="assets/img/gplanet/LiFE_Pledge2.jpeg" alt="" style={{ width: '60%' }} />
                    </div>
                </div> */}
                {/* <!--end of row Dialogue Series--> */}


                <div className="row p-4 p-md-5 d-flex align-items-center justify-content-center"
                    id="Dialogue Series">
                    {/* <!-- <div className="col-12  col-md-6 credit_img " >
                    <img src="img/gplanet/gplanet_component_1.png" alt="" style="width: 100%;">
                </div> --> */}
                    <div className="col-12 col-lg-6 ">
                        <h2 className="lightgreencolor text-center mb-5">Global Green Credit Initiative Portal</h2>
                        <h6 className=" text-center">The Global Green Credit Initiative Portal serves as the digital hub for Global Green Credit Initiative activities. It
                            provides various features and resources, including</h6>


                    </div>
                    <div className="col-12 col-lg-6 Participatory_bg">
                        <div className='d-flex'>
                            <div>
                                <span class="gcp_about_ul">
                                    <Bullet />
                                </span>
                            </div>
                            <div>
                                <span class=" justify fw-bold lightgreencolor textsize">
                                    Participatory Events
                                </span>
                                <p class="text-dark" >
                                    Stakeholders can express their interest in participating in Global Green Credit Initiative activities through the portal.
                                </p>
                            </div>
                        </div>
                        <div className='d-flex'>
                            <div>
                                <span class="gcp_about_ul">
                                    <Bullet />
                                </span>
                            </div>
                            <div>
                                <span class=" justify fw-bold lightgreencolor textsize">
                                    Dialogue Calendar
                                </span>
                                <p class="text-dark" >
                                    The portal hosts a calendar of upcoming dialogues and events.
                                </p>
                            </div>
                        </div>
                        <div className='d-flex'>
                            <div>
                                <span class="gcp_about_ul">
                                    <Bullet />
                                </span>
                            </div>
                            <div>
                                <span class=" justify fw-bold lightgreencolor textsize">
                                    Dialogue Reports
                                </span>
                                <p class="text-dark" >
                                    Reports summarizing the outcomes of dialogues and discussions.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!--end of row Dialogue Series--> */}


                {/* <!-- Types of GC’s start --> */}
                <div className="row p-4 p-md-5 gc_typesimg" id="Benefits">
                    <h2 className="lightgreencolor">Benefits of Global Green Credit Initiative </h2>
                    <p className="text-dark">The Global Platform for Nature's Ecological Transformation offers a range of benefits for participating countries and stakeholders</p>

                    <div className="col-12 col-lg-12 mx-auto py-3 my-0 shadow section_design mt-4">
                        <div className="row text-center text-md-start d-flex align-items-center justify-content-center ">
                            <div className="col-12 col-md-3 col-lg-2 ">
                                <LazyLoadImage className='shadow1 ' effect='blur' src="https://ik.imagekit.io/gplanet/Gplanet/img/gplanet/global_leadership-02-01.png?updatedAt=1701510161594" alt="About" style={{ width: '80%' }} />
                                {/* <img src="assets/img/gplanet/global_leadership-02-01.png" alt="" className='shadow1 w-100' style={{ height: '20vh' }} /> */}
                            </div>
                            <div className="col-12 col-md-9 col-lg-10 pt-3 ">
                                <h5>Global Leadership and Influence</h5>
                                <p className="text-dark pt-2">Participating countries can assert their leadership in environmental stewardship on a global stage, influencing international discussions and commitments.</p>
                            </div>
                        </div>
                    </div>

                    <div className="col-12 col-lg-12 mx-auto py-4 my-0 shadow section_design mt-4">
                        <div className="row text-center text-md-start d-flex align-items-center justify-content-center">
                            <div className="col-12 col-md-3 col-lg-2">
                                <LazyLoadImage className='shadow1' effect='blur' src="https://ik.imagekit.io/gplanet/Gplanet/img/ksnew-01.png?updatedAt=1701510155691" alt="About" style={{ width: '80%' }} />
                                {/* <img src="assets/img/gplanet/ks1-01.png" alt="" className='shadow1 w-100' style={{ height: '20vh' }} /> */}
                            </div>
                            <div className="col-12 col-md-9 col-lg-10 pt-3 ">
                                <h5>Knowledge Sharing</h5>
                                <p className="text-dark pt-2">Global Green Credit Initiative facilitates the sharing of knowledge, experiences, and resources, enabling participants to learn from one another and enhance their environmental initiatives.
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="col-12 col-lg-12 mx-auto py-4 my-0 shadow section_design mt-4">
                        <div className="row text-center text-md-start d-flex align-items-center justify-content-center">
                            <div className="col-12 col-md-3 col-lg-2 ">
                                <LazyLoadImage className='shadow1 ' effect='blur' src="https://ik.imagekit.io/gplanet/Gplanet/img/gplanet/innovation_final_op-01.png?updatedAt=1701510161711" alt="About" style={{ width: '80%' }} />

                                {/* <img src="assets/img/gplanet/innovation_final_op-01.png" alt="" className='shadow1 w-100' style={{ height: '20vh' }} /> */}
                            </div>
                            <div className="col-12 col-md-9 col-lg-10 pt-3 ">
                                <h5>Innovative Solutions</h5>
                                <p className="text-dark pt-2"> By engaging in structured dialogues and collaboration, countries can identify innovative solutions and policy instruments to promote environmental transformation.</p>
                            </div>
                        </div>
                    </div>

                    <div className="col-12 col-lg-12 mx-auto py-4 my-0 shadow section_design mt-4">
                        <div className="row text-center text-md-start d-flex align-items-center justify-content-center">
                            <div className="col-12 col-md-3 col-lg-2">
                                <LazyLoadImage className='shadow1 ' effect='blur' src="https://ik.imagekit.io/gplanet/Gplanet/img/Collective%20Action-01.png?updatedAt=1701510153034" alt="About" style={{ width: '80%' }} />

                                {/* <img src="assets/img/gplanet/collecte-01.png" alt="" className='shadow1 w-100' style={{ height: '20vh' }} /> */}
                            </div>
                            <div className="col-12 col-md-9 col-lg-10 pt-3 ">
                                <h5>Collective Action</h5>
                                <p className="text-dark pt-2">Global Green Credit Initiative fosters opportunities for collective action, enabling countries to work together to address global environmental challenges effectively.</p>
                            </div>
                        </div>
                    </div>

                </div>
                {/* <!-- end of row Benefits--> */}
            </div >

        </>
    )
}

export default HomeGPlanet