import { EventNameDDL } from "../../../../../Components/DDL/EventNameDDL/EventNameDDL"
import { PartnerNameDDL } from "../../../../../Components/DDL/PartnerNameDDL/PartnerNameDDL"
import { PartnerTypeDDL } from "../../../../../Components/DDL/PartnerTypeDDL/PartnerTypeDDL"
import React, { useState } from 'react'
import { useAuthState } from "../../../../../Helper/Context"
import { useDispatch } from "react-redux"
import { EventGalleryPhotoPostAPI } from "../../../../../Redux/AdminSlice/EventGallarySlice/EventGallarySlice"

export const ReplyPopUp = (props) => {
    const { handleShowReplayPopUp, handleIsPost, setReplyText, ReplyText, loading } = props

    const userDetails = useAuthState()


    const [ReplyForm, setReplyForm] = useState({
        Subject: 'About Global Green Credit Initiative Query Regarding.',
        To: 'Dear sir/mam,',
        Quote: 'We really appreciate your inquiry about our Global Green Credit Initiative,',
        Message: '',
        Thanks: 'Thanks & Regards,',
        Name: 'Global Green Credit Initiative Admin.'
    })



    return (
        <div className="modal show fade px-lg-5" id="exampleModal"
            style={{ display: "block", backgroundColor: "#00000055", overflow: 'auto' }}
            tabindex="-1" aria-labelledby="exampleModalLabel"
            aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" style={{ maxWidth: '55%' }}>
                <div class="modal-content" style={{ minHeight: '250px' }}>
                    <div className="modal-header px-lg-5 bg-white">
                        <h5 className="modal-title lightgreencolor" id="exampleModalLabel">Reply
                        </h5>
                        <button type="button" className=" bg-danger text-white px-2 border-0 rounded fs-4"
                            data-bs-dismiss="modal" onClick={handleShowReplayPopUp} aria-label="Close"> &times; </button>
                    </div>
                    <div className="modal-body px-5" style={{ overflowY: 'scroll' }}>
                        <form>
                            <div className='row'>
                                {/* <p className="text-dark pt-2"><b>Subject</b> - {ReplyForm.Subject} </p> */}
                                {/* <text className="text-dark pt-3 "> {ReplyForm.To}</text>
                                <text className="text-dark py-2"> {ReplyForm.Quote}</text> */}
                                <div className="col-12 col-md-12 col-lg-12">
                                    {/* <label for="UploadPhoto" className="form-label">Reply</label> */}
                                    <textarea
                                        type="text"
                                        rows="5"
                                        className="form-control"
                                        id="UploadPhoto"
                                        aria-describedby="emailHelp"
                                        placeholder="Type here..."
                                        onChange={(e) => setReplyText(e.target.value)}
                                    />


                                </div>

                                {/* <text className="text-dark pt-3 fw-bold "> {ReplyForm.Thanks}</text>
                                <text className="text-dark py-2"> {ReplyForm.Name}</text> */}

                            </div>

                            <div className="col-12 col-md-12 col-lg-12 float-end">
                                <p className="float-end my-4">
                                    <button type="button"
                                        disabled={ReplyText == '' || loading}
                                        className="btn lightgreenbg text-white px-4 float-right"
                                        onClick={() => handleIsPost()}
                                    >{loading && (
                                        <i
                                            className="fa fa-spinner fa-spin"
                                            style={{ marginRight: "5px" }}
                                        />
                                    )}
                                        {!loading && <span>Send</span>}
                                    </button>
                                </p>
                            </div>

                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}