import React, { useEffect } from 'react'
import Header from '../../../../../Components/Header/Header'
import Footer from '../../../../../Components/Footer/Footer'
import PartnerCaseStudy from '../PartnerCaseStudy/PartnerCaseStudy'
import { PagesHeroSection } from '../../../../../Components/PagesHeroSection/PagesHeroSection'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { NoData } from '../../../../../Helper/NoData/NoData'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useAuthState } from '../../../../../Helper/Context'
import { TechnicalreportDataAPI } from '../../../../../Redux/TechnicalReportSlice/TechnicalReportSlice'
import moment from 'moment'


const Reports = () => {

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const userDetails = useAuthState()
    const { RoleID, UserID, token } = userDetails

    useEffect(() => {
        const data = {
            ReportDate: '',
            Flag: 'WEB',
            M_UserID: '0',
            token: token,
            From: '1',
            To: '99999',
        }

        dispatch(TechnicalreportDataAPI({ data }))
    }, [])

    const { isLoading, tableData } = useSelector(state => state.TechnicalreportData)
    return (
        <>
            <Header active='Knowledge' />

            <PagesHeroSection
                Heading='Reports'
                BreadScrum1='Home'
                BreadScrum2='Knowledge Repository'
            />

            <div className="row px-3 px-lg-5 py-4">

                {
                    tableData && tableData.length > 0 ? tableData.map(i => {
                        return (
                            <div class="col-12 col-md-6 px-4 mt-1 px-1 px-lg-4">
                                <div class="pt-4">
                                    <LazyLoadImage effect='blur' src="assets/img/pdf.png" alt="About" style={{ width: '25px' }} className="py-2" />
                                    <span class="text-dark ps-2"> {i?.reportSubject}</span>

                                    <p class="py-3 font_size fw-lighter border-bottom pb-3">
                                        <LazyLoadImage effect='blur' src="assets/img/calendar.png" alt="About" style={{ width: '15px' }} className="me-2" />
                                        <span>{i.reportDate ? moment(i.reportDate).format('MMMM DD YYYY') : ''}</span>
                                        <a href={i?.reportDoc} target='_blank' class="float-end text-success fs-6">Read More... <i
                                            class="fa-solid fa-right-to-bracket ps-2"></i></a>
                                    </p>

                                </div>
                            </div>
                        )
                    })
                        :

                        <div class="row">
                            <div className='col-12 col-md-4 mx-auto text-center '>
                                <NoData />
                            </div>
                        </div>
                }

                {/* <div class="row">
                    <div className='col-12 col-md-4 mx-auto text-center '>
                        <NoData />
                    </div>
                </div> */}

            </div>
            {/* <div className="row bg_img" id="herosection">
                <div className="col-12 col-lg-6 d-flex  align-items-center">
                    <div className=" py-5 px-4 text-white">
                        <h2 class="dark-blue mb-3 animated slideInDown lightgreencolor">Reports</h2>
                        <nav style={{ bsBreadcrumbdivider: '>' }} aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item active "><a href="#" className="text-dark">Home</a></li><span
                                    className="mx-2 text-dark">/</span>
                                <li className="breadcrumb-item active " aria-current="page"><a href="#"
                                    className="text-dark">Knowledge Repository</a></li><span className="mx-2 text-dark">/</span>
                                <li className="breadcrumb-item active " aria-current="page"><a href="#"
                                    className="lightgreencolor fw-bold">Reports</a></li>
                            </ol>
                        </nav>
                    </div>

                </div>
                <div className='col-12 col-lg-6 d-flex align-items-center justify-content-end pe-lg-5 '>
                    <img className='heroIMGLogo' src='assets/img/G_PlaNET_logo.png ' />
                </div>
            </div> */}
            {/* <div className="row p-5"> */}
            {/* <div class="col-12">                


            
            <div className="row p-5">
                {/* <div class="col-12">                

                    <h2 class="lightgreencolor">Reports
                        <span class="float-end">
                            <div class="search-box d-flex justify-content-center align-items-center">
                                <input class="search-input" type="text" name="" placeholder="Type Here" />
                                <a href="#" class="search-btn">
                                    <i class="fas fa-search fs-5"></i>
                                </a>
                            </div>
                        </span>
                    </h2>
                </div> */}


            {/* <hr className=" mt-4 mb-4" /> */}

            {/* <hr className=" mt-4 mb-4" /> */}


            {/* <PartnerCaseStudy /> */}

            {/* </div> */}
            <Footer />
        </>
    )
}

export default Reports