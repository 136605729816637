
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BaseUrl } from "../../Helper/BaseURL";
import { toastErrorr } from "../../Helper/ToastMessage";

// Get Table Data
export const PhotovideoEventTableDataAPI = createAsyncThunk("PhotovideoEventTableData", async ({ data }) => {
    const {
        M_EventID,
        Flag,
        M_UserID,
        token,
        From,
        To,
        setGCPUpcoming,
        setloading
    } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };
    return fetch(`${BaseUrl}/Master/Get_WEB_M_Event_Wise_Photo_Video_Select?M_EventID=${M_EventID}&Flag=${Flag}&M_UserID=${M_UserID}&From=${From}&To=${To}`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                {setGCPUpcoming && setGCPUpcoming(result.data && result.data.table)}
    
                return result.data && result.data.table
            } else {
                return result
            }
        })
})

const PhotovideoEventTableDataSlice = createSlice({
    name: "PhotovideoEventTableData",
    initialState: {
        isLoading: false,
        tableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(PhotovideoEventTableDataAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(PhotovideoEventTableDataAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.tableData = action.payload;
        });
        builder.addCase(PhotovideoEventTableDataAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.tableData = null;
            // toastErrorr('Something went wrong')
        });
    },
})

export const PhotovideoEventTableDataReducer = PhotovideoEventTableDataSlice.reducer


export const NewsLetterDataAPI = createAsyncThunk("NewsLetterData", async ({ data }) => {
    const {
        MediaDate,
        Flag,
        M_UserID,
        token,
        From,
        To,
    } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };
    return fetch(`${BaseUrl}/Master/Get_WEB_M_News_Select?MediaDate=${MediaDate}&Flag=${Flag}&M_UserID=${M_UserID}&From=${From}&To=${To}`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                return result.data && result.data.table
            } else {
                return result
            }
        })
})

const NewsLetterDataSlice = createSlice({
    name: "NewsLetterData",
    initialState: {
        isLoading: false,
        tableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(NewsLetterDataAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(NewsLetterDataAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.tableData = action.payload;
        });
        builder.addCase(NewsLetterDataAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.tableData = null;
            // toastErrorr('Something went wrong')
        });
    },
})

export const NewsLetterDataReducer = NewsLetterDataSlice.reducer