import React, { useEffect, useState } from 'react'
import AdminHeader from '../../../../../Components/Header/AdminHeader'
import Sidebar from '../../../../../Components/Sidebar/Sidebar'
import { EventDDL } from '../../../../../Components/DDL/EventDDL/EventDDL'
import { PartnerTypeDDL } from '../../../../../Components/DDL/PartnerTypeDDL/PartnerTypeDDL'
import { useDispatch, useSelector } from 'react-redux'
import { EventTypeDDLAPI } from '../../../../../Redux/DDLSlice'
import { useAuthState } from '../../../../../Helper/Context'
import { EventTypeDDL } from '../../../../../Components/DDL/EventTypeDDL/EventTypeDDL'
import { AddEvent } from './AddEvent'
import FAB from '../../../../../Components/FAB/FAB'
import { EventMasterDeleteAPI, EventTableDataAPI } from '../../../../../Redux/AdminSlice/EventSlice/EventSlice'
import moment from 'moment'
import { Loading } from '../../../../../Helper/Loading'
import { CommonDeletePopUp } from '../KnowledgeRepositoryReport/Action/CommonDeletePopUp'
import { LogoLoader } from '../../../../../Helper/LogoLoader'


export const Event = () => {

    const userDetails = useAuthState()
    const { RoleID, UserID, token } = userDetails

    const dispatch = useDispatch()

    const [EventDate, setEventDate] = useState('')
    const [showAddPopUp, setshowAddPopUp] = useState(false)
    const [IsPost, setIsPost] = useState(false)
    const [TotalCount, setTotalCount] = useState(0)
    const [DeletePopUp, setDeletePopUp] = useState(false)
    const [IsDeleteLoading, setIsDeleteLoading] = useState(false)
    const [editData, setEditData] = useState('')
    const [eventTypeDDL, seteventTypeDDL] = useState({
        DDL: [],
        ID: 0,
        Label: "Select...",
    })

    const handleDeletePopUp = (item) => {
        setDeletePopUp(true)
        setEditData(item)
    }

    const handlePublishCloseClick = () => {
        setDeletePopUp(false)
    }

    const handleOnAddClick = () => {
        setshowAddPopUp(!showAddPopUp)
    }

    const handleIsPost = () => {
        setIsPost(!IsPost)
    }

    const handleClear = () => {
        seteventTypeDDL({
            ...eventTypeDDL,
            ID: 0,
            Label: "Select...",
        })
        setEventDate('')
    }

    useEffect(() => {
        const data = {
            M_EventID: '0',
            M_EventTypeID: eventTypeDDL.ID,
            EventDate: EventDate,
            Flag: RoleID == 1 ? 'Admin' : 'Partner',
            M_UserID: UserID,
            token: token,
            TOP: '99999',
            setTotalCount: setTotalCount
        }
        dispatch(EventTableDataAPI({ data }))
    }, [eventTypeDDL.ID, EventDate, IsPost])

    const { tableData, isLoading } = useSelector(state => state.EventTableData)

    const handleDelete = () => {
        const data = {
            M_EventID: editData.m_EventID,
            M_UserID: UserID,
            token: token,
            handleIsPost: handleIsPost,
            handleAddCloseClick: handlePublishCloseClick,
            setIsDeleteLoading: setIsDeleteLoading
        }
        dispatch(EventMasterDeleteAPI({ data }))
    }
    const { isDeleteLoading } = useSelector(state => state.EventMasterDeleteData)
    return (
        <>
            {isLoading && <LogoLoader/>}
            {isDeleteLoading && <LogoLoader/>}
            <AdminHeader />
            {/* <Sidebar active="dashboard" /> */}
            <div class="row flex-nowrap gr wrapper">
                <Sidebar active='Event' />
                <div class="col py-3 text-dark" id='content'>
                    <div class="page-content">
                        <span className='d-flex justify-content-between align-items-center mx-3'>
                            <h5 class=" fw-bold my-3 "> Event Master
                                {/* <!-- <span class="float-end"><a href="#n_popup_login"
                                class="add_btn  mx-1 green_color py-0">Add</a></span> --> */}
                            </h5>
                            {/* <button className='add-btn' onClick={() => navigate()}>Add</button> */}
                            {/* <a href="#" className="text-dark mx-2 mobile_none add-btn" data-bs-toggle="modal"
                                data-bs-target="#exampleModal">Add</a> */}
                        </span>
                        <div class="row py-4  card p-2">
                            <div class="col-12 ">

                                <div class="col bg-light">
                                    <div class="row grayish py-3 d-flex align-items-end">
                                        <div class="col-12 col-md-6 col-lg-3">
                                            <EventTypeDDL
                                                eventTypeDDL={eventTypeDDL}
                                                seteventTypeDDL={seteventTypeDDL}
                                            />
                                        </div>
                                        <div class="col-12 col-md-6 col-lg-3">
                                            <label for="exampleInputEmail1">
                                                Event Date
                                            </label>
                                            <input type="date" class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp"
                                                value={EventDate}
                                                onChange={(e) => setEventDate(e.target.value)}
                                            />
                                        </div>

                                        {/* <div class="col-12 col-md-6 col-lg-3">
                                            <PartnerTypeDDL
                                                partnerTypeDDL={partnerTypeDDL}
                                                setpartnerTypeDDL={setpartnerTypeDDL}
                                            />
                                        </div> */}

                                        <div className="col-12 col-md-6 col-lg-3 mt-3">
                                            <button type="button" className="clear_button"
                                                onClick={handleClear}
                                            > <i class="fa-solid fa-xmark pe-1"></i> Clear</button>
                                        </div>

                                        <div className="col-12 col-md-6 col-lg-2">
                                            <span>Total Records : <b> {TotalCount}</b></span>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-12 pb-5" style={{ overflowX: 'scroll', width: '100%' }}>
                                    {/* <!-- <h3 class="lightgreencolor pb-3">Participants</h3> --> */}
                                    <table>
                                        <thead>
                                            <tr class="header lightgreenbg whitecolor">
                                                <th>Sr. No.</th>
                                                <th>Event Type</th>
                                                <th>Event Sub Type</th>
                                                <th>Event Name</th>
                                                <th>Event Start Date</th>
                                                <th>Event End Date</th>
                                                <th>Location</th>
                                                <th>Time</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                tableData && tableData.table && tableData.table.length > 0 ? tableData.table.map((item, i) => {
                                                    return (
                                                        <tr>
                                                            <td className='text-center' width='6%'>{item.rowNum ? item.rowNum : '-'}</td>
                                                            <td>{item.eventType ? item.eventType : '-'}</td>
                                                            <td>{item.eventSubType ? item.eventSubType : '-'}</td>
                                                            <td>{item.eventName ? item.eventName : '-'}</td>
                                                            <td>{item.eventStartDateTime ? moment(item.eventStartDateTime).format('DD-MM-YYYY') : '-'}</td>
                                                            <td>{item.eventEndDateTime ? moment(item.eventEndDateTime).format('DD-MM-YYYY') : '-'}</td>
                                                            <td>{item.location ? item.location : '-'}</td>
                                                            <td>{item.eventTime ? item.eventTime : '-'}</td>
                                                            <td className='text-center'>
                                                                {/* <span className='px-2' style={{ cursor: 'pointer' }}>
                                                        <i class="fa-solid fa-arrow-up-from-bracket fs-6"
                                                            // onClick={() => PublishButtonClick('Publish PopUp')} 
                                                            title='Publish'></i>
                                                    </span> */}
                                                                <span style={{ cursor: 'pointer' }}>
                                                                    <i class="fa-solid fa-trash-can fs-6" title='Delete' style={{ color: 'red' }}
                                                                        onClick={() => handleDeletePopUp(item)}></i>
                                                                </span>
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                                    : <>No data</>
                                            }


                                        </tbody>
                                    </table>

                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <FAB toggleState={showAddPopUp} settoggleState={handleOnAddClick} />

            {
                showAddPopUp &&
                <AddEvent handleOnAddClick={handleOnAddClick} handleIsPost={handleIsPost} />
            }

            {
                DeletePopUp && <CommonDeletePopUp
                    open={DeletePopUp}
                    heading=''
                    Lable='Event'
                    handleAddCloseClick={handlePublishCloseClick}
                    handleDelete={handleDelete}
                    IsDeleteLoading={IsDeleteLoading}
                />
            }
        </>
    )
}
