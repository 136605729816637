
import Footer from "../../../Components/Footer/Footer"
import Header from "../../../Components/Header/Header"
import { PagesHeroSection } from "../../../Components/PagesHeroSection/PagesHeroSection"

export const ContactUS = () => {
    return (
        <div class="container-fluid g-0">
            <Header active='Contact'/>

            <PagesHeroSection
                Heading='Chat Suport'
                BreadScrum1='Home'
                BreadScrum2='Contact'
            />



            <div class="row p-3 p-md-5">
                <div class="col-12 col-lg-8 mx-auto text-center">
                    <h2 class="lightgreencolor  pb-0">Contact Us</h2>
                    {/* <p class="text-dark">Thank you for your interest in Global Green Credit Initiative . We value your feedback, inquiries, and collaboration.
                    Please feel free to reach out to us using the contact information below:</p> */}
                    <hr />
                </div>

                <div class="row px-lg-5 pb-5">
                    {/* <div class="col-12 col-lg-5 mx-auto p-5 shadow2 text-center"> */}
                    <div class="col-12 col-lg-5 mx-auto p-5 shadow2 text-center mt-3">
                        <h5 class="pb-3 lightgreencolor border-bottom mb-4">General Inquiries</h5>
                        <p class="text-dark">Thank you for your interest in Global Green Credit Initiative . We value your feedback, inquiries and collaboration.
                            Please feel free to reach out to us using the contact information below;</p>
                        <p class="fw-bold lightgreencolor">
                            <a href="mailto:bhawna.singh@gov.in" class="py-3 lightgreencolor"><i
                                class="fas fa-envelope me-2 lightgreencolor"></i> <span class="text-size">bhawna.singh@gov.in</span> </a>
                        </p>
                        <p class="fw-bold lightgreencolor">
                            <a href="tel:011-20819182" class="py-3 lightgreencolor"><i
                                class="fas fa-phone-alt  me-2 lightgreencolor"></i><span class="text-size">011-20819415 </span> </a>
                        </p>
                    </div>

                    {/* <div class="col-12 col-lg-5 mx-auto p-5 shadow2 text-center mt-4 mt-lg-0">
                    <h5 class="pb-3 lightgreencolor border-bottom mb-4">Technical Support</h5>
                    <p class="text-dark">If you encounter any technical issues while using the Global Green Credit Initiative  Portal or have questions related to the website, please contact our technical support team:</p>
                        <p class="fw-bold lightgreencolor">
                            <a href="mailto:bhawna.singh@gov.in" class="py-3 lightgreencolor"><i
                                class="fas fa-envelope me-2 lightgreencolor"></i> <span class="text-size">bhawna.singh@gov.in</span> </a>
                        </p>
                       
                </div> */}

                    <div class="col-12 col-lg-5 mx-auto p-5 shadow2 text-center mt-3">
                        <h5 class="pb-3 lightgreencolor border-bottom mb-4">Mailing Address</h5>
                        <p>You can also reach us by mail at our office address:</p>
                        <p class="text-dark">Ministry of Environment, Forest and Climate Change
                            Indira Paryavaran Bhawan
                            Jorbagh Road New Delhi – 110 003
                            INDIA.
                        </p>

                    </div>
                </div>

            </div>

            <div class="row">
                <div class="col-12 ">


                    <div class="offcanvas offcanvas-bottom rounded rounded-3 me-3 mb-3" tabindex="-1" id="offcanvasBottom"
                        aria-labelledby="offcanvasBottomLabel">
                        <div class="offcanvas-header lightgreenbg">
                            <h5 class="offcanvas-title" id="offcanvasBottomLabel">
                                <img src="assets/img/G_PlaNET_logo.png" alt="..." width="40px" /><span class="ps-3 text-white">Green
                                    Planet</span>
                            </h5>
                            <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas"
                                aria-label="Close"></button>
                        </div>
                        <div class="offcanvas-body small">
                            <div class="col-12 px-4 py-3">
                                <form>
                                    <div class="mb-3">
                                        <label for="exampleInputEmail1" class="form-label">Name:</label>
                                        <input type="email" class="form-control" id="exampleInputEmail1"
                                            aria-describedby="emailHelp" />

                                    </div>
                                    <div class="mb-3">
                                        <label for="exampleInputPassword1" class="form-label">Number:</label>
                                        <input type="password" class="form-control" id="exampleInputPassword1" />
                                    </div>
                                    <div class="mb-3">
                                        <label for="exampleInputEmail1" class="form-label">Email ID:</label>
                                        <input type="email" class="form-control" id="exampleInputEmail1"
                                            aria-describedby="emailHelp" />

                                    </div>
                                    <div class="mb-3">
                                        <label for="exampleInputPassword1" class="form-label">Query:</label>
                                        <textarea class="form-control" rows="5" placeholder="Leave a comment here"
                                            id="floatingTextarea"></textarea>

                                    </div>
                                    <a href="#" class="all_button">Submit</a>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* <div class="row" id="offcanvas_button">
                <div class="col-12">
                    <button class="btn lightgreenbg back-to-top2" type="button" data-bs-toggle="offcanvas"
                        data-bs-target="#offcanvasBottom" aria-controls="offcanvasBottom"
                        style={{ borderRadius: '100px', padding: '10px 9px' }}
                    > <img src="assets/img/G_PlaNET_logo.png" alt="..." width="40px" /></button>
                </div>
            </div> */}

            <Footer />
        </div>
    )
}