
import { useEffect } from 'react'
import Select from 'react-select'
import { useDispatch, useSelector } from 'react-redux'
import { PartnerNameDDLAPI, UpdatedPartnerNameDDLAPI } from '../../../Redux/DDLSlice'
import { useAuthState } from '../../../Helper/Context'


export const UpdatedPartnerNameDataDDL = (props) => {
    const { UpdatedPartnerNameDDL, setUpdatedPartnerNameDDL, apiFlag, rowData, Flag } = props

    const dispatch = useDispatch()
    const userDetails = useAuthState();
    const { UserID, token } = userDetails

    useEffect(() => {

        const data = { M_UserID:UserID,Flag:Flag, token}
        
        dispatch(UpdatedPartnerNameDDLAPI({ data }))
    }, [])

    const { DDLData } = useSelector(state => state.UpdatedPartnerNameDDLData)

    useEffect(() => {
        handleDDL()
    }, [DDLData])

    const handleDDL = () => {
        if (DDLData && DDLData.length > 0) {
            let list = DDLData.map((item, index) => ({
                value: item.m_PartnerRegistrationID,
                label: item.partnerName,
            }))

            setUpdatedPartnerNameDDL({
                DDL: list,
                ID: apiFlag == 'Update' ? rowData?.m_PartnerRegistrationID : 0,
                Label: apiFlag == 'Update' ? rowData?.partnerName : "Select...",

            })
        }
        else {
            setUpdatedPartnerNameDDL({
                DDL: [],
                ID: 0,
                Label: "Select...",
            })
        }
    }

    return (
        <div className="form-group">
            <label className="d-block" htmlFor="NameofDepartment">Partner  Name</label>
            <Select
                isClearable
                // isRtl={isRtl}
                isSearchable
                maxMenuHeight={150}
                value={{ value: UpdatedPartnerNameDDL.ID, label: UpdatedPartnerNameDDL.Label }}
                onChange={(e) => {
                    e ?
                        setUpdatedPartnerNameDDL({ ...UpdatedPartnerNameDDL, ID: e.value, Label: e.label })
                        :
                        setUpdatedPartnerNameDDL({ ...UpdatedPartnerNameDDL, ID: 0, Label: "Select..." })

                }}
                options={UpdatedPartnerNameDDL.DDL}
            />
        </div>
    )
}