import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BaseUrl } from "../../../Helper/BaseURL";
import { toastErrorr, toastSuccesss } from "../../../Helper/ToastMessage";

export const CommonSampleReportAPIDataAPI = createAsyncThunk("CommonSampleReportAPIData", async ({ data }) => {
    const {
        M_SampleReportID,
        SampleReport_M_IndicatorID,
        From,
        To,
        M_UserID,
        token,
    } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    return fetch(`${BaseUrl}/Master/Get_M_SampleReport_Select?M_SampleReportID=${M_SampleReportID}&SampleReport_M_IndicatorID=${SampleReport_M_IndicatorID}&M_UserID=${M_UserID}&From=${From}&To=${To}`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data.table) {
                return result.data.table
            } else {
                return result
            }
        })
})

const CommonSampleReportAPIDataSlice = createSlice({
    name: "CommonSampleReportAPIData",
    initialState: {
        isLoading: false,
        tableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(CommonSampleReportAPIDataAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(CommonSampleReportAPIDataAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.tableData = action.payload;
        });
        builder.addCase(CommonSampleReportAPIDataAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.tableData = null;
            toastErrorr('Something went wrong')
        });
    },
})

export const CommonSampleReportAPIDataReducer = CommonSampleReportAPIDataSlice.reducer

// Sample Report Upload API

export const CommonSamplePDFUploadPostAPI = createAsyncThunk("CommonSamplePDFUploadPost", async ({ data }) => {
    const {
        M_FinancialYearID,
        M_MonthID,
        SampleReport_M_IndicatorID,
        SampleReport_Path,
        SampleReport_FileExtension,
        M_UserID,
        handleIsPost,
        setSamplePDFPostLoading,
        token,
    } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var formdata = new FormData();
    formdata.append("M_FinancialYearID", M_FinancialYearID);
    formdata.append("M_MonthID", M_MonthID);
    formdata.append("SampleReport_M_IndicatorID", SampleReport_M_IndicatorID);
    formdata.append("SampleReport_Path", SampleReport_Path);
    formdata.append("SampleReport_FileExtension", SampleReport_FileExtension);
    formdata.append("M_UserID", M_UserID);

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
    };
    setSamplePDFPostLoading(true)
    fetch(`${BaseUrl}/Master/Post_M_SampleReport_Insert`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.status) {
                handleIsPost()
                toastSuccesss(result.message)
            } else {
                toastErrorr(result.message)
            }
            setSamplePDFPostLoading(false)
            return result

        })
})

const CommonSamplePDFUploadPostSlice = createSlice({
    name: "CommonSamplePDFUploadPost",
    initialState: {
        isLoading: false,
        CommonSamplePDFUploadPostData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(CommonSamplePDFUploadPostAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(CommonSamplePDFUploadPostAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.CommonSamplePDFUploadPostData = action.payload;
        });
        builder.addCase(CommonSamplePDFUploadPostAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.CommonSamplePDFUploadPostData = null;
            // toastErrorr('Something went wrong')
        });
    },
})

export const CommonSamplePDFUploadPostReducer = CommonSamplePDFUploadPostSlice.reducer