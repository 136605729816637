import React from 'react'
import Header from '../../../../../Components/Header/Header'
import Footer from '../../../../../Components/Footer/Footer'

const LiFE = () => {
    return (
        <>
            <Header />
            <div>
                <div className="row" id="herosection">
                    <div className="col-12 bg_img d-flex  align-items-center">
                        <div className=" py-5 px-4 text-white">
                            <nav style={{ bsBreadcrumbdivider: '>' }} aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item active "><a href="#" className="text-dark">Home</a></li><span
                                        className="mx-2 text-dark">/</span>
                                    <li className="breadcrumb-item active " aria-current="page"><a href="#"
                                        className="text-dark">About</a></li><span className="mx-2 text-dark">/</span>
                                    <li className="breadcrumb-item active " aria-current="page"><a href="#"
                                        className="lightgreencolor fw-bold">LiFE</a></li>
                                </ol>
                            </nav>
                        </div>

                    </div>
                </div>
                <div className="row p-5 d-flex justify-content-center align-items-center">
                    <div className="col-12 col-md-7 text-center ">
                        <h2 className="text-success pb-3">Mission LiFE </h2>
                        <h5 className="dark-blue pb-3 ">Encouraging Environmentally Conscious Lifestyles</h5>
                        <p className="text-dark justify"> Mission LiFE, unveiled at COP26 by Hon'ble Prime Minister Narendra Modi, is a
                            visionary initiative aimed at encouraging individuals and communities worldwide to adopt
                            environmentally conscious lifestyles. It seeks to nudge individuals and communities towards actions
                            that protect and conserve the environment while promoting sustainability.</p>
                        <p className="text-dark justify">
                            Mission LiFE is a global mass movement that emphasizes the importance of individual and collective actions in addressing environmental challenges. It aligns with the broader global agenda of sustainable development and climate action.
                        </p>
                    </div>
                    <div className="col-12 col-md-5 pt-2 text-center align-items-center justify-content-center">
                        <img src="assets/img/life/missionlife.jpg" alt="..." width="300px" />
                    </div>
                </div>
                {/* <!-- about end --> */}


                {/* <!-- Types of GC’s start --> */}
                <div className="row p-4 p-md-5 gc_typesimg" id="Benefits">
                    <h3 className="dark-blue">Three Phases of Mission LiFE</h3>
                    <p className="text-dark">Each phase requires a fundamental shift in our approach towards sustainability.</p>

                    <div className="col-12 col-lg-12 mx-auto py-4 my-0 shadow section_design mt-4">
                        <div className="row text-center text-md-start">
                            <div className="col-12 col-md-3 col-lg-2 d-block align-items-center justify-content-center ">
                                <img src="assets/img/life/Demand-01.png" alt="" />
                            </div>
                            <div className="col-12 col-md-9 col-lg-10 pt-4 ">
                                <h5> Change in Demand (Phase I)</h5>
                                <p className="text-dark pt-2">Nudging individuals across the world to practice simple yet effective environment-friendly actions in their daily lives.</p>
                            </div>
                        </div>
                    </div>

                    <div className="col-12 col-lg-12 mx-auto py-4 my-0 shadow section_design mt-4">
                        <div className="row text-center text-md-start">
                            <div className="col-12 col-md-3 col-lg-2 d-block align-items-center justify-content-center">
                                <img src="assets/img/life/ChangeinSupply-01.png" alt="" />
                            </div>
                            <div className="col-12 col-md-9 col-lg-10 pt-4 ">
                                <h5>Change in Supply (Phase II)</h5>
                                <p className="text-dark pt-2">Changes in large-scale individual demand are expected to gradually nudge industries and markets to respond and tailor supply and procurement as per the revised demands.</p>
                            </div>
                        </div>
                    </div>

                    <div className="col-12 col-lg-12 mx-auto py-4 my-0 shadow section_design mt-4">
                        <div className="row text-center text-md-start">
                            <div className="col-12 col-md-3 col-lg-2 d-block align-items-center justify-content-center">
                                <img src="assets/img/life/Policy-01.png" alt="" />
                            </div>
                            <div className="col-12 col-md-9 col-lg-10 pt-4 ">
                                <h5>Change in Policy (Phase III)</h5>
                                <p className="text-dark pt-2">By influencing the demand and supply dynamics of India and the world, the long-term vision of Mission LiFE is to trigger shifts in large-scale industrial and government policies that can support both sustainable consumption and production.</p>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- end of row Benefits--> */}

                <div className="row p-5 d-flex align-items-center justify-content-center">
                    <div className="col-12 col-lg-6 p-0 p-lg-5  text-dark fs-5 dark-blue justify" >
                        Mission Lifestyle for Environment recognises that Indian culture and living traditions are inherently sustainable. The importance of conserving our precious natural resources and living in harmony with nature are emphasised in our ancient scriptures. The need of the hour is to tap into that ancient wisdom and spread the message to as many people as possible. Mission LiFE seeks to channel the efforts of individuals and communities into a global mass movement of positive behavioural change.
                    </div>
                    <div className="col-12 col-lg-6 text-center ">
                        <img src="assets/img/life/about_life.png" width="70%" alt="" />
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default LiFE