import { useEffect, useState } from "react"
import { IsPasswordMatch, RegExEmail, RegExNumbersOnly, RegExOnlyText, RegExPassword } from "../../Helper/Regx/Regx"
import { PartnerTypeDDL } from "../../Components/DDL/PartnerTypeDDL/PartnerTypeDDL"
import { useDispatch, useSelector } from "react-redux"
import { PostPartnerRegistrationAPI, PostRegistrationAPI } from "../../Redux/PartnerRegistrationSlice/PartnerRegistrationSlice"
import { useAuthState } from "../../Helper/Context"
import { OrganiztionTypeDDL } from "../../Components/DDL/OrganiztionTypeDDL/OrganiztionTypeDDL"
import { CountryDDL } from "../../Components/DDL/CountryDDL/CountryDDL"
import { CountryCodeDDLAPI } from "../../Redux/DDLSlice"
import { AstricSign } from "../../Helper/AstricSign/AstricSign"
import { PostSendEmailAPI } from "../../Redux/SendEmailSlice/SendEmailSlice"
import { format } from "crypto-js"
import { convertAfterImageBase64 } from "../../Helper/Base64Converter/Base64ConverterFiles"

export const RegistrationPopUp = (props) => {
    const { handleShowRegistration } = props
    const dispatch = useDispatch()

    const userDetails = useAuthState();
    const { UserID, token } = userDetails

    const [FormData, setFormData] = useState({
        FullName: '',
        LastName: '',
        OrganizationName: '',
        Address: '',
        PostalCode: '',
        ConatctPerson: '',
        Position: '',
        Email: '',
        ContactNumber: '',
        UserName: '',
        Password: '',
        ConfirmPassword: '',
        OtherReason: '',
        AreaOfInterest: [],
        Contribute: [],
        Country: '',
        CorporateCountry: '',
        CorporateNodalOfficer: '',
        CorporateWebsiteLink: '',
        GovernmentCountry: '',
        GovernmentWebsiteLink: '',
        GovernmentLegalEntityInfo: '',
        GovernmentAuthorizedSignatories: '',
        OtherAuthorizationName: ''
    })

    const [showOther, setshowOther] = useState(false)
    const [CountryCodeID, setCountryCodeID] = useState('')
    const [IsAreaofInterest, setIsAreaofInterest] = useState()
    const [IsContribute, setIsContribute] = useState('')
    const [IsDisable, setIsDisable] = useState(true)
    const [PostMsg, setPostMsg] = useState('')
    const [loading, setloading] = useState(false)

    const onChange = (e) => {
        const { name, value } = e.target
        setFormData(pre => ({
            ...pre, [name]: value
        }))
    }

    const [partnerTypeDDL, setpartnerTypeDDL] = useState({
        DDL: [],
        ID: 0,
        Label: "Select...",
    })
    const [organizationTypeDDL, setorganizationTypeDDL] = useState({
        DDL: [],
        ID: 0,
        Label: "Select...",
    })
    const [CountryTypeDDL, setCountryTypeDDL] = useState({
        DDL: [],
        ID: 0,
        Label: "Select...",
    })

    const [CountryCode, setCountryCode] = useState({
        DDL: [],
        ID: 0,
        Label: "Select...",
    })

    const [ConfirmMaterial, setConfirmMaterial] = useState(false)
    const [GrantGGCIPermission, setGrantGGCIPermission] = useState(false)

    const handleYesConfirm = (e) => {
        setConfirmMaterial(!ConfirmMaterial)
        // setGrantGGCIPermission(!GrantGGCIPermission)
    }
    const handleGGCIConfirm = (e) => {
        setGrantGGCIPermission(!GrantGGCIPermission)
    }

    const handleAreaOfInterest = (e) => {
        if (e.target.checked) {
            FormData.AreaOfInterest.push(e.target.value)
        } else {
            handleAreaOfInterestFilter(e.target.value)
        }
        setIsAreaofInterest(FormData.AreaOfInterest.length)
    }

    const handleAreaOfInterestFilter = (item) => {
        FormData.AreaOfInterest = FormData.AreaOfInterest.filter((i) => {
            return i !== item
        })
    }

    const handleContribute = (e) => {
        if (e.target.checked) {
            FormData.Contribute.push(e.target.value)
        } else {
            handleContributeFilter(e.target.value)
        }
        setIsContribute(FormData.Contribute.length)
    }

    const handleContributeFilter = (item) => {
        FormData.Contribute = FormData.Contribute.filter((i) => {
            return i !== item
        })
    }

    const onChangeOther = (e) => {
        if (e.target.checked) {
            setshowOther(true)
        } else {
            setshowOther(false)
        }
    }

    const [IsValidText, setIsValidText] = useState(false)
    const handleCheckText = (e) => {
        onChange(e)
        const IsValid = RegExOnlyText(e.target.value)
        setIsValidText(IsValid)
        return IsValid
    }

    const [IsValidEmail, setIsValidEmail] = useState(false)
    const handleCheckEmail = (e) => {
        onChange(e)
        const IsValid = RegExEmail(e.target.value)
        setIsValidEmail(IsValid)
        return IsValid
    }

    const [IsValidNumber, setIsValidNumber] = useState(false)
    const handleCheckNumber = (e) => {
        onChange(e)
        const IsValid = RegExNumbersOnly(e.target.value)
        setIsValidNumber(IsValid)
        return IsValid
    }

    const [IsValidPassword, setIsValidPassword] = useState(false)
    const handleCheckPassword = (e) => {
        setFormData({
            ...FormData,
            ConfirmPassword: '',
        })
        onChange(e)
        const IsValid = RegExPassword(e.target.value)
        setIsValidPassword(IsValid)
        return IsValid
    }

    const [IsConfirmValidPassword, setIsConfirmValidPassword] = useState(false)
    const handleCheckConfirmPassword = (e) => {
        onChange(e)
        if (FormData.Password == e.target.value) {
            setIsConfirmValidPassword(false)
        } else {
            setIsConfirmValidPassword(true)
        }
    }

    const showPass = () => {
        var x = document.getElementById("pass");
        var y = document.getElementById("eye");
        if (x.type === "password") {
            x.type = "text";
        } else {
            x.type = "password";
        }
        if (y.className === "fa fa-eye-slash") {
            y.className = "fa fa-eye";
        } else {
            y.className = "fa fa-eye-slash";
        }
    }

    const handleSendEmail = (result) => {

        const data = {
            Full_Name: FormData.FullName,
            Email_ID: FormData.Email,
            Username: FormData.UserName,
            Password: FormData.ConfirmPassword,
            Flag: 'PartnerRegistration'
        }
        if (result && result[0].responsecode < 3) {
            dispatch(PostSendEmailAPI({ data }))
        }
    }

    const handlePostRegistration = (data) => {
        setPostMsg(data)
        setTimeout(() => {
            setloading(false)
            // handleShowRegistration()
        }, 2000);
    }

    const handleClear = () => {
        setSelectedId(null)
        setCountryTypeDDL({
            ...CountryTypeDDL,
            ID: 0,
            Label: 'Select...'
        })
        setFormData({
            FullName: '',
            LastName: '',
            OrganizationName: '',
            Address: '',
            PostalCode: '',
            ConatctPerson: '',
            Position: '',
            Email: '',
            ContactNumber: '',
            UserName: '',
            Password: '',
            ConfirmPassword: '',
            OtherReason: '',
            AreaOfInterest: [],
            Contribute: [],
            Country: '',
            CorporateCountry: '',
            CorporateNodalOfficer: '',
            CorporateWebsiteLink: '',
            GovernmentCountry: '',
            GovernmentWebsiteLink: '',
            GovernmentLegalEntityInfo: '',
            GovernmentAuthorizedSignatories: '',
            OtherAuthorizationName: '',

        })
        // document.getElementById('science').checked = false
        // document.getElementById('policy').checked = false
        // document.getElementById('practice').checked = false
        // document.getElementById('participate').checked = false
        // document.getElementById('commit').checked = false
        // document.getElementById('engage').checked = false
        // document.getElementById('other').checked = false
        setshowOther(false)
        handleYesConfirm()
        handleGGCIConfirm()
    }

    // const handleSubmit = () => {
    //     setloading(true)

    //     const data = {
    //         M_CountryNameID: CountryCodeID ? CountryCodeID : '0',
    //         G_Planet_text: FormData.FullName,
    //         M_PartnerTypeID: partnerTypeDDL.ID,
    //         M_SubPartnerTypeID: organizationTypeDDL.ID,
    //         Full_Name: FormData.FullName,
    //         CompanyName: FormData.OrganizationName,
    //         Position_InOrganization: FormData.Position,
    //         ContactPersonName: FormData.ConatctPerson,
    //         Email_ID: FormData.Email,
    //         MobileNo: FormData.ContactNumber,
    //         Address: FormData.Address,
    //         PostalCode: FormData.PostalCode,
    //         Username: FormData.UserName,
    //         Password: FormData.Password,
    //         AreaOfInterest: FormData.AreaOfInterest,
    //         Contribution: FormData.Contribute,
    //         OtherText: FormData.OtherReason,
    //         M_UserID: '0',
    //         handlePostRegistration,
    //         handleSendEmail,
    //         setPostMsg: setPostMsg,
    //         handleClear: handleClear
    //     }
    //     dispatch(PostPartnerRegistrationAPI({ data }))
    // }

    // const { isRegistrationLoading } = useSelector(state => state.PostPartnerRegistrationData)

    //  Image or PDF converter 

    const [ProofIdentityImagePDF, setProofIdentityImagePDF] = useState('');
    const [ProofAddressImagePDF, setProofAddressImagePDF] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [identityFileExtension, setIdentityFileExtension] = useState('');
    const [addressFileExtension, setAddressFileExtension] = useState('');
    const [CertificateIncorporationImagePDF, setCertificateIncorporationImagePDF] = useState('');
    const [MemorandumArticlesImagePDF, setMemorandumArticlesImagePDF] = useState('');
    const [TaxIdentificationImagePDF, setTaxIdentificationImagePDF] = useState('');
    const [CerificateFileExtension, setCerificateFileExtension] = useState('');
    const [MemorandumArticlesFileExtension, setMemorandumArticlesFileExtension] = useState('');
    const [TaxIdentificationFileExtension, setTaxIdentificationFileExtension] = useState('');
    const [OtherCertificateofIncorporationPDF, setOtherCertificateofIncorporationPDF] = useState('')
    const [OtherCertificateFileExtension, setOtherCertificateFileExtension] = useState('')
    const [OtherLetterofAuthorizationPDF, setOtherLetterofAuthorizationPDF] = useState('')
    const [LetterofAuthorizationFileExtension, setLetterofAuthorizationFileExtension] = useState('')
    const [OtherAprroveIDCardofAuthorizationPDF, setOtherAprroveIDCardofAuthorizationPDF] = useState('')
    const [AprroveIDCard_FileExtension, setAprroveIDCard_FileExtension] = useState('')

    // Corporate Entity Image Converter

    const ImagePdfConverter = async (e, type) => {
        // console.log(e);
        const file = e.target.files[0];

        // console.log(file);

        if (file) {

            if (file.size > 3 * 1024 * 1024) {
                setErrorMessage('File size exceeds 3MB. Please upload a smaller file.');
                return;
            }

            setErrorMessage('');

            try {
                const base64 = await convertAfterImageBase64(file);

                //file extension
                const fileExtension = `.${file.name.split('.').pop()}`;
                // console.log("fileExtension ", file.name.split('.').pop())

                if (type === 'identity') {
                    setProofIdentityImagePDF(base64.split(',')[1]);
                    setIdentityFileExtension(fileExtension);
                } else if (type === 'address') {
                    setProofAddressImagePDF(base64.split(',')[1]);
                    setAddressFileExtension(fileExtension);
                } else if (type === 'Certificate') {
                    setCertificateIncorporationImagePDF(base64.split(',')[1]);
                    setCerificateFileExtension(fileExtension);
                } else if (type === 'Memorandum') {
                    setMemorandumArticlesImagePDF(base64.split(',')[1]);
                    setMemorandumArticlesFileExtension(fileExtension);
                } else if (type === 'TaxIdentification') {
                    setTaxIdentificationImagePDF(base64.split(',')[1]);
                    setTaxIdentificationFileExtension(fileExtension);
                } else if (type === 'Incorporation') {
                    setOtherCertificateofIncorporationPDF(base64.split(',')[1]);
                    setOtherCertificateFileExtension(fileExtension);
                } else if (type === 'Letterauthorization') {
                    setOtherLetterofAuthorizationPDF(base64.split(',')[1]);
                    setLetterofAuthorizationFileExtension(fileExtension);
                } else if (type === 'ApprovedIDCard') {
                    setOtherAprroveIDCardofAuthorizationPDF(base64.split(',')[1]);
                    setAprroveIDCard_FileExtension(fileExtension);
                }
            } catch (error) {
                console.error('Error converting file to Base64', error);
                setErrorMessage('An error occurred while uploading the file.');
            }
        }
    };
    const currentDate = new Date();

    const handleSubmit = () => {
        setloading(true)

        const data = {
            M_FinancialYearID: currentDate.getFullYear(),
            M_MonthID: currentDate.getMonth() + 1,
            FirstName: FormData.FullName,
            LastName: FormData.LastName,
            OrganizationName: FormData.OrganizationName,
            DesignationName: FormData.Position,
            Email_ID: FormData.Email,
            PhoneNumber: FormData.ContactNumber,
            UserType_M_IndicatorID: selectedId,
            Username: FormData.UserName,
            Password: FormData.Password,
            Individual_Country: selectedId.ID == 6 ? CountryTypeDDL.ID : 0,
            Individual_Proof_of_Identity: ProofIdentityImagePDF,
            ProofofIdentity_FileExtension: identityFileExtension,
            Individual_Proof_of_Address: ProofAddressImagePDF,
            ProofofAddress_FileExtension: addressFileExtension,
            Corporate_Country: selectedId.ID == 7 ? CountryTypeDDL.ID : 0,
            Corporate_NodalOfficer: FormData.CorporateNodalOfficer,
            Corporate_WebsiteLink: FormData.CorporateWebsiteLink,
            Corporate_Certificate_of_Incorporation: CertificateIncorporationImagePDF,
            CertificateofIncorporation_FileExtension: CerificateFileExtension,
            Corporate_MemorandumArticles: MemorandumArticlesImagePDF,
            MemorandumArticles_FileExtension: MemorandumArticlesFileExtension,
            Corporate_TaxIdentification: TaxIdentificationImagePDF,
            TaxIdentification_FileExtension: TaxIdentificationFileExtension,
            Government_Country: selectedId.ID == 8 ? CountryTypeDDL.ID : 0,
            Government_WebsiteLink: FormData.GovernmentWebsiteLink,
            Government_LegalEntityInfo: FormData.GovernmentLegalEntityInfo,
            Government_AuthorizedSignatories: FormData.GovernmentAuthorizedSignatories,
            Other_Certificate_of_Incorporation: OtherCertificateofIncorporationPDF,
            OtherCertificate_FileExtension: OtherCertificateFileExtension,
            Other_AuthorizationName: FormData.OtherAuthorizationName,
            Other_Letter_of_Authorization: OtherLetterofAuthorizationPDF,
            LetterofAuthorization_FileExtension: LetterofAuthorizationFileExtension,
            Other_AprroveIDCard_of_Authorization: OtherAprroveIDCardofAuthorizationPDF,
            AprroveIDCard_FileExtension: AprroveIDCard_FileExtension,
            M_UserID: '0',
            handlePostRegistration,
            handleSendEmail,
            setPostMsg: setPostMsg,
            handleClear: handleClear
        }
        dispatch(PostRegistrationAPI({ data }))
    }

    const { isRegistrationLoading } = useSelector(state => state.PostRegistrationData)

    useEffect(() => {
        const data = { UserID, token, CountryTypeDDL, setCountryCodeID }
        dispatch(CountryCodeDDLAPI({ data }))
    }, [CountryTypeDDL])

    const { DDLData } = useSelector(state => state.CountryCodeDDLData)

    // ------------------------

    const Data = [
        {
            id: "6",
            name: "Individual Users"
        },
        {
            id: "7",
            name: "Corporate Entity"
        },
        {
            id: "8",
            name: "Government Entity"
        },
        {
            id: "12",
            name: "Other Users"
        },

    ]

    const [selectedId, setSelectedId] = useState(null);
    const [textInput, setTextInput] = useState("");

    const handleCheckboxChange = (id) => {
        setSelectedId(selectedId === id ? null : id);
        if (selectedId === id) {
            setTextInput("");
        }
    };

    const IsValidate = () => {
        if (!showOther) {
            // if (
            //     IsValidText || IsValidEmail || IsValidNumber || IsValidPassword || IsConfirmValidPassword ||
            //     FormData.AreaOfInterest.length == '0' || FormData.Contribute.length == '0' || FormData.Email == '' ||
            //     CountryTypeDDL.ID == '0' || FormData.FullName == '' || FormData.OrganizationName == '' ||
            //     FormData.Position == '' || FormData.UserName == '' ||
            //     FormData.Password == '' || FormData.ConfirmPassword == '' || FormData.LastName == '') {
            //     setIsDisable(true)
            // }
            if (
                IsValidText || IsValidEmail || IsValidNumber || IsValidPassword || IsConfirmValidPassword || FormData.Email == '' ||
                FormData.FullName == '' || FormData.OrganizationName == '' ||
                FormData.Position == '' || FormData.UserName == '' ||
                FormData.Password == '' || FormData.ConfirmPassword == '' || FormData.LastName == '' ||
                FormData.ContactNumber == '' || selectedId == null 
                // || !ConfirmMaterial || !GrantGGCIPermission
            ) {
                setIsDisable(true)
            } else {
                setIsDisable(false)
            }
        }
        else if (showOther) {
            // if (
            //     IsValidText || IsValidEmail || IsValidNumber || IsValidPassword || IsConfirmValidPassword ||
            //     FormData.AreaOfInterest.length == '0' || FormData.Contribute.length == '0' || FormData.Email == '' ||
            //     CountryTypeDDL.ID == '0' || FormData.FullName == '' || FormData.OrganizationName == '' ||
            //     FormData.Position == '' || FormData.UserName == '' ||
            //     FormData.Password == '' || FormData.ConfirmPassword == '' || FormData.OtherReason == '' ||
            //     FormData.LastName == '') {
            //     setIsDisable(true)
            // } 
            if (
                IsValidText || IsValidEmail || IsValidNumber || IsValidPassword || IsConfirmValidPassword || FormData.Email == '' ||
                FormData.FullName == '' || FormData.OrganizationName == '' ||
                FormData.Position == '' || FormData.UserName == '' || FormData.OtherReason == '' ||
                FormData.Password == '' || FormData.ConfirmPassword == '' || FormData.LastName == '' ||
                FormData.ContactNumber == '' || selectedId == null 
                // || !ConfirmMaterial || !GrantGGCIPermission
            ) {
                setIsDisable(true)
            } else {
                setIsDisable(false)
            }
        }
    }

    useEffect(() => {
        IsValidate()
    }, [FormData, IsAreaofInterest, IsContribute, showOther, ConfirmMaterial, GrantGGCIPermission])

    // useEffect(() => {
    //     IsValidate()
    // }, [FormData, IsAreaofInterest, IsContribute, showOther,ConfirmMaterial,GrantGGCIPermission])

    const handleRadioInputClear = () => {
        if (selectedId == 6) {
            setFormData({
                ...FormData,
                CorporateCountry: '',
                CorporateNodalOfficer: '',
                CorporateWebsiteLink: '',
                GovernmentCountry: '',
                GovernmentWebsiteLink: '',
                GovernmentLegalEntityInfo: '',
                GovernmentAuthorizedSignatories: '',
                OtherAuthorizationName: '',
            });
            setCountryTypeDDL({
                ...CountryTypeDDL,
                ID: 0,
                Label: "Select...",

            })
            setCertificateIncorporationImagePDF('');
            setCerificateFileExtension('');
            setMemorandumArticlesImagePDF('');
            setMemorandumArticlesFileExtension('');
            setTaxIdentificationImagePDF('');
            setTaxIdentificationFileExtension('');
            setOtherCertificateofIncorporationPDF('');
            setOtherCertificateFileExtension('');
            setOtherLetterofAuthorizationPDF('');
            setLetterofAuthorizationFileExtension('');
            setOtherAprroveIDCardofAuthorizationPDF('');
            setAprroveIDCard_FileExtension('');
        }
        if (selectedId == 7) {
            setFormData({
                ...FormData,
                GovernmentCountry: '',
                GovernmentWebsiteLink: '',
                GovernmentLegalEntityInfo: '',
                GovernmentAuthorizedSignatories: '',
                OtherAuthorizationName: '',

            })
            setCountryTypeDDL({
                ...CountryTypeDDL,
                ID: 0,
                Label: "Select...",

            })
            setProofIdentityImagePDF('');
            setIdentityFileExtension('');
            setProofAddressImagePDF('');
            setAddressFileExtension('');
            setOtherCertificateofIncorporationPDF('');
            setOtherCertificateFileExtension('');
            setOtherLetterofAuthorizationPDF('');
            setLetterofAuthorizationFileExtension('');
            setOtherAprroveIDCardofAuthorizationPDF('');
            setAprroveIDCard_FileExtension('');
        }
        if (selectedId == 8) {
            setFormData({
                ...FormData,
                CorporateCountry: '',
                CorporateNodalOfficer: '',
                CorporateWebsiteLink: '',
                OtherAuthorizationName: '',
            })
            setCountryTypeDDL({
                ...CountryTypeDDL,
                ID: 0,
                Label: "Select...",

            })
            setProofIdentityImagePDF('');
            setIdentityFileExtension('');
            setProofAddressImagePDF('');
            setAddressFileExtension('');
            setCertificateIncorporationImagePDF('');
            setCerificateFileExtension('');
            setMemorandumArticlesImagePDF('');
            setMemorandumArticlesFileExtension('');
            setTaxIdentificationImagePDF('');
            setTaxIdentificationFileExtension('');
            setCertificateIncorporationImagePDF('');
            setCerificateFileExtension('');
            setMemorandumArticlesImagePDF('');
            setMemorandumArticlesFileExtension('');
            setTaxIdentificationImagePDF('');
            setTaxIdentificationFileExtension('');
        }
        if (selectedId == 12) {
            setFormData({
                ...FormData,
                CorporateCountry: '',
                CorporateNodalOfficer: '',
                CorporateWebsiteLink: '',
                GovernmentCountry: '',
                GovernmentWebsiteLink: '',
                GovernmentLegalEntityInfo: '',
                GovernmentAuthorizedSignatories: '',
            })
            setProofIdentityImagePDF('');
            setIdentityFileExtension('');
            setProofAddressImagePDF('');
            setAddressFileExtension('');
            setCertificateIncorporationImagePDF('');
            setCerificateFileExtension('');
            setMemorandumArticlesImagePDF('');
            setMemorandumArticlesFileExtension('');
            setTaxIdentificationImagePDF('');
            setTaxIdentificationFileExtension('');
        }
    }

    useEffect(() => {
        handleRadioInputClear()
    }, [selectedId])


    return (
        <div className="modal show fade px-lg-5" id="exampleModal"
            style={{ display: "block", backgroundColor: "#00000055", overflow: 'auto' }}
            tabindex="-1" aria-labelledby="exampleModalLabel"
            aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered" >
                <div className="modal-content" style={{ height: '90vh' }}>
                    <div className="modal-header px-lg-5 bg-white">
                        <h5 className="modal-title lightgreencolor " id="exampleModalLabel">
                            Registration
                        </h5>
                        <button type="button" className=" bg-danger text-white px-2 border-0 rounded fs-4"
                            data-dismiss="modal" data-bs-dismiss="modal" aria-label="Close"
                            onClick={handleShowRegistration}
                        > &times; </button>
                    </div>

                    <div className="modal-body px-5 whats_new"
                    // style={{ height: '600px', overflowY: 'scroll' }}
                    >

                        <form>
                            {/* <div className="row pt-4">
                                <div className="col-12 col-md-5">
                                    <div className="col-12">
                                        <label for="exampleInputEmail1"
                                            className="form-label">Country/Region<AstricSign /></label>
                                    </div>
                                </div>
                                <div className="col-12 col-md-7">
                                    <div className="col-12">
                                        <CountryDDL
                                            CountryTypeDDL={CountryTypeDDL}
                                            setCountryTypeDDL={setCountryTypeDDL}
                                        />
                                    </div>
                                </div>
                            </div> */}
                            <div className="row pt-4">
                                <div className="col-12 col-md-5">
                                    <div className="col-12">
                                        <label for="exampleInputEmail1" className="form-label">First Name<AstricSign /></label>
                                    </div>
                                </div>
                                <div className="col-12 col-md-7">
                                    <div className="col-12">
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="FullName"
                                            value={FormData.FullName}
                                            // onChange={onChange}
                                            onChange={(e) => handleCheckText(e)}
                                        />
                                        {
                                            IsValidText && <text className="RegxValidation">Please enter Alphabet characters only</text>
                                        }
                                    </div>
                                </div>
                            </div>

                            <div className="row pt-4">
                                <div className="col-12 col-md-5">
                                    <div className="col-12">
                                        <label for="exampleInputEmail1" className="form-label">Last  Name<AstricSign /></label>
                                    </div>
                                </div>
                                <div className="col-12 col-md-7">
                                    <div className="col-12">
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="LastName"
                                            value={FormData.LastName}
                                            // onChange={onChange}
                                            onChange={(e) => handleCheckText(e)}
                                        />
                                        {
                                            IsValidText && <text className="RegxValidation">Please enter Alphabet characters only</text>
                                        }
                                    </div>
                                </div>
                            </div>
                            {/* <div className="row pt-4">
                                <div className="col-12 col-md-5">
                                    <div className="col-12">
                                        <label for="exampleInputEmail1" className="form-label">Partner Name<AstricSign /></label>
                                    </div>
                                </div>
                                <div className="col-12 col-md-7">
                                    <div className="col-12">
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="FullName"
                                            value={FormData.FullName}
                                            // onChange={onChange}
                                            onChange={(e) => handleCheckText(e)}
                                        />
                                        {
                                            IsValidText && <text className="RegxValidation">Please enter Alphabet characters only</text>
                                        }
                                    </div>
                                </div>
                            </div> */}
                            <div className="row pt-4">
                                <div className="col-12 col-md-5">
                                    <div className="col-12">
                                        <label for="exampleInputEmail1" className="form-label"> Organization Name<AstricSign /></label>
                                    </div>
                                </div>
                                <div className="col-12 col-md-7">
                                    <div className="col-12">
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="OrganizationName"
                                            value={FormData.OrganizationName}
                                            onChange={onChange} />
                                    </div>
                                </div>
                            </div>
                            <div className="row pt-4">
                                <div className="col-12 col-md-5">
                                    <div className="col-12">
                                        <label for="exampleInputEmail1" className="form-label"> Designation<AstricSign /></label>
                                    </div>
                                </div>
                                <div className="col-12 col-md-7">
                                    <div className="col-12">
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="Position"
                                            value={FormData.Position}
                                            onChange={onChange} />
                                    </div>
                                </div>
                            </div>
                            <div className="row pt-4">
                                <div className="col-12 col-md-5">
                                    <div className="col-12">
                                        <label for="exampleInputEmail1" className="form-label"> Email<AstricSign /></label>
                                    </div>
                                </div>
                                <div className="col-12 col-md-7">
                                    <div className="col-12">
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="Email"
                                            value={FormData.Email}
                                            // onChange={onChange} 
                                            onChange={(e) => handleCheckEmail(e)}
                                        />
                                        {
                                            IsValidEmail && <text className="RegxValidation">Please enter valid Email Address</text>
                                        }
                                    </div>
                                </div>
                            </div>

                            <div className="row pt-4">

                                <div className="col-12 col-md-5">
                                    <div className="col-12">
                                        <label for="exampleInputEmail1" className="form-label">Contact Number</label>
                                    </div>
                                </div>

                                <div className="col-12 col-md-7">
                                    <div className="col-12">
                                        <div className="input-group ">
                                            <span className="input-group-text">

                                                <select className="" style={{ border: 'none', backgroundColor: '#E9ECEF' }}
                                                    onChange={(e) => {
                                                        setCountryCodeID(e.target.value)
                                                    }}
                                                >
                                                    {
                                                        DDLData && DDLData.length > 0 && DDLData.map((item) => (
                                                            <option key={item.m_CountryID} value={item.m_CountryID} selected>{item.mobileCountryCode}</option>
                                                        ))
                                                    }
                                                </select>
                                            </span>

                                            <input
                                                type="text"
                                                className="form-control"
                                                name="ContactNumber"
                                                value={FormData.ContactNumber}
                                                // onChange={onChange} 
                                                onChange={(e) => handleCheckNumber(e)}
                                            />
                                            {
                                                IsValidNumber && <text className="RegxValidation">Please enter Numeric characters only</text>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* <div className="row pt-4">
                                <div className="col-12 col-md-5">
                                    <div className="col-12">
                                        <label for="exampleInputEmail1" className="form-label">Partner Type<AstricSign /></label>

                                    </div>
                                </div>
                                <div className="col-12 col-md-7">
                                    <div className="col-12">
                                        <PartnerTypeDDL
                                            label={true}
                                            partnerTypeDDL={partnerTypeDDL}
                                            setpartnerTypeDDL={setpartnerTypeDDL}
                                        />
                                    </div>
                                </div>
                            </div> */}


                            <div className="row pt-4">
                                <div className="col-12 col-md-5">
                                    <div className="col-12">
                                        <label for="exampleInputEmail1" className="form-label">User Type<AstricSign /></label>

                                    </div>
                                </div>
                                <div className="col-12 col-md-7">
                                    <div className="col-12">
                                        <div className="row">
                                            {Data.map((item) => (
                                                <div className="col-12 pt-3" key={item.id}>
                                                    <form>
                                                        <input
                                                            type="radio"
                                                            className="px-3"
                                                            id={item.id}
                                                            name={item.id}
                                                            checked={selectedId === item.id}
                                                            onChange={() => handleCheckboxChange(item.id)}
                                                        />
                                                        <span className="ps-2">{item.name}</span>
                                                    </form>
                                                </div>
                                            ))}

                                        </div>
                                    </div>
                                </div>

                                <div className="col-12">
                                    {selectedId == 6 &&
                                        <div className="pt-3 col-12">
                                            <h6>Individual Form and KYC</h6>
                                            <div className="border p-4">

                                                <div className="row pt-4">
                                                    <div className="col-12 col-md-5">
                                                        <div className="col-12">
                                                            <label for="exampleInputEmail1"
                                                                className="form-label">Country/Region<AstricSign /></label>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-7">
                                                        <div className="col-12">
                                                            <CountryDDL
                                                                CountryTypeDDL={CountryTypeDDL}
                                                                setCountryTypeDDL={setCountryTypeDDL}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>

                                                {/* <div className="row py-2 ">
                                                    <div className="col-12 col-md-5">
                                                        <div className="col-12">
                                                            <label for="exampleInputEmail1" className="form-label">Country/Region <AstricSign /> </label>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-7">
                                                        <div className="col-12">
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                name="Country"
                                                                value={FormData.Country}
                                                                onChange={onChange} />
                                                        </div>
                                                    </div>
                                                </div> */}

                                                <div className="row py-2 ">
                                                    <div className="col-12 col-md-5">
                                                        <div className="col-12">
                                                            <label for="exampleInputEmail1" className="form-label"> PROOF OF IDENTITY </label><br />
                                                            <p style={{ fontSize: '13px', textAlign: 'justify' }}>(Aadhaar card, Passport, Driving license,
                                                                Voter ID,PAN card, Photo ID cards issued by
                                                                the Central or State government entities, public
                                                                financial institutions and public sector undertakings)
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-7">
                                                        <div className="col-12">
                                                            <input
                                                                type="file"
                                                                className="form-control"
                                                                name="filename"
                                                                accept=".jpg,.pdf"
                                                                onChange={(e) => {
                                                                    ImagePdfConverter(e, 'identity');
                                                                }}
                                                            />

                                                            <small className="text-danger" style={{ fontSize: '13px' }}>Upload Size(3MB)Format (PDF, Image)</small>
                                                            {errorMessage && (
                                                                <div className="alert alert-danger mt-3">
                                                                    <strong>Error:</strong> {errorMessage}
                                                                </div>
                                                            )}

                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row py-2">
                                                    <div className="col-12 col-md-5">
                                                        <div className="col-12">
                                                            <label for="exampleInputEmail1" className="form-label"> PROOF OF ADDRESS  </label>
                                                            <br />
                                                            <p style={{ fontSize: '13px', textAlign: 'justify' }}>(Voter ID card, Driving license, Passport, Utility bills,
                                                                such as water bill, electricity, gas bill, or telephone bill (not older than one month))

                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-7">
                                                        <div className="col-12">
                                                            <input
                                                                type="file"
                                                                className="form-control"
                                                                name="filename"
                                                                // accept=".png, .jpg, .jpeg,.pdf"
                                                                accept=".jpg,.pdf"
                                                                onChange={(e) => {
                                                                    ImagePdfConverter(e, 'address');
                                                                }}
                                                            />
                                                            <small className="text-danger" style={{ fontSize: '13px' }}>Upload Size(3MB)Format (PDF, Image)</small>

                                                            {errorMessage && (
                                                                <div className="alert alert-danger mt-3">
                                                                    <strong>Error:</strong> {errorMessage}
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    }

                                    {selectedId == 7 &&
                                        <div className="pt-3 col-12">
                                            <h6>Corporate Form and KYC (Private Organization)</h6>
                                            <div className="border p-4">

                                                <div className="row pt-4">
                                                    <div className="col-12 col-md-5">
                                                        <div className="col-12">
                                                            <label for="exampleInputEmail1"
                                                                className="form-label">Country/Region<AstricSign /></label>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-7">
                                                        <div className="col-12">
                                                            <CountryDDL
                                                                CountryTypeDDL={CountryTypeDDL}
                                                                setCountryTypeDDL={setCountryTypeDDL}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>

                                                {/* <div className="row py-2 ">
                                                    <div className="col-12 col-md-5">
                                                        <div className="col-12">
                                                            <label for="exampleInputEmail1" className="form-label">Country/Region <AstricSign /></label>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-7">
                                                        <div className="col-12">
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                name="CorporateCountry"
                                                                value={FormData.CorporateCountry}
                                                                onChange={onChange} />
                                                        </div>
                                                    </div>
                                                </div> */}

                                                <div className="row py-2 ">
                                                    <div className="col-12 col-md-5">
                                                        <div className="col-12">
                                                            <label for="exampleInputEmail1" className="form-label"> Nodal Officer  <AstricSign /></label>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-7">
                                                        <div className="col-12">
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                name="CorporateNodalOfficer"
                                                                value={FormData.CorporateNodalOfficer}
                                                                onChange={onChange} />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row py-2 ">
                                                    <div className="col-12 col-md-5">
                                                        <div className="col-12">
                                                            <label for="exampleInputEmail1" className="form-label"> Website Link <AstricSign />	 </label>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-7">
                                                        <div className="col-12">
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                name="CorporateWebsiteLink"
                                                                value={FormData.CorporateWebsiteLink}
                                                                onChange={onChange} />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row py-2 ">
                                                    <div className="col-12 col-md-5">
                                                        <div className="col-12">
                                                            <label for="exampleInputEmail1" className="form-label">Copy of Certificate of incorporation </label><br />
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-7">
                                                        <div className="col-12">
                                                            <input
                                                                type="file"
                                                                className="form-control"
                                                                name="filename"
                                                                accept=".jpg,.pdf"
                                                                onChange={(e) => {
                                                                    ImagePdfConverter(e, 'Certificate');
                                                                }} />
                                                            <small className="text-danger" style={{ fontSize: '13px' }}>Upload Size(3MB)Format (PDF, Image)</small>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row py-2">
                                                    <div className="col-12 col-md-5">
                                                        <div className="col-12">
                                                            <label for="exampleInputEmail1" className="form-label"> Copy of Memorandum & Articles of Association </label>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-7">
                                                        <div className="col-12">
                                                            <input
                                                                type="file"
                                                                className="form-control"
                                                                name="filename"
                                                                accept=".jpg,.pdf"
                                                                onChange={(e) => {
                                                                    ImagePdfConverter(e, 'Memorandum');
                                                                }} />
                                                            <small className="text-danger" style={{ fontSize: '13px' }}>Upload Size(3MB)Format (PDF, Image)</small>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row py-2">
                                                    <div className="col-12 col-md-5">
                                                        <div className="col-12">
                                                            <label for="exampleInputEmail1" className="form-label">Copy of Tax Identification Number of the Company</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-7">
                                                        <div className="col-12">
                                                            <input
                                                                type="file"
                                                                className="form-control"
                                                                name="filename"
                                                                accept=".jpg,.pdf"
                                                                onChange={(e) => {
                                                                    ImagePdfConverter(e, 'TaxIdentification');
                                                                }} />
                                                            <small className="text-danger" style={{ fontSize: '13px' }}>Upload Size(3MB)Format (PDF, Image)</small>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    }

                                    {selectedId == 8 &&
                                        <div className="pt-3 col-12">
                                            <h6>Government Entity (KYC)</h6>
                                            <div className="border p-4">

                                                <div className="row py-2 ">
                                                    <div className="row pt-4">
                                                        <div className="col-12 col-md-5">
                                                            <div className="col-12">
                                                                <label for="exampleInputEmail1"
                                                                    className="form-label">Country/Region<AstricSign /></label>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 col-md-7">
                                                            <div className="col-12">
                                                                <CountryDDL
                                                                    CountryTypeDDL={CountryTypeDDL}
                                                                    setCountryTypeDDL={setCountryTypeDDL}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* <div className="col-12">
                                                        <label for="exampleInputEmail1" className="form-label"> Country/Region <AstricSign /> </label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            name="GovernmentCountry"
                                                            value={FormData.GovernmentCountry}
                                                            onChange={onChange}
                                                        // onChange={(e) => handleCheckText(e)}
                                                        />
                                                    </div> */}

                                                    <div className="col-12 py-2">
                                                        <label for="exampleInputEmail1" className="form-label"> Website Link <AstricSign /> </label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            name="GovernmentWebsiteLink"
                                                            value={FormData.GovernmentWebsiteLink}
                                                            onChange={onChange}
                                                        // onChange={(e) => handleCheckText(e)}
                                                        />
                                                    </div>

                                                    <div className="col-12 py-2">
                                                        <label className="form-label text-dark"> Legal Entity Information  </label>
                                                        <p style={{ textAlign: 'justify' }}>This includes details such as the legal name, registration number, incorporation details, and registered address of the government entity or local government organization. </p>
                                                        <textarea class="form-control"
                                                            placeholder=" Answer" id="exampleFormControlTextarea1"
                                                            rows="2"
                                                            name="GovernmentLegalEntityInfo"
                                                            value={FormData.GovernmentLegalEntityInfo}
                                                            onChange={onChange}
                                                        ></textarea>
                                                    </div>

                                                    <div className="col-12 py-2">
                                                        <label className="form-label text-dark"> Authorized Signatories  </label>
                                                        <p style={{ textAlign: 'justify' }}>Name and Designation information about individuals authorized to * represent and act on behalf of the government entity or local government organization, including their names, titles, and contact details.* </p>
                                                        <textarea class="form-control"
                                                            placeholder=" Answer"
                                                            id="exampleFormControlTextarea1"
                                                            rows="2"
                                                            name="GovernmentAuthorizedSignatories"
                                                            value={FormData.GovernmentAuthorizedSignatories}
                                                            onChange={onChange}
                                                        ></textarea>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    }

                                    {selectedId == 12 &&
                                        <>
                                            <div className="pt-3 col-12">
                                                <h6>Other Users KYC</h6>
                                                <div className="border p-4">

                                                    <div className="row py-2 ">
                                                        <div className="col-12 col-md-5">
                                                            <div className="col-12">
                                                                <label for="exampleInputEmail1" className="form-label">Copy of the Certificate of Incorporation of the organization </label><br />
                                                            </div>
                                                        </div>
                                                        <div className="col-12 col-md-7">
                                                            <div className="col-12">
                                                                <input
                                                                    type="file"
                                                                    className="form-control"
                                                                    name="filename"
                                                                    accept=".jpg,.pdf"
                                                                    onChange={(e) => {
                                                                        ImagePdfConverter(e, 'Incorporation');
                                                                    }}
                                                                />
                                                                <small className="text-danger" style={{ fontSize: '13px' }}>Upload Size(3MB)Format (PDF, Image)</small>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="row py-2 ">
                                                        <div className="col-12 col-md-5">
                                                            <div className="col-12">
                                                                <label for="exampleInputEmail1" className="form-label">Name of authorised or nominated representatives </label>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 col-md-7">
                                                            <div className="col-12">
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    name="OtherAuthorizationName"
                                                                    value={FormData.OtherAuthorizationName}
                                                                    onChange={onChange} />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="row py-2">
                                                        <div className="col-12 col-md-5">
                                                            <div className="col-12">
                                                                <label for="exampleInputEmail1" className="form-label"> Letter of authorization / resolution for nominated or authorised representatives </label>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 col-md-7">
                                                            <div className="col-12">
                                                                <input
                                                                    type="file"
                                                                    className="form-control"
                                                                    name="filename"
                                                                    accept=".jpg,.pdf"
                                                                    onChange={(e) => {
                                                                        ImagePdfConverter(e, 'Letterauthorization');
                                                                    }}
                                                                />
                                                                <small className="text-danger" style={{ fontSize: '13px' }}>Upload Size(3MB)Format (PDF, Image)</small>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="row py-2">
                                                        <div className="col-12 col-md-5">
                                                            <div className="col-12">
                                                                <label for="exampleInputEmail1" className="form-label">Copy of Govt. approved ID Card of authorised / nominated representatives</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 col-md-7">
                                                            <div className="col-12">
                                                                <input
                                                                    type="file"
                                                                    className="form-control"
                                                                    name="filename"
                                                                    accept=".jpg,.pdf"
                                                                    onChange={(e) => {
                                                                        ImagePdfConverter(e, 'ApprovedIDCard');
                                                                    }}
                                                                />
                                                                <small className="text-danger" style={{ fontSize: '13px' }}>Upload Size(3MB)Format (PDF, Image)</small>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </>
                                    }
                                </div>
                            </div>


                            {
                                partnerTypeDDL.ID > 1 &&
                                <div className="row pt-4">
                                    <div className="col-12 col-md-5">
                                        <div className="col-12">
                                            <label for="exampleInputEmail1" className="form-label">
                                                {partnerTypeDDL.ID == 2 ? 'Organization Type' : 'Academia & Researchers'}<AstricSign />
                                            </label>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-7">
                                        <div className="col-12">
                                            <OrganiztionTypeDDL
                                                organizationTypeDDL={organizationTypeDDL}
                                                setorganizationTypeDDL={setorganizationTypeDDL}
                                                PartnerTypeID={partnerTypeDDL.ID}
                                                Label=''
                                            />
                                        </div>
                                    </div>
                                </div>
                            }

                            <div className="row pt-4">
                                <div className="col-12 col-md-5">
                                    <div className="col-12">
                                        <label for="Username" className="form-label">Username<AstricSign /></label>
                                    </div>
                                </div>
                                <div className="col-12 col-md-7">
                                    <div className="col-12">
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="UserName"
                                            value={FormData.UserName}
                                            onChange={onChange} />
                                    </div>
                                </div>
                            </div>

                            <div className="row pt-4">
                                <div className="col-12 col-md-5">
                                    <div className="col-12">
                                        <label for="password" className="form-label">Password<AstricSign /></label>
                                    </div>
                                </div>
                                <div className="col-12 col-md-7">
                                    <div className="col-12">
                                        <input
                                            type="password"
                                            className="form-control"
                                            name="Password"
                                            autoComplete="new-password"
                                            value={FormData.Password}
                                            // onChange={onChange}
                                            onChange={(e) => handleCheckPassword(e)}
                                        />
                                        {
                                            IsValidPassword &&
                                            <div className="RegxValidation">
                                                <text >Please enter Minimum six characters, at least one uppercase letter, one lowercase letter, one number and one special character.</text>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>

                            <div className="row pt-4 pb-3">
                                <div className="col-12 col-md-5">
                                    <div className="col-12">
                                        <label for="password" className="form-label">Confirm Password<AstricSign /></label>
                                    </div>
                                </div>
                                <div className="col-12 col-md-7">
                                    <div className="col-12">
                                        <div className="input-group ">
                                            <input
                                                type="password"
                                                className="form-control"
                                                name="ConfirmPassword"
                                                id="pass"
                                                value={FormData.ConfirmPassword}
                                                onChange={(e) => handleCheckConfirmPassword(e)}
                                            />
                                            <span className="input-group-text">

                                                <span id='eye' className="fa fa-eye-slash"
                                                    onClick={() => showPass()}

                                                ></span>
                                            </span>
                                        </div>
                                        {
                                            IsConfirmValidPassword &&
                                            <div className="RegxValidation">
                                                <text >Password Mismatch</text>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>

                            <hr className="mt-3" />
                            {/* <div className="row">
                                <div className="col-12">
                                    <h6 className="pt-3">Disclaimer</h6>
                                    <p>I confirm that I own the rights to the content I am uploading, or I have obtained the necessary permissions to publish/share this material.</p>
                                    <div class="mb-3 form-check">
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id="exampleCheck1"
                                            name="ConfirmMaterial"
                                            checked={ConfirmMaterial}
                                            value={ConfirmMaterial}
                                            onChange={(e) => handleYesConfirm(e)}

                                        />
                                        <label class="form-check-label text-dark" for="exampleCheck1">Yes, I confirm</label>
                                    </div>
                                </div>

                                <div className="col-12">
                                    <p>I grant GGCI permission to make the resource I am uploading public on the platform.</p>
                                    <div class="mb-3 form-check">
                                        <input type="checkbox"
                                            className="form-check-input"
                                            id="exampleCheck1"
                                            name="GrantGGCIPermission"
                                            checked={GrantGGCIPermission}
                                            value={GrantGGCIPermission}
                                            onChange={(e) => handleGGCIConfirm(e)}
                                        />
                                        <label class="form-check-label text-dark" for="exampleCheck1">Yes, I confirm</label>
                                    </div>
                                </div>
                            </div> */}



                            <p className="py-5">
                                <div className="col-12 mt-0">
                                    <text style={{ color: '#006939', textAlign: 'center' }}>
                                        {PostMsg}
                                    </text>
                                </div>

                                <div className="col-12">
                                    <button type="button" className="btn float-end lightgreenbg text-white px-4"
                                        disabled={IsDisable || isRegistrationLoading || loading}
                                        onClick={handleSubmit}
                                    >
                                        {(isRegistrationLoading || loading) && (
                                            <i
                                                className="fa fa-spinner fa-spin"
                                                style={{ marginRight: "5px" }}
                                            />
                                        )}
                                        {(!isRegistrationLoading || !loading) && <span>Submit</span>}
                                    </button>
                                </div>

                            </p>

                        </form>

                    </div>


                </div>
            </div>
        </div>
    )
}