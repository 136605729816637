
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BaseUrl } from "../../Helper/BaseURL";

// InsertUpdate Table Data

export const PostSendEmailAPI = createAsyncThunk("PostSendEmail", async ({ data }) => {
    const {
        Full_Name,
        Email_ID,
        Username,
        Password,
        Flag
    } = data


    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer ");

    var formdata = new FormData();

    formdata.append("Email_ID", Email_ID);
    formdata.append("G_Planet_text", Full_Name);
    formdata.append("Username", Username);
    formdata.append("Password", Password);
    formdata.append("Flag", Flag);

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
    };

    fetch(`${BaseUrl}/Master/Post_SendEmail_Insert`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                return result.data
            } else {
            }
            return result
        })
})

const PostSendEmailSlice = createSlice({
    name: "PostSendEmail",
    initialState: {
        isEmailLoading: false,
        tableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(PostSendEmailAPI.pending, (state, action) => {
            state.isEmailLoading = true;
        });
        builder.addCase(PostSendEmailAPI.fulfilled, (state, action) => {
            state.isEmailLoading = false;
            state.tableData = action.payload;
        });
        builder.addCase(PostSendEmailAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isEmailLoading = false;
            state.isError = true;
            state.tableData = null;
            // toastErrorr('Something went wrong')
        });
    },
})

export const PostSendEmailReducer = PostSendEmailSlice.reducer